import axios from 'axios';
import React, { useContext } from 'react';
import { UserContext } from '../../App';

axios.defaults.withCredentials = true;

const Profile = () => {
  const { user, loggedIn } = useContext(UserContext);

  // If the user data is not yet loaded or available, handle it gracefully
  if (!user) {
    return (
      <div className="text-center my-5">
        <p>Loading user profile...</p> {/* You can replace this with a spinner or any loading indicator */}
      </div>
    );
  }

  return (
    <div>
      <div className="w-100 mb-4">
        <div className="card p-0 rounded-0 shadow">
          <div className="row"  style={{minHeight:'270px'}}>
            <div className="col-sm-12 col-md-6"  >
              {user.profilePic ? (
                <div
                  className="d-flex justify-content-center"
                  style={{ background: '#001c30f5' }}
                >
                  <img
                    src={`https://posnewversionbackend.basic2ai.info/profilePic/${user.profilePic}`}
                    // src={`http://localhost:8010/public/profilePic/${user.profilePic}`}
                    alt="Profile"
                    style={{
                      minWidth: '150px',
                      maxHeight: '300px',
                      height: '100%',
                      width: '100%',
                      objectFit: 'contain', // Ensure the image covers the container without stretching
                    }}
                  />
                </div>
              ) : (
                <div
                  className="border rounded-0 w-100 d-flex align-items-center justify-content-center"
                  style={{
                    minHeight: '150px',
                    height: '100%',
                    minWidth: '150px',
                    backgroundColor: `rgba(${parseInt(user.id) * 100},${parseInt(user.id) * 10},${parseInt(user.id) * 50})`,
                    color: 'white',
                    fontSize: '100px',
                  }}
                >
                  {user.name?.[0] || 'N/A'}
                </div>
              )}
            </div>
            <div className="col-sm-12 col-md-6 p-3">
              <center className="fs-4 fw-bold">User Profile</center>
              <hr />
              <div className="fw-semibold">ID : {user.id || 'N/A'}</div>
              <div className="fw-semibold">Name : {user.name || 'N/A'}</div>
              <div className="fw-semibold">Role : {user.role || 'N/A'}</div>
              <div className="fw-semibold">Username : {user.username || 'N/A'}</div>
              <div className="fw-semibold mb-2">Email : {user.email || 'N/A'}</div>

              {/* Role-specific token displays */}
              {user.role === 'employee' && (
                <p>Token Employee : {user.tokenEmployee?.split('@')[0] || 'N/A'}</p>
              )}
              {user.role === 'businessAdmin' && (
                <p>Token Business Admin : {user.tokenBusinessAdmin?.split('@')[0] || 'N/A'}</p>
              )}
              {user.role === 'superAdmin' && (
                <p>Token Super Admin : {user.tokenSuperAdmin?.split('@')[0] || 'N/A'}</p>
              )}
              {user.role === 'B2AAdmin' && (
                <p>Token B2A Admin : {user.tokenB2AAdmin?.split('@')[0] || 'N/A'}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
