import React, { useContext, useEffect, useState } from 'react';
import useFetchAnalyticsData from './UseFetchAnalyticsData'; // Custom hook to fetch analytics data
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  LineElement,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { UserContext } from '../../App';
import TopDetailsCard from './TopDetailsCard';
import PieChart from '../Graphs/PieChart';
import LineGraph from '../Graphs/LineGraph';
import DoughnutChart from '../Graphs/DoughnutChart';
import BarGgraph from '../Graphs/BarGgraph';
import SalesDataPage from './SalesDataPage';
import ParentComponent from './ParentComponent';
import ProductsProfitLossPage from './ProductsProfitLossPage';
import axios from 'axios';
axios.defaults.withCredentials = true

ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  LineElement,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const Analytics = () => {
  const [analyticsData, setAnalyticsData] = useState(null);
  const { data, isLoading, annualEarningsData, monthlyEarningsData, profitLossDataAvailable, salesThisMonthData, salesThisYearData, salesTodayData, topProductsData, availableCategoriesData, availableProductsData, yearlyOverviewData, allProducts, error: fetchError } = useFetchAnalyticsData();
  const [AED, setannualEarningsData] = useState([]);
  const [MED, setmonthlyEarningsData] = useState([]);
  const [PLDA, setprofitLossDataAvailable] = useState({});
  const [STMD, setsalesThisMonthData] = useState([]);
  const [STYD, setsalesThisYearData] = useState([]);
  const [STD, setsalesTodayData] = useState([]);
  const [TPD, settopProductsData] = useState([]);
  const [ACD, setavailableCategoriesData] = useState(0);
  const [APD, setavailableProductsData] = useState(0);
  const [YOD, setyearlyOverviewData] = useState({});
  const [allProductsData, setAllProducts] = useState([])

  const [timeBtns, setTimeLine] = useState({
    btn1: true,
    btn2: false,
    btn3: false,
  });
  const { user } = useContext(UserContext);
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust as necessary
  const [totalSales, setTotalSales] = useState([]);

  // Search state
  const [searchQuery, setSearchQuery] = useState('');
  const [productSearchQuery, setProductSearchQuery] = useState('');

  useEffect(() => {
    if (data) {
      setAnalyticsData(data);
      setannualEarningsData(annualEarningsData)
      setmonthlyEarningsData(monthlyEarningsData)
      setprofitLossDataAvailable(profitLossDataAvailable)
      setsalesThisMonthData(salesThisMonthData)
      setsalesThisYearData(salesThisYearData)
      setsalesTodayData(salesTodayData)
      settopProductsData(topProductsData)
      setavailableCategoriesData(availableCategoriesData)
      setavailableProductsData(availableProductsData)
      setyearlyOverviewData(yearlyOverviewData)
      setTotalSales(data.sales_today || []);
      setAllProducts(allProducts || [])
    }
  }, [data]);
  // console.log(data)
  // Check for loading state and error handling
  if (isLoading) return <div>Loading...</div>; // Loading state
  if (fetchError) return <div>Error fetching data: {fetchError}</div>; // Error state

  // Guard clause for null analyticsData
  if (!analyticsData) return <div>No data available.</div>; // If no data, show this message

  // Calculate average earnings per month
  const avgMonthlyEarnings = MED.length > 0 && MED[0] !== null
    ? (MED[0].totalEarnings / 30).toFixed(2)
    : 0;

  // Check if annual_earnings is an array and access the first element safely
  const annualEarnings = AED.length > 0 && AED[0] !== null
    ? AED[0].totalEarnings
    : 0;
  // Prepare data for Line Graph (Yearly Overview)
  const yearlyOverview = YOD
  const labels = Object.keys(yearlyOverview).map(month => new Date(0, month).toLocaleString('default', { month: 'long' }));
  const earningsData = Object.values(yearlyOverview);

  // Prepare data for Doughnut Graph (Top Products)
  const topProducts = TPD || [];
  const productLabels = topProducts.map(p => p.product_name); // Assuming 'product_name' is the label you want
  const productQuantities = topProducts.map(p => p.quantity_sold);

  // Prepare Sales Data for Table
  const salesToday = STD || [];
  const salesThisMonth = STMD || [];
  const salesThisYear = STYD || [];

  // Prepare Profit and Loss Data
  const profitLossData = PLDA || {};
  const profitLabels = Object.keys(profitLossData);
  const profitValues = profitLabels.map(label => profitLossData[label].profit_or_loss);
  const purchasePrices = profitLabels.map(label => profitLossData[label].cost_price);
  const sellingPrices = profitLabels.map(label => profitLossData[label].selling_price);

  // Calculate profit and loss percentages
  const profitLossPercentages = profitLabels.map((label, index) => {
    const costPrice = profitLossData[label].cost_price;
    const sellingPrice = profitLossData[label].selling_price;
    const finalAmount = (sellingPrice - costPrice) / costPrice * 100;
    return (finalAmount ? finalAmount.toFixed(2) : 0);
  });

  // Pagination logic
  const indexOfLastSale = currentPage * itemsPerPage;
  const indexOfFirstSale = indexOfLastSale - itemsPerPage;
  const currentSalesData = salesToday.slice(indexOfFirstSale, indexOfLastSale);

  const totalPages = Math.ceil(totalSales.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderSalesData = (salesData) => {
    if (salesData.length === 0) {
      return (
        <tr>
          <td colSpan={getColumnSpan(user.role)} style={{ textAlign: 'center' }}>
            No sales data available.
          </td>
        </tr>
      );
    }

    return salesData.map((sale, index) => (
      <tr key={index}>
        <td className='text-center'>{new Date(sale.dataValues.createdAt).toLocaleDateString()}</td>
        <td className='text-center'>{new Date(sale.dataValues.createdAt).toLocaleTimeString()}</td>
        <td>{sale.dataValues.customer_name}</td>
        <td>
          {sale.product_data.map((data, idx) => (
            <span key={idx}>
              {data.product_name}{idx < sale.product_data.length - 1 && ', '}
            </span>
          ))}
        </td>
        <td className='text-center'>{sale.dataValues.employeeToken.split('@')[0] || 'N/A'}</td>
        {renderAdminTokens(sale)}
      </tr>
    ));
  };

  const getColumnSpan = (role) => {
    if (role === 'bussinessAdmin') return 6;
    if (role === 'superAdmin') return 7;
    if (role === 'B2AAdmin') return 8;
    return 5; // Default
  };

  const renderAdminTokens = (sale) => {
    if (user?.role === 'bussinessAdmin' || user?.role === 'superAdmin' || user?.role === 'B2AAdmin') {
      return (
        <td className='text-center'>{sale.dataValues.bussinessToken.split('@')[0] || 'N/A'}</td>
      );
    }
    if (user?.role === 'superAdmin' || user?.role === 'B2AAdmin') {
      return (
        <td className='text-center'>{sale.dataValues.superAdminToken.split('@')[0] || 'N/A'}</td>
      );
    }
    if (user?.role === 'B2AAdmin') {
      return (
        <td className='text-center'>{sale.dataValues.B2AAdminToken.split('@')[0] || 'N/A'}</td>
      );
    }
    return null;
  };

  // Filter sales data based on search query
  const filterSalesData = (salesData) => {
    if (!searchQuery) return salesData; // If no search query, return original data
    return salesData.filter(sale => {
      // Extract and clean the customer name
      const customerName = sale.dataValues.customer_name.trim().toLowerCase();
      const productNames = sale.product_data.map(data => data.product_name.trim().toLowerCase());

      // Check for matches
      const customerNameMatch = customerName.includes(searchQuery.toLowerCase());
      const productNamesMatch = productNames.some(name => name.includes(searchQuery.toLowerCase()));
      // Return if either the customer or product matches

      return sale; // Filter by customer name or product names
    });
  };


  // Filter products data based on search query
  const filterProductsData = (topProducts) => {
    if (!productSearchQuery) return topProducts; // If no search query, return original data
    return topProducts.filter((_, idx) => {
      const productName = productLabels[idx].toLowerCase();
      return productName.includes(productSearchQuery.toLowerCase()); // Filter by product name
    });
  };

  const filteredSalesData = filterSalesData(timeBtns.btn1 ? salesToday : timeBtns.btn2 ? salesThisMonth : timeBtns.btn3 ? salesThisYear : salesThisMonth);
  const filteredTopProducts = filterProductsData(topProducts);
  // console.log(APD)
  return (
    <div className="analytics-container">
      <h1>Analytics Dashboard</h1>

      {/* Average Monthly Earnings and Registered Products & Categories */}
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
          <TopDetailsCard icon='bi-basket3-fill' title='Avg. Monthly Earnings' number={`${avgMonthlyEarnings} ₹`} color='#7673E1' />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
          <TopDetailsCard icon='bi-currency-rupee' title='Annual Earnings' number={`${annualEarnings ? annualEarnings.toFixed(2) : 0} ₹`} color='#1CC88A' />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
          <TopDetailsCard icon='bi-tag-fill' title='Registered Products' number={`${APD}`} color='#42BDCF' />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
          <TopDetailsCard icon='bi-signpost-split-fill' title='Registered Categories' number={`${ACD}`} color='#FFB02E' />
        </div>
      </div>

      {/* Graphs Section */}
      <div className='my-3 p-3'>
        <div className='row'>
          <div className='border mb-2 py-0 p-0 rounded-0 shadow bg-white col-lg-8'>
            <LineGraph earningsData={earningsData} labels={labels} />
          </div>
          <div className='border mb-2 p-0 rounded-0 shadow bg-white col-lg-4'>
            <DoughnutChart productLabels={productLabels} productQuantities={productQuantities} />
            <div className='table-responsive mb-0 pb-0'>
              <table className='table table-bordered'>
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Product</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTopProducts.map((_, idx) => (
                    <tr key={idx}>
                      <td className='text-center'>{idx + 1}</td>
                      <td>{productLabels[idx]}</td>
                      <td className='text-center'>{productQuantities[idx]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Sales Data Table */}
      <ParentComponent salesToday={salesToday} salesThisMonth={salesThisMonth} salesThisYear={salesThisYear} user={user} />
      <ProductsProfitLossPage allProducts={allProductsData} />

      <div className='row'>
        <div className='col-sm-12 col-md-12 col-lg-6'>
          <BarGgraph profitLabels={profitLabels} purchasePrices={purchasePrices} sellingPrices={sellingPrices} />
        </div>
        <div className='col-sm-12 col-md-12 col-lg-6'>
          <PieChart profitLabels={profitLabels} profitValues={profitValues} />
        </div>
      </div>
      {/* Profit and Loss Graph */}

      {/* Pie Chart for Profit and Loss */}

      {/* Products Table with Profit and Loss Percentage */}
    </div>
  );
};

export default Analytics;
