import axios from 'axios'
import React from 'react'
axios.defaults.withCredentials = true
const TopDetailsCard = ({ icon, title, number, color }) => {
    return (
        <div className='my-3'>
            <div className='bg-white align-items-center shadow rounded-3 p-4 d-flex justify-content-between' style={{ borderLeft: `5px solid ${color}` }}>
                <div>
                    <p className='text-uppercase' style={{ fontSize: '14px', fontWeight: "600", color: `${color}` }}>{title}</p>
                    <b className='' style={{ fontSize: '25px', fontWeight: '600' }}>{number}</b>
                </div>
                <div>
                    <i className={`bi ${icon} fs-1`} style={{ color: '#DDDFEB' }}></i>
                </div>
            </div>
        </div>
    )
}

export default TopDetailsCard