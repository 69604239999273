import React, { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../App';
import './Product.css';
import axios from 'axios';
import { NavLink, useParams } from 'react-router-dom';
axios.defaults.withCredentials = true;

const EditProducts = () => {
    const [cat_id, setCategory] = useState('');
    const [product_name, setProduct_name] = useState('');
    const [product_desc, setProduct_desc] = useState('');
    const [cost_price, setCost_price] = useState('');
    const [selling_price, setSelling_price] = useState('');
    const [quantity, setQuantity] = useState('');
    const [expiry_date, setExpiry_date] = useState('');
    const [expiry_alert, setExpiry_alert] = useState(false);
    const [msg, setMsg] = useState(null);
    const [msg_type, setMsg_type] = useState(null);
    const [category, setCategoryList] = useState([]);
    const { productId, employeeToken } = useParams()
    const msgRef = useRef(null);
    const formRef = useRef(null);

    // Get user context
    const { user, fontSizeChange, onClose, handleCloseForm, } = useContext(UserContext);
    const addedBy = user?.username ? `${user.username} ${user.email} ${user.phone}` : "Unknown User";

    // Handle clicks outside the form to close it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (formRef.current && !formRef.current.contains(event.target)) {
                handleCloseForm();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    useEffect(() => {
        axios.get('https://posnewversionbackend.basic2ai.info/api/categories')
        // axios.get('http://localhost:8010/api/categories')
            .then(res => {
                setCategoryList(res.data.categoriesFound);
            })
            .catch(error => console.error('Error fetching categories:', error));
    }, []);

    // Fetch product data for editing
    useEffect(() => {
        if (productId) {
            axios.get(`https://posnewversionbackend.basic2ai.info/api/products/${productId}/${employeeToken}`)
            // axios.get(`http://localhost:8010/api/products/${productId}/${employeeToken}`)
                .then(res => {
                    const product = res.data.product; // Assuming the API returns the product object
                    setCategory(product.cat_id);
                    setProduct_name(product.product_name);
                    setProduct_desc(product.product_desc);
                    setCost_price(product.cost_price);
                    setSelling_price(product.selling_price);
                    setQuantity(product.quantity);
                    setExpiry_date(product.expiry_date);
                    setExpiry_alert(product.expiry_alert);
                })
                .catch(error => {
                    console.error('Error fetching product data:', error);
                    setMsg('Error fetching product data.');
                    setMsg_type('error');
                });
        }
    }, [productId]);

    // Set today's date as the minimum allowed date for expiry date
    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        setExpiry_date(getTodayDate()); // Default to today's date
    }, []);

    // Handle the form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        if (new Date(expiry_date) < new Date()) {
            setMsg('Expiry date cannot be in the past.');
            setMsg_type('error');
            return;
        }

        // Update product data in the API
        axios.post(`https://posnewversionbackend.basic2ai.info/api/update/product`, {
        // axios.post(`http://localhost:8010/api/update/product`, {
            product_id: productId,
            cat_id: cat_id,
            product_name: product_name,
            product_desc: product_desc,
            cost_price: cost_price,
            selling_price: selling_price,
            quantity: quantity,
            expiry_date: expiry_date,
            expiry_alert: expiry_alert,
            employeeToken: employeeToken
        })
            .then(res => {
                if (res.status === 200) {
                    setMsg(res.data.msg);
                    setMsg_type('good');
                    // Reset form fields after successful edit
                    setCategory('');
                    setProduct_name('');
                    setProduct_desc('');
                    setCost_price('');
                    setSelling_price('');
                    setQuantity('');
                    setExpiry_date(getTodayDate());
                    setExpiry_alert(false);
                    const interval = setInterval(() => {
                        setMsg(null)
                        window.location.href = '/products'
                    }, [2500])
                    return () => clearInterval(interval)
                } else {
                    setMsg(res.data.msg);
                    setMsg_type('error');
                }
            })
            .catch(err => {
                console.log(err)
                setMsg(`Error updating product.${err.message}`);
                setMsg_type('error');
            });
    };

    return (
        <div>
            {msg && (
                <div className='d-flex align-items-center justify-content-center min-vh-100' style={{ background: '#5656569f', position: 'absolute', width: '100vw', zIndex: '1050', left: '0px', top: "0px" }}>
                    <div
                        ref={msgRef}
                        className={`msgAlert animate2 p-5 rounded-3 show border border-2 show ${msg ? 'show' : 'notShow'} ${msg_type === 'good' ? 'text-success' : 'text-danger'}`}
                        style={{ top: '35%', fontSize: '20px', height: '250px', width: '370px', background: 'white' }}
                    >
                        <div className=''>
                            <div>
                                <center>{msg_type === 'error' ? <i className='bi bi-x-circle animate' style={{ fontSize: '45px' }}></i> : <i className='bi bi-check-circle animate' style={{ fontSize: '45px' }}></i>}</center>
                            </div>
                            <div className='text-center'>
                                {msg}
                            </div>
                            <hr />
                            <center>
                                <div className={`btn ${msg_type === 'error' ? 'btn-danger' : 'btn-success'} rounded-0`} onClick={() => setMsg(null)}>
                                    Okay
                                </div>
                            </center>
                        </div>
                    </div>
                </div>
            )}

            <div className="EditProducts">
                <div className="d-flex justify-content-center shadow" style={{ marginTop: '20px' }}>
                    <div ref={formRef} className={`container border bg-white`} style={{ minHeight: '700px' }}>
                        <div className="btn rounded-0 my-3 py-2 ps-0 mainPageName" style={{ fontWeight: '600', fontSize: `${fontSizeChange ? '25px' : '18px'}` }}>
                            Edit Product
                        </div>
                        <br />
                        <NavLink to='/products'>
                            <div className="btn rounded-0 mb-3 text-white py-2 px-4 mainPageBack" style={{ fontSize: `${fontSizeChange ? '20px' : '16px'}` }} >
                                <i className="bi bi-arrow-left me-2"></i> Back
                            </div>
                        </NavLink>
                        <form className="p-3" onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-md-6 col-sm-12 mb-3">
                                    <div className="input-group">
                                        <label>Name</label>
                                        <input type="text" className="form-control w-100" value={product_name} onChange={e => setProduct_name(e.target.value)} required />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 mb-3">
                                    <div className="input-group">
                                        <label>Category ID</label>
                                        <select className="form-control w-100" value={cat_id} onChange={e => setCategory(e.target.value)} required>
                                            <option selected disabled>-- Select Category --</option>
                                            {
                                                category.length > 0 ?
                                                    category.map((data, idx) => (
                                                        <option key={idx} value={data.cat_id}>{data.cat_name} -[ {data.cat_id} ]</option>
                                                    ))
                                                    : <option disabled>No Category Available</option>
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 mb-3">
                                    <div className="input-group">
                                        <label>Description</label>
                                        <textarea type="text" rows={3} style={{ resize: 'none' }} className="form-control w-100" value={product_desc} onChange={e => setProduct_desc(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <div className="input-group">
                                        <label>Cost Price</label>
                                        <input type="number" className="form-control w-100" min={1} value={cost_price} onChange={e => setCost_price(e.target.value)} required />
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <div className="input-group">
                                        <label>Selling Price</label>
                                        <input type="number" className="form-control w-100" min={cost_price} value={selling_price} onChange={e => setSelling_price(e.target.value)} required />
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <div className="input-group">
                                        <label>Quantity</label>
                                        <input type="number" className="form-control w-100" value={quantity} onChange={e => setQuantity(e.target.value)} required />
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <div className="input-group">
                                        <label>Expiry Date</label>
                                        <input type="date" className="form-control w-100" value={expiry_date.split('T')[0]} min={getTodayDate()} onChange={e => setExpiry_date(e.target.value)} required />
                                    </div>
                                </div>
                                <div className="col-12 mb-3">
                                    <div className="input-group">
                                        <label>Expiry Alert <input type="checkbox" checked={expiry_alert} onChange={e => setExpiry_alert(e.target.checked)} /></label>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <label>Added by</label>
                                    <input type="text" className="form-control w-100" value={addedBy} readOnly disabled />
                                </div>
                            </div>
                            <button type='submit' className="btn rounded-0 text-white" style={{ background: '#1CC88A' }}>Update Product</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditProducts;
