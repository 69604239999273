import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { UserContext } from '../../App'
import axios from 'axios'
import Swal from 'sweetalert2'
import { printBill } from './PrintBill.js'

const Detail = () => {
  const [customerDetails, setCustomerDetails] = useState([])
  const [billDetail, setBillDetail] = useState([])
  const { sale_id, customer_id, employeeToken } = useParams()
  const [products, setProducts] = useState()
  const { user, isFormOpen, fontSizeChange } = useContext(UserContext)
  useEffect(() => {
    axios.get(`https://posnewversionbackend.basic2ai.info/api/specific/customer/${customer_id}/${employeeToken}`)
    // axios.get(`http://localhost:8010/api/specific/customer/${customer_id}/${employeeToken}`)
      .then(res => {
        setCustomerDetails(res.data.customer)
      })
      .catch(err => {
        console.log(err)
      })
    axios.get(`https://posnewversionbackend.basic2ai.info/api/specific/product/${sale_id}/${employeeToken}`)
    // axios.get(`http://localhost:8010/api/specific/product/${sale_id}/${employeeToken}`)
      .then(res => {
        // console.log(res.data)
        setBillDetail(res.data.sale)
        setProducts(res.data.sale.product_data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])
  const handleFare = (totalAmount, amountPayed, opt) => {
    // console.log(totalAmount, amountPayed, opt)
    Swal.fire({
      title: `Attention!`,
      text: `Please make sure to ${opt == 1 ? 'take' : 'give'} the remain amount of ${opt == 1 ? totalAmount - amountPayed : amountPayed - totalAmount} ₹`,
      icon: 'warning',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      showCancelButton: true,
    }).then(res => {
      if (res.isConfirmed) {
        const maxAmount = parseFloat((opt == 1 ? totalAmount - amountPayed : amountPayed - totalAmount).toFixed(2));

        const paid = parseFloat(parseFloat(prompt(`Enter the Amount ${opt == 1 ? 'Paid by customer' : 'Given to Customer'}`)).toFixed(2));

        if (isNaN(paid) || paid != maxAmount) {
          Swal.fire({
            title: `Attention!`,
            text: `Please make sure to ${opt == 1 ? 'take' : 'give'} the remain amount of ${opt == 1 ? totalAmount - amountPayed : amountPayed - totalAmount} ₹. Can not Proceed further with amount less or more than the amount remaining.`,
            icon: 'warning',
            confirmButtonText: 'Okay',
          })
        } else {
          axios.post('https://posnewversionbackend.basic2ai.info/api/handle-fare', { totalAmountWithTax: totalAmount, amountPayed: paid, employeeToken: employeeToken, sale_id: sale_id, givenByCustomer: billDetail.givenByCustomer, givenByShopkeeper: billDetail.givenByShopkeeper })
          // axios.post('http://localhost:8010/api/handle-fare', { totalAmountWithTax: totalAmount, amountPayed: paid, employeeToken: employeeToken, sale_id: sale_id, givenByCustomer: billDetail.givenByCustomer, givenByShopkeeper: billDetail.givenByShopkeeper })
            .then(res => {
              if (res.status == 200) {
                Swal.fire({
                  title: `Success!`,
                  text: `Changes Remaining 0 ₹. Have a Nice Day 😊`,
                  icon: 'success',
                  confirmButtonText: 'Done',
                  confirmButtonColor: '#4E73DE',
                  cancelButtonColor: 'red',
                  cancelButtonText: 'Close',
                  showCancelButton: true,
                }).then(res => {
                  if (res.isDismissed) {
                    window.location.reload(true)
                  } else {
                    window.location.reload(true)
                  }
                })
              }
              else {
                Swal.fire({
                  title: `Oops!`,
                  text: `${res.data.msg}`,
                  icon: 'error',
                  confirmButtonText: 'Okay',
                })
              }
            })
            .catch(err => {
              console.log(err)
              Swal.fire({
                title: `Oops!`,
                text: `${err.message}. Something happend to server.\n Please be calm. Error occurs from company side. Contact them to resolve this error.`,
                icon: 'error',
                confirmButtonText: 'Okay',
              })
            })
        }
      }
    })
  }

  const handlePrint = () => {
    // Call the printBill function with the gathered data
    printBill(customerDetails, billDetail, products, user)
  }
  return (
    <div>
      <div className='container'>
        <h1>Bill</h1>
        <NavLink to='/sales'>
          <div className="btn rounded-0 my-4 text-white me-2 px-2 mainPageBack" style={{ fontSize: `${fontSizeChange ? '20px' : '16px'}` }} >
            <i className="bi bi-arrow-left me-2"></i> Back
          </div>
        </NavLink>
        <button className='btn my-3 text-white rounded-0' style={{background:'#4E73DE'}} onClick={handlePrint}>Print Bill</button>

        <div className='card bg-white shadow p-0'>
          <div className='card-header fw-bolder fs-3' style={{ color: '#99C3FF' }}>Bill</div>
          {customerDetails && <div className='row p-3'>
            <div className='col-sm-12 col-md-6 col-lg-3 border mb-2'>
              <b className="text-uppercase">Name:</b> {customerDetails.cust_name}
            </div>
            <div className='col-sm-12 col-md-6 col-lg-3 border mb-2'>
              <b className="text-uppercase">Phone:</b> {customerDetails.cust_phone}
            </div>
            <div className='col-sm-12 col-md-6 col-lg-3 border mb-2'>
              <b className="text-uppercase">Email:</b> {customerDetails.cust_email || 'N/A'}
            </div>
            <div className='col-sm-12 col-md-6 col-lg-3 border mb-2'>
              <b className="text-uppercase">Village:</b> {customerDetails.village || 'N/A'}
            </div>
            <div className='col-sm-12 col-md-6 col-lg-3 border mb-2'>
              <b className="text-uppercase">City:</b> {customerDetails.city || 'N/A'}
            </div>
            <div className='col-sm-12 col-md-6 col-lg-3 border mb-2'>
              <b className="text-uppercase">District:</b> {customerDetails.district || 'N/A'}
            </div>
            <div className='col-sm-12 col-md-6 col-lg-3 border mb-2'>
              <b className="text-uppercase">State:</b> {customerDetails.estate || 'N/A'}
            </div>
            <div className='col-sm-12 col-md-6 col-lg-3 border mb-2'>
              <b className="text-uppercase">Zip Code:</b> {customerDetails.zipCode || 'N/A'}
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6 border mb-2'>
              <b className="text-uppercase">Added By:</b> {(customerDetails && customerDetails.employeeToken) || 'N/A'}
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6 border mb-2'>
              <b className="text-uppercase">Added Under:</b> {(customerDetails && customerDetails.bussinessToken) || 'N/A'}
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6 border mb-2'>
              <b className="text-uppercase">Admin:</b> {(customerDetails && customerDetails.superAdminToken) || 'N/A'}
            </div>
            {user && <div className='col-sm-12 col-md-6 col-lg-6 border mb-2'>
              <b className="text-uppercase">Shop:</b> {user.shopName || 'N/A'}
            </div>}
            {user && <div className='col-sm-12 col-md-6 col-lg-6 border mb-2'>
              <b className="text-uppercase">Contact:</b> {user.phone || 'N/A'}
            </div>}
            {user && <div className='col-sm-12 col-md-6 col-lg-6 border mb-2'>
              <b className="text-uppercase">Email:</b> {user.email || 'N/A'}
            </div>}
          </div>}
        </div>

        <div className='border shadow my-3 p-2'>
          <button className='btn btn-success rounded-0 mb-3'>Bill Information</button>
          <div className='table-responsive my-2' >
            <table className='table table-bordered table-hover'>
              <thead>
                <tr>
                  <th className='text-center' style={{ background: '#0080FF', color: 'white', minWidth: '20px', verticalAlign: 'middle' }}>#</th>
                  <th className='text-center' style={{ background: '#0080FF', color: 'white', minWidth: '100px', verticalAlign: 'middle' }}>Sold on</th>
                  <th className='text-center' style={{ background: '#0080FF', color: 'white', minWidth: '70px', verticalAlign: 'middle' }}>Items</th>
                  <th className='text-center' style={{ background: '#0080FF', color: 'white', minWidth: '120px', verticalAlign: 'middle' }}>Amount</th>
                  <th className='text-center' style={{ background: '#0080FF', color: 'white', minWidth: '150px', verticalAlign: 'middle' }}>Amount (+ Tax)</th>
                  <th className='text-center' style={{ background: '#0080FF', color: 'white', minWidth: '100px', verticalAlign: 'middle' }}>Tax  (%)</th>
                  <th className='text-center' style={{ background: '#0080FF', color: 'white', minWidth: '150px', verticalAlign: 'middle' }}>Tax Amount  (%)</th>
                  <th className='text-center' style={{ background: '#0080FF', color: 'white', minWidth: '120px', verticalAlign: 'middle' }}>Amount Paid</th>
                  <th className='text-center' style={{ background: '#0080FF', color: 'white', minWidth: '70px', verticalAlign: 'middle' }}>Left</th>
                  <th className='text-center' style={{ background: '#0080FF', color: 'white', minWidth: '100px', verticalAlign: 'middle' }}>Given By</th>
                  {(user && user.role === 'employee') && <th className='text-center' style={{ background: '#0080FF', color: 'white', minWidth: '100px', verticalAlign: 'middle' }}>Action</th>
                  }
                </tr>
              </thead>
              <tbody>
                {billDetail && <tr>
                  <td className='' style={{ verticalAlign: 'middle', textAlign: 'center' }}>{billDetail.id}</td>
                  <td className='' style={{ verticalAlign: 'middle', textAlign: 'center' }}>{billDetail?.updatedAt?.split('T')[0] || 'N/A'}</td>
                  <td className='' style={{ verticalAlign: 'middle', textAlign: 'center' }}>{billDetail.numberOfItems}</td>
                  <td className='' style={{ verticalAlign: 'middle', textAlign: 'end' }}>{billDetail.totalAmountWithoutTax}₹</td>
                  <td className='' style={{ verticalAlign: 'middle', textAlign: 'end' }}>{billDetail.totalAmountWithTax}₹</td>
                  <td className='' style={{ verticalAlign: 'middle', textAlign: 'end' }}>{billDetail.taxPercentage} %</td>
                  <td className='' style={{ verticalAlign: 'middle', textAlign: 'end' }}>{billDetail.taxAmount}₹</td>
                  <td className='' style={{ verticalAlign: 'middle', textAlign: 'end' }}>{billDetail.amountPayed}₹</td>
                  <td className='' style={{ verticalAlign: 'middle', textAlign: 'end' }}>{billDetail.amountLeft}₹</td>
                  <td className='text-white' style={{ verticalAlign: 'middle', textAlign: 'center', background: `${billDetail.givenByCustomer == 1 ? 'green' : billDetail.givenByShopkeeper ? 'red' : 'gray'}` }}>{billDetail.givenByCustomer == 1 ? 'Customer' : billDetail.givenByShopkeeper ? 'Shop' : 'None'}</td>
                  {(user && user.role === "employee") && <td className={`text-white`} style={{ verticalAlign: 'middle', textAlign: 'center', background: `${billDetail.givenByCustomer == 1 ? '#FFC107' : billDetail.givenByShopkeeper == 1 ? 'red' : 'gray'}` }}>{billDetail.givenByCustomer == 1 ? <button className='btn text-white rounded-0' onClick={e => handleFare(billDetail.totalAmountWithTax, billDetail.amountPayed, 1)}>Take</button> : billDetail.givenByShopkeeper == 1 ? <button className='btn text-white rounded-0' onClick={e => handleFare(billDetail.totalAmountWithTax, billDetail.amountPayed, 0)}>Give</button> : <button className='btn rounded-0 text-white'>No Action</button>}</td>}
                </tr>}
              </tbody>
            </table>

          </div>
        </div>
        <div className='border shadow my-3 p-2'>
          <div className=''>
            <button className='btn btn-primary rounded-0 mb-3'>Product Purchased</button>
            <div className='table-responsive'>
              <table className='table table-bordered table-hover'>
                <thead>
                  <tr>
                    <th className='text-center' style={{ background: "#0080FF", color: 'white', verticalAlign: 'middle', minWidth: '20px' }}>#</th>
                    <th className='text-center' style={{ background: "#0080FF", color: 'white', verticalAlign: 'middle', minWidth: '100px' }}>ID</th>
                    <th className='text-center' style={{ background: "#0080FF", color: 'white', verticalAlign: 'middle', minWidth: '100px' }}>Name</th>
                    <th className='text-center' style={{ background: "#0080FF", color: 'white', verticalAlign: 'middle', minWidth: '100px' }}>Category</th>
                    <th className='text-center' style={{ background: "#0080FF", color: 'white', verticalAlign: 'middle', minWidth: '100px' }}>Quantity</th>
                    <th className='text-center' style={{ background: "#0080FF", color: 'white', verticalAlign: 'middle', minWidth: '100px' }}>Price (₹)</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    products && products.map((data, idx) => (
                      <tr key={idx}>
                        <td className='text-center' style={{ verticalAlign: 'middle' }}>{idx + 1}</td>
                        <td className='text-center' style={{ verticalAlign: 'middle' }}>{data.product_id}</td>
                        <td className='text-center' style={{ verticalAlign: 'middle' }}>{data.product_name}</td>
                        <td className='text-center' style={{ verticalAlign: 'middle' }}>{data.category_name}</td>
                        <td className='text-center' style={{ verticalAlign: 'middle' }}>{data.quantity}</td>
                        <td className='text-center' style={{ verticalAlign: 'middle' }}>{data.total_price} ₹</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Detail