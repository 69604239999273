import React from 'react'
import { Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    ArcElement,
    LineElement,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    LineElement,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
);
const PieChart = ({ profitLabels, profitValues }) => {
    const backgroundColor = [
        'rgba(255, 99, 132, 0.9)',   // Red
        'rgba(54, 162, 235, 0.9)',    // Blue
        'rgba(255, 206, 86, 0.9)',    // Yellow
        'rgba(75, 192, 192, 0.9)',    // Teal
        'rgba(153, 102, 255, 0.9)',   // Purple
        'rgba(255, 159, 64, 0.9)',    // Orange
        'rgba(255, 99, 132, 0.9)',    // Red
        'rgba(255, 51, 51, 0.9)',     // Light Red
        'rgba(51, 204, 51, 0.9)',     // Green
        'rgba(255, 204, 0, 0.9)',     // Gold
        'rgba(255, 153, 204, 0.9)',   // Light Pink
        'rgba(0, 153, 204, 0.9)',     // Cyan
        'rgba(204, 51, 255, 0.9)',    // Magenta
        'rgba(204, 153, 0, 0.9)',     // Brown
        'rgba(153, 0, 153, 0.9)',      // Dark Purple
        'rgba(0, 204, 0, 0.9)',        // Bright Green
        'rgba(204, 204, 204, 0.9)',    // Light Gray
        'rgba(51, 51, 255, 0.9)',      // Dark Blue
        'rgba(255, 128, 128, 0.9)',   // Salmon
        'rgba(128, 255, 128, 0.9)',   // Light Green
        'rgba(128, 128, 255, 0.9)',   // Light Blue
        'rgba(255, 255, 128, 0.9)',   // Light Yellow
        'rgba(255, 128, 255, 0.9)',   // Light Purple
        'rgba(255, 64, 64, 0.9)',     // Coral
        'rgba(64, 255, 255, 0.9)',    // Turquoise
        'rgba(64, 64, 255, 0.9)',     // Slate Blue
        'rgba(255, 192, 203, 0.9)',   // Pink
        'rgba(128, 0, 0, 0.9)',       // Maroon
        'rgba(0, 128, 128, 0.9)',     // Teal
        'rgba(255, 99, 71, 0.9)',     // Tomato
        'rgba(186, 85, 211, 0.9)',    // Medium Orchid
        'rgba(0, 255, 255, 0.9)',     // Aqua
        'rgba(0, 0, 128, 0.9)',       // Navy
        'rgba(255, 20, 147, 0.9)',    // Deep Pink
        'rgba(221, 160, 221, 0.9)',   // Plum
        'rgba(0, 255, 127, 0.9)',     // Spring Green
        'rgba(255, 69, 0, 0.9)',      // Red Orange
        'rgba(128, 128, 0, 0.9)',     // Olive
        'rgba(255, 215, 0, 0.9)',     // Gold
        'rgba(34, 139, 34, 0.9)',     // Forest Green
        'rgba(0, 191, 255, 0.9)',     // Deep Sky Blue
        'rgba(75, 0, 130, 0.9)',      // Indigo
        'rgba(255, 228, 196, 0.9)',   // Bisque
        'rgba(255, 192, 203, 0.9)',   // Pink
        'rgba(173, 216, 230, 0.9)',   // Light Blue
        'rgba(100, 149, 237, 0.9)',   // Cornflower Blue
        'rgba(240, 230, 140, 0.9)',   // Khaki
        'rgba(186, 85, 211, 0.9)',    // Medium Orchid
        'rgba(255, 127, 80, 0.9)',    // Coral
        'rgba(124, 252, 0, 0.9)',     // Lawn Green
        'rgba(173, 255, 47, 0.9)',    // Green Yellow
    ];

    // Update your Pie chart code to use this array.

    return (
        <div className='card bg-white my-4 border-0 rounded-0 shadow'>
            <div className='card-header'>
                <h2>Profit and Loss Percentage</h2>
            </div>
            <div className='card-body'>
                <Pie
                    data={{
                        labels: profitLabels,
                        datasets: [{
                            label: 'Profit and Loss',
                            data: profitValues,
                            backgroundColor: backgroundColor, // Use the new unique colors
                        }],
                    }}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false, // Set to false for custom dimensions
                        width: 400,  // Adjust width as needed
                        height: 400, // Adjust height as needed
                        plugins: {
                            legend: {
                                position: 'top',
                            },
                        },
                    }}
                    style={{ width: '420px', height: '443px' }} // Set the style for the chart
                />

            </div>
        </div>
    )
}

export default PieChart