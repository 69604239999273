import React, { useContext, useEffect, useState } from 'react';
import Registration from './Registration';
import { UserContext } from '../App'; // Assuming UserContext is defined in your App.js
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Ensure you have Bootstrap Icons installed
import './Main.css'
import axios from 'axios';

const Login = () => {
  const { signUp, setSignUp } = useContext(UserContext); // Use context for sign-up toggle
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [msg, setMsg] = useState(null); // State for messages (can be used for feedback)
  const [msg_type, setMsg_type] = useState(null); // State to track the type of message (success, error, etc.)

  const [username, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [phone, setPhone] = useState('')
  const [role, setRole] = useState('')
  // Toggle show/hide password
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Handle SignUp toggle
  const handleToggle = () => {
    setSignUp(!signUp);
  };
  const ChangeNumber = (e, len) => {
    if (e.target.value.length <= len)
      setPhone(e.target.value)
    else {
      alert('Digits Exceeds')
    }
  }
  const handleLogin = (e) => {
    e.preventDefault();
    // console.log(username, password)

    // Check if any required field is empty or invalid
    if (
      !username || username === '' ||
      !password || password === '' ||
      !role || role === '' ||
      phone.length !== 10
    ) {
      setMsg_type('error');
      // Display specific field error message
      setMsg(`Field ${!username || username === '' ? 'Username' : !password || password === '' ? 'Password' : !role || role === '' ? 'Role' : phone.length !== 10 ? 'Phone Number' : ''} is not full filled`);
      // Use setTimeout to clear the message after 2.5 seconds
      const timeout = setTimeout(() => {
        setMsg(null);
        setMsg_type(null);
      }, 2500);

      // Optional: clear the timeout when component unmounts or when not needed anymore
      return () => clearTimeout(timeout);
    } else {
      axios.post('https://posnewversionbackend.basic2ai.info/api/login', { username: username, password: password, phone: phone, role: role })
      // axios.post('http://localhost:8010/api/login', { username: username, password: password, phone: phone, role: role })
        .then(res => {
          if (res.status == 200)
            window.location.href = '/sales'
        })
        .catch(err => {
          console.log(err)
          setMsg(err.response.data.msg)
          setMsg_type(err.response.data.msg_type)
        })
    }
  };


  return (
    <>
      <div className='d-flex align-items-center justify-content-center'>
        <div className={`msgAlert ${msg && 'show'}`}>
          {msg}
        </div>
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center min-vh-100 bg-light">
        {/* Main Login Form Container */}
        <div className="bg-white container shadow w-100" style={{ maxWidth: '', minWidth: '370px' }}>
          <div className='row'>
            <div className='changingImage col-md-6'></div>
            <div className='col-sm-12 col-md-6 p-4'>
              <form>
                <h3 className="text-center mb-3">Login</h3>
                <center>
                  {msg && <div style={{ filter: 'drop-shadow(0px 0px 1px)' }} className={`fs-5 alert ${msg_type == 'error' ? 'text-danger' : 'text-success'}`} role="alert"><sup className='fs-4 fw-bolder'>*</sup>{msg}</div>} {/* Display message if exists */}
                </center>
                {/* Username Field */}
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">Username</label>
                  <input type="text" className="form-control rounded-pill" id="username" placeholder="Username should not contains space" onChange={e => setUserName(e.target.value)} />
                </div>

                {/* Role  */}
                <div className="mb-3">
                  <label htmlFor="role" className="form-label">Role</label>
                  <select className="form-select rounded-pill" name="role" required onChange={e => setRole(e.target.value)}>
                    <option value="">Select Role</option>
                    <option value="employee">Employee</option>
                    <option value="bussinessAdmin">Business Admin</option>
                    <option value="superAdmin">Super Admin</option>
                    <option value="B2AAdmin">B2A Admin</option>
                  </select>
                </div>

                {/* Phone Field */}
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">Phone</label>
                  <input type="number" className="form-control rounded-pill" id="phone" value={phone} placeholder="Enter your phone number" onChange={e => ChangeNumber(e, 10)} />
                </div>

                {/* Password Field with Show/Hide Icon */}
                <div className="mb-3 position-relative">
                  <label htmlFor="password" className="form-label">Password</label>
                  <div className="input-group">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="form-control rounded-start-pill"
                      id="password"
                      placeholder="Enter your password"
                      onChange={e => setPassword(e.target.value)}
                      style={{ borderRight: '1px solid transparent', borderLeft: '1px solid #DEE2E6', borderTop: '1px solid #DEE2E6', borderBottom: '1px solid #DEE2E6' }}
                    />
                    <button
                      type="button"
                      className="btn rounded-end-pill"
                      onClick={togglePasswordVisibility}
                      style={{ borderLeft: '1px solid transparent', borderRight: '1px solid #DEE2E6', borderTop: '1px solid #DEE2E6', borderBottom: '1px solid #DEE2E6' }}
                      aria-label="Toggle password visibility"
                    >
                      <i className={`bi ${showPassword ? 'bi-eye-slash' : 'bi-eye'}`}></i>
                    </button>
                  </div>
                </div>

                {/* Login Button */}
                <button type="submit" onClick={e => handleLogin(e)} className="btn btn-primary rounded-0 w-100">Login</button>
              </form>
              <div className="text-center mt-4">
                <p>Do not have account ? Register Here</p>
                <button onClick={handleToggle} className="btn btn-success w-100 rounded-0">
                  {signUp ? 'Close Registration' : 'Sign Up'}
                </button>
              </div>
            </div>
          </div>

          {/* Sign-Up Toggle */}
        </div>

        {/* Registration Component (Sign-Up Modal) */}
        <div className={`w-100 registerPageBase${signUp ? 'Open' : 'Close'}`}>
          {signUp && <Registration signUp={signUp} setSignUp={setSignUp} />}
        </div>
      </div>
    </>
  );
};

export default Login;
