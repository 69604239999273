import { createContext, useEffect, useState, useRef } from 'react';
import './App.css';
import Login from './Authentication/Login';
import Registration from './Authentication/Registration.jsx';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import Navbar from './Utils/Navbar.jsx';
import Customers from './Pages/Customers/Customers.jsx';
import Products from './Pages/Products/Products.jsx';
import Categories from './Pages/Categories/Categories.jsx';
import OurBills from './Pages/OurBills/OurBills.jsx';
import Analytics from './Pages/Analytics/Analytics.jsx';
import Dashboard from './Pages/DashBoard/Dashboard.jsx';
import Sales from './Pages/Sales/Sales.jsx';
import EditCustomer from './Pages/Customers/EditCustomer.jsx';
import EditCategory from './Pages/Categories/EditCategory.jsx';
import PageNotFounf from './Utils/PageNotFounf.jsx';
import EditProducts from './Pages/Products/EditProduct.jsx';
import WarningProducts from './Pages/Products/WarningProducts.jsx';
import ExpiryAlert from './Pages/Products/ExpiryAlert.jsx';
import AddSales from './Pages/Sales/AddSales.jsx';
import Detail from './Pages/Sales/Detail.jsx';
import CustomerDetail from './Pages/Customers/CustomerDetail.jsx';
import EditSales from './Pages/Sales/EditSale.jsx';
import AlteredData from './Pages/Sales/AlteredData.jsx';
import Footer from './Utils/Footer.jsx';

axios.defaults.withCredentials = true;
export const UserContext = createContext(); // Exporting the context for use in other components

function App() {
  const [signUp, setSignUp] = useState(false); // Start with signUp closed
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loggedIn, setloggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null)
  const [activeLink, setActiveLink] = useState(window.location.pathname);
  const [fontSizeChange, setFontSizeChange] = useState(null);
  const [msg, setMsg] = useState(null);
  const [msg_type, setMsg_type] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const msgRef = useRef(null); // Create a ref for the message box

  useEffect(() => {
    // Define a function to fetch data
    const fetchData = () => {
      axios.get('https://posnewversionbackend.basic2ai.info/api/loggedIn')
        // axios.get('http://localhost:8010/api/loggedIn')
        .then(res => {
          setloggedIn(res.data.login);
          setUser(res.data.user);
          setRole(res.data.user.role)
        })
        .catch(err => {
          console.log(err);
        });
    };

    // Initial fetch when the component mounts
    fetchData();

    // Set an interval to fetch data every 30 seconds
    const interval = setInterval(fetchData, 3000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  const handleToggleView = () => {
    axios.post('https://posnewversionbackend.basic2ai.info/api/toggleView', { fontSizeChange: fontSizeChange })
      // axios.post('http://localhost:8010/api/toggleView', { fontSizeChange: fontSizeChange })
      .then(res => {
        if (res.status == 200) {
          setMsg('Successfully Changed the view.\n\n\nAnd It is saved for the next time.');
          setMsg_type('good');
        }
      })
      .catch(err => {
        console.log(err)
      })
  };

  // Close message when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (msgRef.current && !msgRef.current.contains(event.target)) {
        setMsg(null); // Close the message when clicking outside
      }
    };

    // Add event listener for outside clicks
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [msg]);
  useEffect(() => {
    axios.get('https://posnewversionbackend.basic2ai.info/api/getToggleView')
      // axios.get('http://localhost:8010/api/getToggleView')
      .then(res => {
        setFontSizeChange(res.data.theme)
        // console.log(res.data.theme)
      })
      .catch(err => {
        console.log(err)
      })
  })
  const handleCloseForm = () => {
    setIsFormOpen(false); // Hide form when onClose is triggered
  };

  return (
    <>
      {msg && (
        <div className='poppins-medium d-flex align-items-center justify-content-center min-vh-100' style={{ background: '#5656569f', position: 'absolute', width: '100vw', zIndex: '1050' }}>
          <div
            ref={msgRef} // Reference the message box
            className={`msgAlert p-5 rounded-3 show border border-2 show ${msg && 'show'} ${msg_type === 'good' ? 'text-success' : 'text-danger'}`}
            style={{ top: '35%', fontSize: '20px', height: '250px', width: '370px', background: 'white' }}
          >
            <div>
              <div>
                <center><i className='bi bi-check-circle animate' style={{ fontSize: '45px' }}></i></center>
              </div>
              <div className='text-center'>
                {msg}
              </div>
              <hr />
              <center>
                <div className='btn btn-success' onClick={() => setMsg(null)}> {/* Close the message when clicking 'Okay' */}
                  Okay
                </div>
              </center>
            </div>
          </div>
        </div>
      )}

      <div className='bg-light' style={{ fontSize: `${fontSizeChange ? '1.2em' : '16px'}`, minWidth: '400px' }}>
        <UserContext.Provider value={{ isFormOpen,role, handleCloseForm, handleToggleView, setIsFormOpen, sidebarOpen, setSidebarOpen, activeLink, setActiveLink, signUp, user, fontSizeChange, loggedIn, setloggedIn, setSignUp, setFontSizeChange }}> {/* Set the value as an object */}
          <Router>
            <Navbar />
            <div className={`${loggedIn && 'content'} poppins-light p-4 ${sidebarOpen ? 'shifted' : ''}`}>
              <div className={`${loggedIn && 'container'} py-5`}>
                <Routes>
                  {/* Uncomment the Registration route if needed */}
                  {/* <Route path='/register' element={<Registration />} /> */}
                  <Route path='/' element={loggedIn ? <Dashboard /> : <Login />} />
                  <Route path='/login' element={loggedIn ? <Dashboard /> : <Login />} />
                  <Route path='/sales' element={<Sales />} />
                  <Route path='/sales/add' element={<AddSales />} />
                  <Route path='/sales/rejected-altered-bills' element={<AlteredData />} />
                  <Route path='/sales/detailed/:sale_id/:customer_id/:employeeToken' element={<Detail />} />
                  <Route path='/sales/edit/:sale_id/:customer_id/:employeeToken' element={<EditSales />} />
                  <Route path='/customer' element={<Customers />} />
                  <Route path='/customer/detail/:cust_id/:cust_name/:zipCode/:role' element={<CustomerDetail />} />
                  <Route path='/products' element={<Products />} />
                  <Route path='/products/warning' element={<WarningProducts />} />
                  <Route path='/products/Expiry-Alert' element={<ExpiryAlert />} />
                  <Route path='/products/edit/:productId/:employeeToken' element={<EditProducts />} />
                  <Route path='/categories' element={<Categories />} />
                  <Route path='/categories/update' element={<Categories />} />
                  <Route path='/ourbills' element={<OurBills />} />
                  <Route path='/analytics' element={<Analytics />} />
                  <Route path='/dashboard' element={<Dashboard />} />
                  <Route path='/customer/edit/:id/:name/:phone/:zipCode/:employeeToken' element={<EditCustomer />} />
                  <Route path='/category/edit/:cat_id/:cat_name/:cat_desc/:employeeToken' element={<EditCategory />} />
                  <Route path='*' element={<PageNotFounf />} />
                </Routes>
              </div>
            </div>
            <Footer />
          </Router>
        </UserContext.Provider>
      </div>
    </>
  );
}

export default App;
