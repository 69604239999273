export const printBill = (customerDetails, billDetails, products, user) => {
  console.log(billDetails)
    const printWindow = window.open('', '', 'width=800,height=600'); 
    const billContent = `
      <html>
        <head>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 20px;
            }
            .bill-container {
              padding: 0px 20px;
            }
            h1, h2 {
              text-align: center;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            table, th, td {
              border: 1px solid #000;
            }
            th, td {
              padding: 10px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
            .bill-header {
              margin-bottom: 20px;
              border:2px solid black;

            }
            .bill-header div {
              margin: 5px 0;
              padding-left:5px;
              padding-top:5px;
              padding-right:5px;
              border-bottom:1px solid black;
            }
           .bill-header div:last-child {
            margin: 5px 0;
            border-bottom: 0px solid black;
            }

            .bill-total {
              font-weight: bold;
              text-align: right;
              margin-top: 20px;
            }
            .summary-table td {
              text-align: right;
            }
            .print-button {
              display: block;
              width: 100px;
              margin: 20px auto;
              padding: 10px;
              text-align: center;
              background-color: #4CAF50;
              color: white;
              border: none;
              cursor: pointer;
              font-size: 16px;
            }
          </style>
        </head>
        <body>
          <div class="bill-container">
            <h1>Bill Details</h1>
  
            <!-- Customer and Shop Details -->
            <div class="bill-header">
              <div><strong>Customer Name:</strong> ${customerDetails.cust_name || 'N/A'}</div>
              <div><strong>Phone:</strong> ${customerDetails.cust_phone || 'N/A'}</div>
              <div><strong>Email:</strong> ${customerDetails.cust_email || 'N/A'}</div>
              <div><strong>Shop Name:</strong> ${user?.shopName || 'N/A'}</div>
              <div><strong>Contact:</strong> ${user?.phone || 'N/A'}</div>
              <div><strong>Printing Date:</strong> ${new Date().toLocaleDateString() || 'N/A'} ${new Date().toLocaleTimeString() || 'N/A'}</div>
              <div><strong>Print By:</strong> ${user?.name || 'N/A'}</div>
            </div>
  
            <!-- Product List -->
            <h2>Products</h2>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product Name</th>
                  <th>Category</th>
                  <th>Quantity</th>
                  <th>Price (₹)</th>
                  <th>Total (₹)</th>
                </tr>
              </thead>
              <tbody>
                ${products.map((product, idx) => `
                  <tr>
                    <td>${idx + 1}</td>
                    <td>${product.product_name || 'N/A'}</td>
                    <td>${product.category_name || 'N/A'}</td>
                    <td>${product.quantity || 0}</td>
                    <td>₹${(product.total_price || 0).toFixed(2)}</td>
                    <td>₹${((product.quantity || 0) * (product.total_price || 0)).toFixed(2)}</td>
                  </tr>
                `).join('')}
              </tbody>
            </table>
  
            <!-- Bill Summary -->
            <h2>Bill Summary</h2>
            <table class="summary-table">
              <tbody>
                <tr>
                  <td><strong>Total Amount (Without Tax):</strong></td>
                  <td>₹${(billDetails.totalAmountWithoutTax || 0).toFixed(2)}</td>
                </tr>
                <tr>
                  <td><strong>Tax Percentage:</strong></td>
                  <td>${billDetails.taxPercentage || 0} %</td>
                </tr>
                <tr>
                  <td><strong>Tax Amount:</strong></td>
                  <td>₹${(billDetails.taxAmount || 0).toFixed(2)}</td>
                </tr>
                <tr>
                  <td><strong>Total Amount (With Tax):</strong></td>
                  <td>₹${(billDetails.totalAmountWithTax || 0).toFixed(2)}</td>
                </tr>
                <tr>
                  <td><strong>Amount Paid:</strong></td>
                  <td>₹${(billDetails.amountPayed || 0).toFixed(2)}</td>
                </tr>
                <tr>
                  <td><strong>Amount Left:</strong></td>
                  <td>₹${(billDetails.amountLeft || 0).toFixed(2)}</td>
                </tr>
                <tr>
                  <td><strong>Lend On:</strong></td>
                  <td>${(billDetails.givenByCustomer > 0?'Customer': billDetails.givenByShopkeeper > 0 ? 'Shop' : 'None')}</td>
                </tr>
              </tbody>
            </table>
  
            <!-- Print Button -->
            <div>
            <center style="font-size:25px;">Thanks for Purchasing the Items. See You Soon !</center>
            </div>
            <button class="print-button" onclick="window.print()">Print Bill</button>
          </div>
  
          <script>
            // Optional: Automatically open the print dialog after the page loads
            window.onload = function() {
              // Wait for the content to load and then print after a short delay
              setTimeout(() => {
                window.focus();
              }, 500);
            };
          </script>
        </body>
      </html>
    `;

    // Write the content and allow the window to load
    printWindow.document.write(billContent);
    printWindow.document.close();
};
