import React, { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink } from 'react-router-dom';
import { UserContext } from '../../App';
axios.defaults.withCredentials = true
const AddSales = () => {
    // State variables
    const [customers, setCustomers] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [customerId, setCustomerId] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [subTotal, setSubTotal] = useState(0);
    const [taxPercentage, setTaxPercentage] = useState(18);
    const [amountPaid, setAmountPaid] = useState(0);
    const [changeGiven, setChangeGiven] = useState(0);
    const [quantities, setQuantities] = useState({}); // Track quantities
    const [grandTotal, setGrandTotal] = useState(0);
    const [taxAmount, setTaxAmount] = useState(0);
    const [productSearch, setProductSearch] = useState('');
    const [customerSearch, setCustomerSearch] = useState('');
    const filteredCustomers = customers.filter(customer =>
        customer.cust_name.toLowerCase().includes(customerSearch.toLowerCase())
    );

    // Filtered products based on search
    const filteredProducts = products.filter(product =>
        product.product_name.toLowerCase().includes(productSearch.toLowerCase())
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                const customerResponse = await axios.get('https://posnewversionbackend.basic2ai.info/api/customers');
                // const customerResponse = await axios.get('http://localhost:8010/api/customers');
                const productResponse = await axios.get('https://posnewversionbackend.basic2ai.info/api/Products');
                // const productResponse = await axios.get('http://localhost:8010/api/Products');
                setCustomers(customerResponse.data.customers);
                setProducts(productResponse.data.productsFound);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const handleAddProduct = (product) => {
        if (!selectedProducts.some(p => p.product_id === product.product_id)) {
            setSelectedProducts((prev) => [...prev, product]);
            // Initialize quantity for the newly selected product
            setQuantities((prev) => ({ ...prev, [product.product_id]: 1 }));
            updateSubTotal(product.selling_price); // Initial amount for the first product
        }
    };

    const updateSubTotal = (amountChange) => {
        setSubTotal((prevSubTotal) => {
            const newSubTotal = prevSubTotal + amountChange;
            updateTaxAndTotal(newSubTotal); // Pass new subtotal directly
            return newSubTotal;
        });
    };

    const updateTaxAndTotal = (newSubTotal) => {
        const newTaxAmount = ((newSubTotal * taxPercentage) / 100).toFixed(2);  // Round to 2 decimal places
        const newGrandTotal = (parseFloat(newSubTotal) + parseFloat(newTaxAmount)).toFixed(2);  // Ensure two decimal points

        setTaxAmount(parseFloat(newTaxAmount));  // Convert back to number if necessary
        setGrandTotal(parseFloat(newGrandTotal));  // Convert back to number if necessary
        calculateChangeGiven(parseFloat(newGrandTotal));  // Pass new grand total directly
    };


    const handleQuantityChange = (productId, newValue) => {
        const product = products.find(p => p.product_id === productId);
        if (!product) return;

        const oldValue = quantities[productId] || 0; // Get the old value or default to 0
        const amountChange = (newValue - oldValue) * product.selling_price;

        if (newValue >= 0 && newValue <= product.quantity) {
            // Update quantities state
            setQuantities((prev) => ({ ...prev, [productId]: newValue }));
            updateSubTotal(amountChange); // Call updateSubTotal with amountChange
        }
    };

    const handleTaxPercentageChange = (e) => {
        const newTaxAmount = (subTotal * e.target.value) / 100;
        const newGrandTotal = subTotal + newTaxAmount;

        setTaxAmount(newTaxAmount);
        setGrandTotal(newGrandTotal);
        calculateChangeGiven(newGrandTotal);
    }
    const calculateChangeGiven = (newGrandTotal) => {
        const amountChange = amountPaid - newGrandTotal;
        setChangeGiven(amountChange < 0 ? 0 : amountChange);
    };

    const handleDeleteProduct = (productId) => {
        const product = selectedProducts.find(p => p.product_id === productId);
        if (product) {
            const productTotal = (quantities[productId] || 1) * product.selling_price;
            setSelectedProducts(prev => prev.filter(p => p.product_id !== productId));
            setSubTotal(prev => prev - productTotal); // Decrease the subtotal
            setQuantities(prev => {
                const newQuantities = { ...prev };
                delete newQuantities[productId]; // Remove quantity for the deleted product
                return newQuantities;
            });
            updateTaxAndTotal(subTotal - productTotal); // Update tax and total
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // console.log(changeGiven)
        const saleData = {
            sale_id: `sale_${Date.now()}`,
            customer_id: customerId,
            customer_name: customers.find(c => c.cust_id === customerId)?.cust_name,
            product_data: selectedProducts.map(p => ({
                product_id: p.product_id,
                quantity: quantities[p.product_id] || 1, // Use quantities state
                total_price: p.selling_price, // Total price for the product
                cat_id: p.cat_id,
                category_name: p.category_name
            })),
            numberOfItems: selectedProducts.length,
            taxPercentage,
            taxAmount,
            totalAmountWithoutTax: subTotal,
            totalAmountWithTax: grandTotal,
            amountPaid: amountPaid,
            amountLeft: amountPaid < grandTotal ? grandTotal - amountPaid : amountPaid - grandTotal,
            givenByCustomer: amountPaid < grandTotal ? amountPaid : 0,
            givenByShopkeeper: amountPaid > grandTotal ? amountPaid - grandTotal : 0,
        };
        // Confirm if the amount paid is different from the grand total
        if (amountPaid !== grandTotal) {
            Swal.fire({
                title: `Attention!`,
                text: `Given amount ${amountPaid} ₹ is ${amountPaid > grandTotal ? `${amountPaid - grandTotal} ₹ more` : `${grandTotal - amountPaid} ₹ less`} than ${grandTotal} ₹. Still want to proceed? Are you sure?`,
                icon: 'warning',
                confirmButtonText: 'Okay',
                cancelButtonText: 'Cancel',
                showCancelButton: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    // User confirmed, proceed with creating the sale
                    try {
                        await axios.post('https://posnewversionbackend.basic2ai.info/api/add/sales', saleData);
                        // await axios.post('http://localhost:8010/api/add/sales', saleData);
                        Swal.fire({
                            title: 'Success!',
                            text: 'Sale has been created successfully.',
                            icon: 'success',
                            confirmButtonText: 'Okay',
                        }).then(result => {
                            if (result.isConfirmed) {
                                window.location.reload(true)
                            }
                        });
                        resetForm();
                    } catch (error) {
                        console.error('Error creating sale:', error);
                        Swal.fire({
                            title: 'Error!',
                            text: 'Failed to create sale.',
                            icon: 'error',
                            confirmButtonText: 'Okay',
                        });
                    }
                } else if (result.isDismissed) {
                    // User canceled, handle any necessary logic here
                    alert("User canceled the sale creation.");
                }
            });
        } else {
            // If the amount paid is equal to the grand total, directly proceed to create the sale
            try {
                await axios.post('https://posnewversionbackend.basic2ai.info/api/add/sales', saleData);
                // await axios.post('http://localhost:8010/api/add/sales', saleData);
                Swal.fire({
                    title: 'Success!',
                    text: 'Sale has been created successfully.',
                    icon: 'success',
                    confirmButtonText: 'Okay',
                }).then(result => {
                    if (result.isConfirmed) {
                        window.location.reload(true)
                    }
                });
                resetForm();
            } catch (error) {
                console.error('Error creating sale:', error);
                Swal.fire({
                    title: 'Error!',
                    text: 'Failed to create sale.',
                    icon: 'error',
                    confirmButtonText: 'Okay',
                });
            }
        }
    };

    const resetForm = () => {
        setSelectedProducts([]);
        setCustomerId('');
        setSubTotal(0);
        setAmountPaid(0);
        setChangeGiven(0);
        setQuantities({}); // Reset quantities
        setTaxAmount(0);
        setGrandTotal(0);
    };

    const { fontSizeChange } = useContext(UserContext)
    return (
        <div className="container">
            <h2>Add Sale</h2>
            <NavLink to="/sales">
                <div className='btn addProductBtn2 rounded-0 mt-2 mb-2 me-2' style={{ fontSize: `${fontSizeChange ? '20px' : '16px'}` }}>
                    <i className='bi bi-arrow-left'></i> Back
                </div>
            </NavLink>
            <div className='row'>
                <div className='col-md-12 col-sm-12 col-lg-8 mt-3'>
                    <div className='card bg-light rounded-0 bg-white shadow mb-3 border p-0'>
                        <div className="form-group card-header p-0">
                            <div className='card-header rounded-0 d-flex justify-content-between' style={{background:'#9edaf1'}}>
                                <h5 className='mb-0 mt-2 fw-bold'>Products</h5>
                                <input className="form-control rounded-0 w-50" placeholder="Search for a product..." type="text" value={productSearch} onChange={(e) => setProductSearch(e.target.value)} />
                            </div>
                            <ul className="list-group list-group-flush" style={{ minHeight: '80px', maxHeight: '250px', overflowY: 'auto' }}>
                                {filteredProducts.map((product) => (
                                    <li key={product.product_id} className="list-group-item d-flex justify-content-between align-items-center p-0">
                                        <div className='text-white py-2 w-100 px-2' style={{ background: "#1CC88A", height: 'max-content' }}>
                                            {product.product_name}
                                        </div>
                                        <div className='text-white py-2 px-2 w-100' style={{ background: "#36B9CC", height: 'max-content' }}>
                                            ({product.quantity} in stock)
                                        </div>
                                        <button className="btn btn-primary rounded-0 border-2" onClick={() => {
                                            const selectedProduct = products.find(p => p.product_id === product.product_id);
                                            if (selectedProduct) handleAddProduct(selectedProduct);
                                        }} disabled={selectedProducts.some(p => p.product_id === product.product_id)} >Add</button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="card shadow rounded-0 mt-4 p-0">
                        <div className='card-header rounded-0' style={{background:'#9edaf1'}}>
                            <h5>Selected Products</h5>
                        </div>
                        <div className='table-responsive card-body'>
                            <table className="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th className='text-center'>Name</th>
                                        <th className='text-center'>Price</th>
                                        <th className='text-center'>Items</th>
                                        <th className='text-center'>Max Quantity</th>
                                        <th className='text-center'>Total Price</th>
                                        <th className='text-center'>Category</th>
                                        <th className='text-center'>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedProducts.length > 0 ? selectedProducts.map((product, index) => (
                                        <tr key={index}>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{index + 1}</td>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{product.product_name}</td>
                                            <td style={{ textAlign: 'end', verticalAlign: 'middle' }}>{product.selling_price} ₹</td>
                                            <td style={{ textAlign: 'end', verticalAlign: 'middle' }}>
                                                <input
                                                    className='form-control text-center'
                                                    style={{ maxWidth: '150px' }}
                                                    type="number"
                                                    value={quantities[product.product_id] || 1} // Get quantity or default to 1
                                                    onChange={e => {
                                                        const newValue = parseInt(e.target.value) || 1; // Convert to integer or default to 1
                                                        handleQuantityChange(product.product_id, newValue);
                                                    }}
                                                />
                                            </td>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{product.quantity}</td>
                                            <td style={{ textAlign: 'end', verticalAlign: 'middle' }}>{(quantities[product.product_id] || 1) * product.selling_price} ₹</td>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{product.category_name}</td>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                <button
                                                    className='rounded-0 btn btn-danger'
                                                    onClick={() => handleDeleteProduct(product.product_id)}
                                                >
                                                    <i className='bi bi-trash'></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )) : <tr className='text-center'><td colSpan={8}>No Items Selected</td></tr>}
                                </tbody>
                            </table>
                        </div>
                        <div className='card-footer'>
                            <div>{selectedProducts.length <= 0 ? "Please Select any item for purchase.." : `${selectedProducts.length} Kind item${selectedProducts.length == 1 ? '' : 's'} Selected...`}</div>
                        </div>
                    </div>
                </div>
                <div className='col-md-12 col-sm-12 col-lg-4 my-3'>
                    <div className='card rounded-0 bg-white shadow mb-3 border p-0'>
                        <div className='card-header rounded-0 d-flex justify-content-between' style={{background:'#9edaf1'}}>
                            <h5 className='mb-0 mt-2 fw-bold'>Customer </h5>
                            <input className="form-control rounded-0 w-50" placeholder="Search for a customer..." type="text" value={customerSearch} onChange={(e) => setCustomerSearch(e.target.value)} />
                        </div>
                        <ul className="list-group list-group-flush" style={{ maxHeight: '250px', minHeight: '80px', overflowY: 'auto ' }}>
                            {filteredCustomers.map((customer) => (
                                <li key={customer.cust_id} className="list-group-item d-flex justify-content-between align-items-center">
                                    <div>{customer.cust_name}</div>
                                    <button className="btn btn-primary btn-sm" onClick={() => {setCustomerId(customer.cust_id);setCustomerName(customer.cust_name)}}>Select</button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <form onSubmit={handleSubmit} className='bg-white p-3 shadow'>
                        <div className="form-group mt-3">
                            <label className='fw-bold'>For:</label>
                            <input className="form-control bg-warning text-black rounded-0" type="text" value={customerName||"Select Customer"} disabled />
                        </div>
                        <div className="form-group mt-3">
                            <label>Sub Total</label>
                            <input className="form-control rounded-0" type="text" value={subTotal} readOnly />
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="products">Tax Percentage</label>
                            <input type='number' className='form-control rounded-0' value={taxPercentage} onChange={e => { setTaxPercentage(e.target.value); handleTaxPercentageChange(e) }} />
                        </div>
                        <div className="form-group mt-3">
                            <label>Tax Amount ({taxPercentage}%)</label>
                            <input className="form-control rounded-0" type="text" value={taxAmount} readOnly />
                        </div>
                        <div className="form-group mt-3">
                            <label>Grand Total</label>
                            <input className="form-control rounded-0" type="text" value={grandTotal} readOnly />
                        </div>
                        <div className="form-group mt-3">
                            <label>Amount Paid</label>
                            <input
                                className="form-control rounded-0"
                                type="number"
                                value={amountPaid}
                                onChange={e => {
                                    const newAmount = parseFloat(e.target.value) || 0; // Convert to float or default to 0
                                    setAmountPaid(newAmount);
                                    calculateChangeGiven(grandTotal); // Recalculate change given
                                }}
                                required
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label>Change Given</label>
                            <input className="form-control rounded-0" type="text" value={parseInt(grandTotal - amountPaid)} readOnly />
                        </div>
                        <div className="form-group mt-3">
                            <label>Given by</label>
                            <br />
                            <label><input className="m-1" type="radio" readOnly checked={grandTotal - amountPaid > 0} />Customer</label>
                            <label><input className="m-1" type="radio" readOnly checked={grandTotal - amountPaid < 0} />Shopkeeper</label>
                            <label><input className="m-1" type="radio" readOnly checked={grandTotal - amountPaid == 0} />None</label>
                        </div>
                        <button type="submit" className="btn btn-success rounded-0 w-100 mt-4">Submit Sale</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddSales;
