import React, { useContext } from 'react'
import { UserContext } from '../../App'
import { Doughnut } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    ArcElement,
    LineElement,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    LineElement,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const DoughnutChart = ({ productLabels, productQuantities }) => {
    const { fontSizeChange } = useContext(UserContext)
    console.log(productLabels, productQuantities)
    return (
        <div className='card border-0 rounded-0'>
            <div className='card-header'><span className='fw-bolder fs-3' style={{ fontSize: `${fontSizeChange ? '30px' : '20px'}` }}>Best Selling Products</span></div>
            <div className='card-body d-flex align-items-center justify-content-center'>
                <Doughnut

                    data={{
                        labels: productLabels,
                        datasets: [{
                            label: 'Products Sold',
                            data: productQuantities,
                            backgroundColor: [
                                'rgba(0, 102, 204, 0.8)',
                                'rgba(76, 177, 64, 0.8)',
                                'rgba(0, 149, 150, 0.8)',
                            ],
                        }],
                    }}
                    options={{
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'bottom',
                            },
                        },
                    }}
                />
            </div>
            <div className='card-footer mb-0'>Items which are on demand.</div>
        </div>
    )
}

export default DoughnutChart