import React, { useContext, useEffect, useRef, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../App';
import axios from 'axios';
axios.defaults.withCredentials = true
const EditCustomer = () => {
    const [cust_id, setCust_id] = useState('');
    const [cust_name, setCust_name] = useState('');
    const [cust_email_Updated, setCust_email] = useState('');
    const [cust_phone, setCust_phone] = useState('');
    const [village, setVillage] = useState('');
    const [city, setCity] = useState('');
    const [district, setDistrict] = useState('');
    const [estate, setEstate] = useState('');
    const [zipCodeUpdated, setZipCode] = useState('');
    const [msg, setMsg] = useState(null);
    const [msg_type, setMsg_type] = useState(null);
    const { id, name, phone, zipCode, employeeToken } = useParams()
    const [customerData, setCustomerData] = useState([])
    const navigate = useNavigate()
    useEffect(() => {
        setCust_name(name)
        setCust_phone(phone)
        axios.post('https://posnewversionbackend.basic2ai.info/api/update-customer', { cust_id: id, cust_name: name, cust_phone: phone, zipCode: zipCode, employeeToken: employeeToken })
        // axios.post('http://localhost:8010/api/update-customer', { cust_id: id, cust_name: name, cust_phone: phone, zipCode: zipCode, employeeToken: employeeToken })
            .then(res => {
                if (res.status == 404) {
                    setMsg(res.data.msg)
                    setMsg_type(res.data.msg_type)
                } else {
                    setCustomerData(res.data.customer)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [])
    const msgRef = useRef(null);
    const formRef = useRef(null); // useRef to track the form element

    // Function to handle phone number and zip code input
    const handleNumber = (e, len) => {
        if (e.target.value.length <= len) {
            if (len === 6) setZipCode(e.target.value);
            else if (len === 10) setCust_phone(e.target.value);
        } else {
            alert(`It should be of length ${len}`);
        }
    };

    const { user, fontSizeChange } = useContext(UserContext);

    // Fallback for user info
    const addedBy = user?.username ? `${user.username} ${user.email} ${user.phone}` : "Unknown User";

    // Effect to handle clicks outside the form


    // Effect to handle clicks outside the message
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (msgRef.current && !msgRef.current.contains(event.target)) {
                setMsg(null); // Close the message when clicking outside
            }
        };

        // Add event listener for outside clicks
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [msg]);

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(cust_email_Updated)
        axios.post('https://posnewversionbackend.basic2ai.info/api/update/customer', { cust_id: customerData.cust_id, cust_name: name, cust_email: cust_email_Updated || customerData.cust_email, cust_phone: phone, village: village || customerData.village, city: city || customerData.city, district: district || customerData.district, estate: estate || customerData.estate, zipCode: zipCodeUpdated || customerData.zipCode, employeeToken: employeeToken })
        // axios.post('http://localhost:8010/api/update/customer', { cust_id: customerData.cust_id, cust_name: name, cust_email: cust_email_Updated || customerData.cust_email, cust_phone: phone, village: village || customerData.village, city: city || customerData.city, district: district || customerData.district, estate: estate || customerData.estate, zipCode: zipCodeUpdated || customerData.zipCode, employeeToken: employeeToken })
            .then(res => {
                if (res.status === 201) {
                    setMsg(res.data.msg);
                    setMsg_type(res.data.msg_type);
                    setInterval(() => {
                        navigate('/customer')
                    }, 5000)
                } else {
                    setMsg(res.data.msg);
                    setMsg_type(res.data.msg_type);
                }
            })
            .catch(err => {
                // console.error(err);
                setMsg('Error Updating customer.');
                setMsg_type('error');
            });
    };

    return (
        <div>
            {msg && (
                <div className='d-flex align-items-center justify-content-center min-vh-100' style={{ background: '#5656569f', position: 'absolute', width: '100vw', zIndex: '1050', left: '0px', top: "0px" }}>
                    <div
                        ref={msgRef} // Reference the message box
                        className={`msgAlert animate2 p-5 rounded-3 show border border-2 show ${msg ? 'show' : 'notShow'} ${msg_type === 'good' ? 'text-success' : 'text-danger'}`}
                        style={{ top: '35%', fontSize: '20px', height: '250px', width: '370px', background: 'white' }}
                    >
                        <div className=''>
                            <div>
                                <center>{msg_type == 'error' ? <i className='bi bi-x-circle animate' style={{ fontSize: '45px' }}></i> : <i className='bi bi-check-circle animate' style={{ fontSize: '45px' }}></i>}</center>
                            </div>
                            <div className='text-center'>
                                {msg}
                            </div>
                            <hr />
                            <center>
                                <div className={`btn ${msg_type === 'error' ? 'btn-danger' : 'btn-success'} rounded-0`} onClick={() => setMsg(null)}> {/* Close the message when clicking 'Okay' */}
                                    Okay
                                </div>
                            </center>
                        </div>
                    </div>
                </div>
            )}
            <div className="addCustomers">
                <div className="d-flex justify-content-center" style={{ marginTop: '100px' }}>
                    {/* Apply ref to form and toggle form visibility using CSS classes */}
                    <div className={`container border bg-white`} style={{ minHeight: '700px' }}>
                        <div className="btn rounded-0 my-3 py-2 ps-0 mainPageName" style={{ fontWeight: '600', fontSize: `${fontSizeChange ? '25px' : '18px'}` }}>
                            Update Customer
                        </div>
                        <br />
                        {/* Back button to close the form */}
                        <NavLink to='/customer'>
                            <div className="btn rounded-0 mb-3 text-white py-2 px-4 mainPageBack" style={{ fontSize: `${fontSizeChange ? '20px' : '16px'}` }} >
                                <i className="bi bi-arrow-left me-2"></i> Back
                            </div>
                        </NavLink>
                        <form className="p-3 bg-light" onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-6 mb-3">
                                    <div className="input-group">
                                        <label>Customer Name</label>
                                        <input type="text" className="form-control w-100" placeholder={customerData.cust_name} onChange={e => setCust_name(e.target.value)} disabled />
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <div className="input-group">
                                        <label>Email</label>
                                        <input type="email" className="form-control w-100" placeholder={customerData.cust_email} value={cust_email_Updated} onChange={e => setCust_email(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <div className="input-group">
                                        <label>Phone</label>
                                        <input type="number" className="form-control w-100" placeholder={customerData.cust_phone} onChange={e => handleNumber(e, 10)} disabled />
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <div className="input-group">
                                        <label>Village</label>
                                        <input type="text" className="form-control w-100" value={village} placeholder={customerData.village} onChange={e => setVillage(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <div className="input-group">
                                        <label>City</label>
                                        <input type="text" className="form-control w-100" value={city} placeholder={customerData.city} onChange={e => setCity(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <div className="input-group">
                                        <label>District</label>
                                        <input type="text" className="form-control w-100" value={district} placeholder={customerData.district} onChange={e => setDistrict(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <div className="input-group">
                                        <label>State</label>
                                        <input type="text" className="form-control w-100" value={estate} placeholder={customerData.estate} onChange={e => setEstate(e.target.value)} required />
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <div className="input-group">
                                        <label>Zip Code</label>
                                        <input type="number" className="form-control w-100" value={zipCodeUpdated} placeholder={customerData.zipCode} onChange={e => handleNumber(e, 6)} required />
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <label>Added by</label>
                                    <input type="text" className="form-control w-100" value={addedBy} disabled />
                                </div>
                            </div>
                            <button type='submit' className="btn rounded-0 text-white" style={{ background: '#1CC88A' }}>Update Customer</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditCustomer