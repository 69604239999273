import React, { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../App';
import AddCustomer from './AddCustomer';
import { exportToPDF, exportToExcel } from '../../Utils/ExportUtils.js';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
axios.defaults.withCredentials = true;

const Customers = () => {
  const { user, loggedIn, fontSizeChange } = useContext(UserContext);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // State for search query
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [customersPerPage, setCustomersPerPage] = useState(20); // State for number of customers per page
  const [msg, setMsg] = useState(null);
  const [msg_type, setMsg_type] = useState(null);
  // Filter customers based on search query
  const filteredCustomers = customers.filter(customer =>
    customer.cust_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    customer.cust_email.toLowerCase().includes(searchQuery.toLowerCase()) ||
    customer.cust_phone.toString().includes(searchQuery.toLowerCase()) ||
    customer.zipCode.toString().includes(searchQuery.toLowerCase()) ||
    customer.employeeToken.toString().includes(searchQuery.toLowerCase()) ||
    customer.bussinessToken.toString().includes(searchQuery.toLowerCase()) ||
    customer.superAdminToken.toString().includes(searchQuery.toLowerCase())
  );

  // Pagination logic
  const indexOfLastCustomer = currentPage * customersPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - customersPerPage;
  const currentCustomers = filteredCustomers.slice(indexOfFirstCustomer, indexOfLastCustomer);

  const totalPages = Math.ceil(filteredCustomers.length / customersPerPage);

  const handleCloseForm = () => {
    setIsFormOpen(false); // Hide form when onClose is triggered
  };
  useEffect(() => {
    axios.get('https://posnewversionbackend.basic2ai.info/api/customers')
    // axios.get('http://localhost:8010/api/customers')
      .then(res => {
        setCustomers(res.data.customers);
      })
      .catch(err => {
        console.log(err);
      });
  }, [isFormOpen, msg]);
  const handleExportPDF = () => {
    if (filteredCustomers.length < 1) {
      Swal.fire({
        title: 'Attention !',
        text: 'No data to print !',
        confirmButtonText: 'Okay',
        icon: 'warning'
      })
      return
    }
    const columnsToRemove = ['id', 'city', 'district', 'cust_id', 'employeeToken', 'bussinessToken', "superAdminToken", "B2AAdminToken", "createdAt", "updatedAt"];
    const columns = Object.keys(filteredCustomers[0]).filter(column => !columnsToRemove.includes(column));
    columns[0] = 'Name';
    columns[1] = 'Email';
    columns[2] = 'Phone';
    columns[4] = 'State';
    const rows = filteredCustomers.map((customer, index) => {
      const filteredValues = Object.entries(customer)
        .filter(([key]) => !columnsToRemove.includes(key))
        .map(([key, value]) => value);
      return [index + 1, ...filteredValues];
    });
    const finalColumns = ['#', ...columns];
    exportToPDF('Customer List', finalColumns, rows, 'customers.pdf');
  };

  const handleExportExcel = () => {
    if (filteredCustomers.length < 1) {
      Swal.fire({
        title: 'Attention !',
        text: 'No data to print !',
        confirmButtonText: 'Okay',
        icon: 'warning'
      })
      return
    }
    const columns = Object.keys(filteredCustomers[0]).slice(0, -1);
    const data = filteredCustomers.map((customer, index) => [...Object.values(customer).slice(0, -1)]);
    exportToExcel('Customers', [columns, ...data], 'customers.xlsx');
  };




  // Handle page changes
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle next and previous page actions
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Handle number of items per page change
  const handleCustomersPerPageChange = (e) => {
    setCustomersPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to page 1 after changing items per page
  };

  const handleDelete = (cust_id, cust_name, cust_phone, zipCode, employeeToken) => {
    Swal.fire({
      text: 'Do you really want to delete this item ? ',
      title: 'Attention',
      confirmButtonColor: '#DC3545',
      cancelButtonColor: '#4E73DE',
      icon: 'error',
      showCancelButton: true
    }).then(res => {
      if (res.isDismissed) {
        return
      }
      else {
        axios.post('https://posnewversionbackend.basic2ai.info/api/delete/customer', { cust_id: cust_id, cust_name: cust_name, cust_phone: cust_phone, zipCode: zipCode, employeeToken: employeeToken })
        // axios.post('http://localhost:8010/api/delete/customer', { cust_id: cust_id, cust_name: cust_name, cust_phone: cust_phone, zipCode: zipCode, employeeToken: employeeToken })
          .then(res => {
            if (res.data.deleted) {
              setMsg(res.data.msg);
              setMsg_type(res.data.msg_type);
              const timeout = setTimeout(() => {
                setMsg(null);
              }, 2500);

              return () => clearTimeout(timeout); // Clear the timeout properly
            } else {
              setMsg(res.data.msg);
              setMsg_type(res.data.msg_type);

              // Clear the message after 2.5 seconds
              const timeout = setTimeout(() => {
                setMsg(null);
              }, 2500);

              return () => clearTimeout(timeout); // Clear the timeout properly
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    })
      .catch(err => {
        Swal.fire({
          text: 'Do you really want to delete this item ? ',
          title: 'Attention',
          confirmButtonColor: '#4E73DE',
        })
      })

  }
  const msgRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (msgRef.current && !msgRef.current.contains(event.target)) {
        setMsg(null); // Close the message when clicking outside
      }
    };

    // Add event listener for outside clicks
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [msg]);
  return (
    <div className='poppins-light min-vh-100'>
      {msg && (
        <div className='d-flex align-items-center justify-content-center' style={{ background: '#5656569f', minHeight: '0px', position: 'absolute', width: '100vw', zIndex: '1050', left: '0px', top: "0px" }}>
          <div
            ref={msgRef} // Reference the message box
            className={`msgAlert animate2  p-5 rounded-3 ${msg ? 'show' : 'notShow'} ${msg_type === 'good' ? 'text-success' : 'text-danger'}`}
            style={{ top: '', fontWeight: '800', fontSize: '25px', height: '150px', width: '370px', background: '#a6dcff' }}
          >
            <div className=''>
              <div>
                <center>{msg_type == 'error' ? <i className='bi bi-x-circle animate' style={{ fontSize: '35px' }}></i> : <i className='bi bi-check-circle animate' style={{ fontSize: '35px' }}></i>}</center>
              </div>
              <div className='text-center'>
                {msg}
              </div>
            </div>
          </div>
        </div>
      )}
      {isFormOpen && (
        <div className=''>
          <AddCustomer fontSizeChange={fontSizeChange} onClose={handleCloseForm} isFormOpen={isFormOpen} />
        </div>
      )}
      <div className='btn rounded-0 py-2 ps-0 mainPageName' style={{ fontSize: `${fontSizeChange ? '35px' : '25px'}` }}>Customers</div>
      <br />
      {(user && user.role === 'employee') && (
        <button className='btn addCustomerBtn rounded-0 py-2 my-4' style={{ fontSize: `${fontSizeChange ? "20px" : "16px"}` }} onClick={() => setIsFormOpen(true)}>
          <i className='bi bi-plus'></i> Add Customer
        </button>
      )}
      {
        loggedIn ? (
          <div className='card shadow'>
            <div className='card-header bg-light'>
              <button style={{ fontSize: `${fontSizeChange ? '20px' : '16px'}` }} className='btn perivewButtons rounded-0 me-3 my-2' onClick={handleExportPDF}>
                <i className='bi bi-file-pdf'></i> PDF
              </button>
              <button style={{ fontSize: `${fontSizeChange ? '20px' : '16px'}` }} className='btn perivewButtons rounded-0 me-3 my-2' onClick={handleExportExcel}>
                <i className='bi bi-file-excel'></i> Excel
              </button>
            </div>
            <div className='card-body'>
              <div className='input-group d-flex align-items-center justify-content-between'>
                <label className='me-1'>Search: </label>
                <input
                  type='search'
                  className='form-control rounded-pill'
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <hr />

              {/* Items per page dropdown */}
              <div className='mb-3 input-group d-flex align-items-center'>
                <label className='me-2'>Items per page: </label>
                <select className='border rounded-0 px-2' style={{ maxWidth: '100px' }} value={customersPerPage} onChange={handleCustomersPerPageChange}>
                  <option className='bg-secondary .bg-gradient text-white' value={customersPerPage} disabled>{customersPerPage}</option>
                  <option style={{ textAlign: 'end' }} value={1}>1</option>
                  <option style={{ textAlign: 'end' }} value={5}>5</option>
                  <option style={{ textAlign: 'end' }} value={10}>10</option>
                  <option style={{ textAlign: 'end' }} value={20}>20</option>
                  <option style={{ textAlign: 'end' }} value={50}>50</option>
                  <option style={{ textAlign: 'end' }} value={100}>100</option>
                </select>
                <b className='mx-2'>or</b>
                <input className='form-control rounded-pill' style={{ maxWidth: '50px' }} onChange={e => setCustomersPerPage(e.target.value > 0 ? e.target.value : 20)} />
              </div>

              {/* Display the paginated data */}
              <div className='table-responsive'>
                <table className='table table-hover table-bordered table-striped'>
                  <thead className="text-center">
                    <tr>
                      <th className="text-center bg-primary text-white">#</th>
                      <th className="text-center bg-primary text-white" style={{ minWidth: '150px' }}>Name</th>
                      <th className="text-center bg-primary text-white" style={{ minWidth: '150px' }}>Email</th>
                      <th className="text-center bg-primary text-white" style={{ minWidth: '150px' }}>Phone</th>
                      <th className="text-center bg-primary text-white" style={{ minWidth: '250px' }}>Address</th>
                      <th className="text-center bg-primary text-white" style={{ minWidth: '120px' }}>Zip Code</th>
                      {user.role === 'employee' && <th className="text-center bg-primary text-white" style={{ minWidth: '150px' }}>Action</th>}
                      {(user.role === 'superAdmin' || user.role === 'B2AAdmin' || user.role === 'bussinessAdmin' || user.role === 'employee') && <th className="text-center bg-primary text-white" style={{ minWidth: '150px' }}>By</th>}
                      {(user.role === 'B2AAdmin' || user.role === 'bussinessAdmin' || user.role === 'superAdmin') && <th className="text-center bg-primary text-white" style={{ minWidth: '150px' }}>Admin 1</th>}
                      {(user.role === 'superAdmin' || user.role === 'B2AAdmin') && <th className="text-center bg-primary text-white" style={{ minWidth: '150px' }}>Admin 2</th>}
                      {user.role === 'B2AAdmin' && <th className="text-center bg-primary text-white" style={{ minWidth: '150px' }}>Admin 3</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      currentCustomers.length > 0 ? currentCustomers.map((data, idx) => (
                        <tr key={idx}>
                          <td>{indexOfFirstCustomer + idx + 1}</td>
                          <td>{data.cust_name}</td>
                          <td>{data.cust_email ? data.cust_email : 'N/A'}</td>
                          <td className='text-center'>{data.cust_phone}</td>
                          <td>{data.village}, {data.city}, {data.district}, {data.estate}</td>
                          <td className='text-center'>{data.zipCode}</td>
                          {user.role === 'employee' && <td>
                            <NavLink to={`/customer/detail/${data.cust_id}/${data.cust_name}/${data.zipCode}/${user.role}`} className='text-decoration-none'><button className='btn text-white rounded-0 px-1 py-0 btn-info'><i className='bi bi-info-circle fs-3 '></i></button></NavLink>
                            {user.role === 'employee' && <NavLink className='text-decoration-none' to={`/customer/edit/${data.cust_id}/${data.cust_name}/${data.cust_phone}/${data.zipCode}/${data.employeeToken}`}> <button className='btn text-white rounded-0 px-1 py-0 btn-warning'><i className='bi bi-pencil-square fs-3'></i></button> </NavLink>}
                            {user.role === 'employee' && <button className='btn rounded-0 px-1 py-0 btn-danger' onClick={e => handleDelete(data.cust_id, data.cust_name, data.cust_phone, data.zipCode, data.employeeToken)}><i className='bi bi-trash fs-3'></i></button>}
                          </td>}
                          <td>{data.employeeToken.split('@')[0]}</td>
                          {(user.role === 'superAdmin' || user.role === 'B2AAdmin' || user.role === 'bussinessAdmin') && <td>{data.bussinessToken.split('@')[0]}</td>}
                          {(user.role === 'superAdmin' || user.role === 'B2AAdmin') && <td>{data.superAdminToken.split('@')[0]}</td>}
                          {user.role === 'B2AAdmin' && <td>{data.B2AAdminToken.split('@')[0]}</td>}
                        </tr>
                      )) : <tr>
                        <td colSpan={6} className='text-center'>No Customers Found</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>

              {/* Pagination Controls */}
              <div className='d-flex justify-content-between align-items-center p-1' style={{ maxWidth: '' }}>
                <div className='d-flex py-1' style={{ maxWidth: '50vw' }}>
                  <button
                    className={`btn border-0 rounded-0 mx-1 ${currentPage === 1 ? 'bg-purple-disabled  disabled' : 'bg-purple'}`}
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>

                  <div className='bg-white paginationNumbers d-flex py-1' style={{ maxWidth: '350px', overflowX: 'auto' }}>
                    {Array.from({ length: totalPages }, (_, index) => (
                      <button
                        key={index}
                        style={{ boxShadow: '2px 2px 2px 1px #c2bebebd', filter: 'drop-shadow(0px 0px 20px #c2bebebd)' }}
                        className={`btn rounded-0 mx-1 ${currentPage === index + 1 ? 'text-primary btn-light' : 'btn-light'}`}
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </button>
                    ))}

                  </div>
                  <button
                    className={`btn border-0 rounded-0 mx-1 ${currentPage === totalPages ? 'bg-purple-disabled disabled' : 'bg-purple'}`}
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
                <b>Current Page No. is : {currentPage} </b>
              </div>
            </div>
          </div>
        ) : (
          <div>You are not authorised...</div>
        )
      }
    </div>
  );
}

export default Customers;
