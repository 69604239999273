import React, { useState } from 'react';
import SalesDataPage from './SalesDataPage';
import axios from 'axios';
axios.defaults.withCredentials = true
const ParentComponent = ({ salesToday, salesThisMonth, salesThisYear, user }) => {
  const [timeBtns, setTimeLine] = useState({ btn1: true, btn2: false, btn3: false });

  // Determine which sales data to pass based on timeBtns state
  const getSelectedSalesData = () => {
    if (timeBtns.btn1) return salesToday;
    if (timeBtns.btn2) return salesThisMonth;
    if (timeBtns.btn3) return salesThisYear;
    return []; // Default in case no button is selected
  };

  return (
    <div>
      <SalesDataPage
        salesData={getSelectedSalesData()}
        setTimeLine={setTimeLine}
        timeBtns={timeBtns}
        user={user}
      />
    </div>
  );
};

export default ParentComponent;
