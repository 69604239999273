import React, { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink, useParams } from 'react-router-dom';
import { UserContext } from '../../App';
axios.defaults.withCredentials = true

const EditSales = () => {
    const { sale_id, employeeToken } = useParams(); // Assuming the sale_id comes from URL params for editing a sale
    const [customers, setCustomers] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [customerId, setCustomerId] = useState('');
    const [subTotal, setSubTotal] = useState(0);
    const [taxPercentage, setTaxPercentage] = useState(18);
    const [amountPaid, setAmountPaid] = useState(0);
    const [changeGiven, setChangeGiven] = useState(0);
    const [quantities, setQuantities] = useState({});
    const [grandTotal, setGrandTotal] = useState(0);
    const [taxAmount, setTaxAmount] = useState(0);
    const [productSearch, setProductSearch] = useState('');
    const [customerSearch, setCustomerSearch] = useState('');
    const filteredCustomers = customers.filter(customer =>
        customer.cust_name.toLowerCase().includes(customerSearch.toLowerCase())
    );
    const [sale_info, setSale_info] = useState([])
    const [customerName, setCustomerName] = useState('')
    const filteredProducts = products.filter(product =>
        product.product_name.toLowerCase().includes(productSearch.toLowerCase())
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch customers and products from backend
                const customerResponse = await axios.get('https://posnewversionbackend.basic2ai.info/api/customers');
                // const customerResponse = await axios.get('http://localhost:8010/api/customers');
                const productResponse = await axios.get('https://posnewversionbackend.basic2ai.info/api/Products');
                // const productResponse = await axios.get('http://localhost:8010/api/Products');
                setCustomers(customerResponse.data.customers);
                setProducts(productResponse.data.productsFound);
                // console.log(sale_id)
                const saleResponse = await axios.get(`https://posnewversionbackend.basic2ai.info/api/specific/product/${sale_id}/${employeeToken}`);
                // const saleResponse = await axios.get(`http://localhost:8010/api/specific/product/${sale_id}/${employeeToken}`);
                const saleData = saleResponse.data.sale;
                setSale_info(saleData || [])
                setCustomerName(saleData.customer_name)
                setCustomerId(saleData.customer_id);
                setSelectedProducts(saleData.product_data);
                setQuantities(saleData.product_data.reduce((acc, prod) => {
                    acc[prod.product_id] = prod.quantity;
                    return acc;
                }, {}));

                // Initialize subtotal, tax, grand total
                const initialSubTotal = saleData.totalAmountWithoutTax;
                const initialTaxAmount = saleData.taxAmount;
                const initialGrandTotal = saleData.totalAmountWithTax;

                setSubTotal(initialSubTotal);
                setTaxAmount(initialTaxAmount);
                setGrandTotal(initialGrandTotal);
                setAmountPaid(saleData.amountPaid);
                setChangeGiven(saleData.givenByCustomer);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [sale_id]);

    const handleAddProduct = (product) => {
        if (!selectedProducts.some(p => p.product_id === product.product_id)) {
            setSelectedProducts((prev) => [...prev, product]);
            // Initialize quantity for the newly selected product
            setQuantities((prev) => ({ ...prev, [product.product_id]: 1 }));
            updateSubTotal(product.selling_price); // Initial amount for the first product
        }
    };

    const updateSubTotal = (amountChange) => {
        setSubTotal((prevSubTotal) => {
            const newSubTotal = prevSubTotal + amountChange;
            updateTaxAndTotal(newSubTotal); // Pass new subtotal directly
            return newSubTotal;
        });
    };

    const updateTaxAndTotal = (newSubTotal) => {
        const newTaxAmount = ((newSubTotal * taxPercentage) / 100).toFixed(2);  // Round to 2 decimal places
        const newGrandTotal = (parseFloat(newSubTotal) + parseFloat(newTaxAmount)).toFixed(2);  // Ensure two decimal points

        setTaxAmount(parseFloat(newTaxAmount));  // Convert back to number if necessary
        setGrandTotal(parseFloat(newGrandTotal));  // Convert back to number if necessary
        calculateChangeGiven(parseFloat(newGrandTotal));  // Pass new grand total directly
    };
    const handleQuantityChange = (productId, newValue) => {
        const product = products.find(p => p.product_id === productId);
        if (!product) return;

        const oldValue = quantities[productId] || 0; // Get the old value or default to 0
        const amountChange = (newValue - oldValue) * product.selling_price;

        if (newValue >= 0 && newValue <= product.quantity) {
            // Update quantities state
            setQuantities((prev) => ({ ...prev, [productId]: newValue }));
            updateSubTotal(amountChange); // Call updateSubTotal with amountChange
        }
    };

    const handleTaxPercentageChange = (e) => {
        const newTaxAmount = (subTotal * e.target.value) / 100;
        const newGrandTotal = subTotal + newTaxAmount;

        setTaxAmount(newTaxAmount);
        setGrandTotal(newGrandTotal);
        calculateChangeGiven(newGrandTotal);
    }
    const calculateChangeGiven = (newGrandTotal) => {
        const amountChange = amountPaid - newGrandTotal;
        setChangeGiven(amountChange < 0 ? 0 : amountChange);
    };

    const handleDeleteProduct = (productId) => {
        const product = selectedProducts.find(p => p.product_id === productId);
        if (product) {
            const productTotal = (quantities[productId] || 1) * product.selling_price;
            setSelectedProducts(prev => prev.filter(p => p.product_id !== productId));
            setSubTotal(prev => prev - productTotal); // Decrease the subtotal
            setQuantities(prev => {
                const newQuantities = { ...prev };
                delete newQuantities[productId]; // Remove quantity for the deleted product
                return newQuantities;
            });
            updateTaxAndTotal(subTotal - productTotal); // Update tax and total
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const saleData = {
            sale_id: sale_id,
            customer_id: customerId,
            customer_name: customerName,
            product_data: selectedProducts.map(p => ({
                product_id: p.product_id,
                quantity: quantities[p.product_id] || 1,
                total_price: p.selling_price,
                cat_id: p.cat_id,
                category_name: p.category_name
            })),
            numberOfItems: selectedProducts.length,
            taxPercentage,
            taxAmount,
            totalAmountWithoutTax: subTotal,
            totalAmountWithTax: grandTotal,
            amountPaid: amountPaid,
            amountLeft: amountPaid < grandTotal ? grandTotal - amountPaid : amountPaid - grandTotal,
            givenByCustomer: amountPaid < grandTotal ? amountPaid : 0,
            givenByShopkeeper: amountPaid > grandTotal ? amountPaid - grandTotal : 0,
        };

        if (amountPaid !== grandTotal) {
            Swal.fire({
                title: `Attention!`,
                text: `Given amount ${amountPaid} ₹ is ${amountPaid > grandTotal ? `${amountPaid - grandTotal} ₹ more` : `${grandTotal - amountPaid} ₹ less`} than ${grandTotal} ₹. Still want to proceed? Are you sure?`,
                icon: 'warning',
                confirmButtonText: 'Okay',
                cancelButtonText: 'Cancel',
                showCancelButton: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await axios.post(`https://posnewversionbackend.basic2ai.info/api/sales/update/${sale_id}`, saleData);
                        // await axios.post(`http://localhost:8010/xpi/sales/update/${sale_id}`, saleData);
                        Swal.fire({
                            title: 'Success!',
                            text: result.data.msg,
                            icon: result.data.msg_type,
                            confirmButtonText: 'Done',
                        }).then(result => {
                            if (result.isConfirmed) {
                                window.location.href = '/sales'
                            }
                        });
                    } catch (error) {
                        console.error('Error updating sale:', error);
                        Swal.fire({
                            title: 'Error!',
                            text: 'Failed to update sale.',
                            icon: 'error',
                            confirmButtonText: 'Okay',
                        });
                    }
                }
            });
        } else {
            try {
                await axios.post(`https://posnewversionbackend.basic2ai.info/api/sales/update/${sale_id}`, saleData);
                // await axios.post(`http://localhost:8010/api/sales/update/${sale_id}`, saleData);
                Swal.fire({
                    title: 'Success!',
                    text: 'Sale has been updated successfully.',
                    icon: 'success',
                    confirmButtonText: 'Okay',
                }).then(result => {
                    if (result.isConfirmed) {
                        window.location.href = '/sales'
                    }
                });
            } catch (error) {
                console.error('Error updating sale:', error);
                Swal.fire({
                    title: 'Error!',
                    text: 'Failed to update sale.',
                    icon: 'error',
                    confirmButtonText: 'Okay',
                });
            }
        }
    };

    const { fontSizeChange } = useContext(UserContext);
    // console.log(selectedProducts)
    return (
        <div className="container">
            <h2>Edit Sale</h2>
            <NavLink to="/sales">
                <div className='btn addProductBtn2 rounded-0 mt-2 mb-2 me-2' style={{ fontSize: `${fontSizeChange ? '20px' : '16px'}` }}>
                    <i className='bi bi-arrow-left'></i> Back
                </div>
            </NavLink>
            <div className='row'>
                <div className='col-md-12 col-sm-12 col-lg-8'>
                    <div className='card bg-light rounded-0 bg-white shadow mb-3 border p-0'>
                        <div className="form-group card-header p-0">
                            <div className='card-header rounded-0 d-flex justify-content-between' style={{ background: '#9edaf1' }}>
                                <h5 className='mb-0 mt-2 fw-bold'>Products List</h5>
                                <input className="form-control rounded-0 w-75" placeholder="Search for a product..." type="text" value={productSearch} onChange={(e) => setProductSearch(e.target.value)} />
                            </div>
                            <ul className="list-group list-group-flush" style={{ minHeight: '80px', maxHeight: '250px', overflowY: 'auto' }}>
                                {filteredProducts.map((product) => (
                                    <li key={product.product_id} className="list-group-item d-flex justify-content-between align-items-center p-0">
                                        <div className='text-white py-2 w-100 px-2' style={{ overflowX: 'auto', background: "#1CC88A", height: '40px' }}>
                                            {product.product_name}
                                        </div>
                                        <div className='text-white py-2 px-2 w-100' style={{ overflowX: 'auto', background: "#36B9CC", height: '40px' }}>
                                            ({product.quantity} in stock)
                                        </div>
                                        <button className="btn btn-primary rounded-0 border-2" onClick={() => {
                                            const selectedProduct = products.find(p => p.product_id === product.product_id);
                                            if (selectedProduct) handleAddProduct(selectedProduct);
                                        }} disabled={selectedProducts.some(p => p.product_id === product.product_id)} >Add</button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="card rounded-0 bg-white shadow mb-3 border p-0">
                        <div className="card-header rounded-0" style={{ background: '#9edaf1' }}>
                            <h5 className='mb-0 mt-2 fw-bold'>Selected Products</h5>
                        </div>
                        <div className='table-responsive card-body'>
                            <table className="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th className='text-center'>Name</th>
                                        <th className='text-center'>Price</th>
                                        <th className='text-center'>Items</th>
                                        <th className='text-center'>Max Quantity</th>
                                        <th className='text-center'>Total Price</th>
                                        <th className='text-center'>Category</th>
                                        <th className='text-center'>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedProducts.length > 0 ? selectedProducts.map((product, index) => {
                                        // Find the corresponding product from the products array using product_id
                                        const foundProduct = products.find(p => p.product_id === product.product_id);
                                        const availableQuantity = foundProduct ? foundProduct.quantity : 'N/A'; // If found, use its quantity, otherwise show 'N/A'
                                        // console.log("Product foud", foundProduct)
                                        const sellingPrice = foundProduct ? foundProduct.selling_price : 'N/A';
                                        return (
                                            <tr key={index}>
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{index + 1}</td>
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{product.product_name}</td>
                                                <td style={{ textAlign: 'end', verticalAlign: 'middle' }}>{sellingPrice} ₹</td>
                                                <td style={{ textAlign: 'end', verticalAlign: 'middle' }}>
                                                    <input
                                                        className='form-control text-center'
                                                        style={{ maxWidth: '150px' }}
                                                        type="number"
                                                        value={quantities[product.product_id] || 1} // Get quantity or default to 1
                                                        onChange={e => {
                                                            const newValue = parseInt(e.target.value) || 1; // Convert to integer or default to 1
                                                            handleQuantityChange(product.product_id, newValue);
                                                        }}
                                                    />
                                                </td>
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                    {availableQuantity} {/* Display available quantity from products array */}
                                                </td>
                                                <td style={{ textAlign: 'end', verticalAlign: 'middle' }}>
                                                    {(quantities[product.product_id] || 1) * sellingPrice} ₹
                                                </td>
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{product.category_name}</td>
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                    <button
                                                        className='rounded-0 btn btn-danger'
                                                        onClick={() => handleDeleteProduct(product.product_id)}
                                                    >
                                                        <i className='bi bi-trash'></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    }) : (
                                        <tr className='text-center'>
                                            <td colSpan={8}>No Items Selected</td>
                                        </tr>
                                    )}
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
                <div className='col-md-12 col-sm-12 col-lg-4'>
                    <div className='card rounded-0 bg-white shadow mb-3 border p-0'>
                        <div className='card-header rounded-0 d-flex justify-content-between' style={{ background: '#9edaf1' }}>
                            <h5 className='mb-0 mt-2 fw-bold'>Customer</h5>
                            <input className="form-control rounded-0 w-50" placeholder="Search for a customer..." type="text" value={customerName} disabled />
                            {/* <input className="form-control rounded-0 w-75" placeholder="Search for a customer..." type="text" value={customerName} onChange={(e) => setCustomerSearch(e.target.value)} /> */}
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-sm-12 col-md-6 border bg-light p-1' style={{}}>Total: {sale_info.totalAmountWithoutTax} ₹</div>
                                <div className='col-sm-12 col-md-6 border bg-light p-1' style={{}}>Tax Amount: {sale_info.taxAmount} ₹</div>
                                <div className='col-sm-12 col-md-6 border bg-light p-1' style={{}}>Grand Total: {sale_info.totalAmountWithTax} ₹</div>
                                <div className='col-sm-12 col-md-6 border bg-light p-1' style={{}}>Already Paid: {sale_info.amountPayed} ₹</div>
                                <div className='col-sm-12 col-md-6 border bg-light p-1' style={{}}>Tax %: {sale_info.taxPercentage}</div>
                                <div className='col-sm-12 col-md-6 border bg-light p-1' style={{}}>Due: {sale_info.amountLeft} ₹</div>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className="bg-white p-3 shadow">
                        <div className="row mb-3">
                            <div className="col-12">
                                <label>Sub Total</label>
                                <input className="form-control rounded-0" type="text" value={subTotal} readOnly />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-12">
                                <label htmlFor="products">Tax Percentage</label>
                                <input
                                    type="number"
                                    className="form-control rounded-0"
                                    value={taxPercentage}
                                    onChange={e => {
                                        setTaxPercentage(e.target.value);
                                        handleTaxPercentageChange(e);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-12">
                                <label>Tax Amount ({taxPercentage}%)</label>
                                <input className="form-control rounded-0" type="text" value={taxAmount} readOnly />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-12">
                                <label>Grand Total</label>
                                <input className="form-control rounded-0" type="text" value={grandTotal} readOnly />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-12">
                                <label>Amount Paid</label>
                                <input
                                    className="form-control rounded-0"
                                    type="number"
                                    value={amountPaid}
                                    onChange={e => {
                                        const newAmount = parseFloat(e.target.value) || 0; // Convert to float or default to 0
                                        setAmountPaid(newAmount);
                                        calculateChangeGiven(grandTotal); // Recalculate change given
                                    }}
                                    required
                                />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-12">
                                <label>Change Given</label>
                                <input
                                    className="form-control rounded-0"
                                    type="text"
                                    value={parseFloat(grandTotal - amountPaid)}
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-12">
                                <label>Given by</label>
                                <div>
                                    <label>
                                        <input
                                            className="m-1"
                                            type="radio"
                                            readOnly
                                            checked={grandTotal - amountPaid > 0}
                                        />
                                        Customer
                                    </label>
                                    <label>
                                        <input
                                            className="m-1"
                                            type="radio"
                                            readOnly
                                            checked={grandTotal - amountPaid < 0}
                                        />
                                        Shopkeeper
                                    </label>
                                    <label>
                                        <input
                                            className="m-1"
                                            type="radio"
                                            readOnly
                                            checked={grandTotal - amountPaid === 0}
                                        />
                                        None
                                    </label>
                                </div>
                            </div>
                        </div>

                        <button type="submit" className="btn btn-success rounded-0 w-100 mt-4">
                            Submit Sale
                        </button>
                    </form>
                </div>
            </div>





        </div>
    );
};

export default EditSales;
