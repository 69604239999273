import axios from 'axios';
import React, { useState, useEffect } from 'react';
axios.defaults.withCredentials = true
// This is the main Sales Data page component
const SalesDataPage = ({ salesData, user, setTimeLine, timeBtns }) => {
    const [filteredSalesData, setFilteredSalesData] = useState(salesData);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1); // For pagination
    const [itemsPerPage, setItemsPerPage] = useState(10); // Items per page
    const [totalPages, setTotalPages] = useState(1);
    // console.log(salesData)
    // Filter sales data based on search query


    const filterSalesData = (data) => {
        if (!searchQuery) return data;

        return data.filter(sale => {
            const customerName = sale.dataValues.customer_name?.trim().toLowerCase() || '';
            const productNames = sale.product_data?.map(data => data.product_name?.trim().toLowerCase()) || [];

            // Safely extract tokens and other attributes with optional chaining
            const employeeToken = sale.dataValues.employeeToken?.trim().toLowerCase() || '';
            const bussinessToken = sale.dataValues.bussinessToken?.trim().toLowerCase() || '';
            const superAdminToken = sale.dataValues.superAdminToken?.trim().toLowerCase() || '';
            const B2AAdminToken = sale.dataValues.B2AAdminToken?.trim().toLowerCase() || '';

            // Safely extract product data fields and convert numbers to strings
            const productIds = sale.product_data?.map(data => String(data.product_id)?.toLowerCase()) || [];
            const quantities = sale.product_data?.map(data => String(data.quantity)?.toLowerCase()) || [];
            const totalPrices = sale.product_data?.map(data => String(data.total_price)?.toLowerCase()) || [];

            // Check if any of these fields match the search query
            const employeeTokenMatch = employeeToken.includes(searchQuery.toLowerCase());
            const bussinessTokenMatch = bussinessToken.includes(searchQuery.toLowerCase());
            const superAdminTokenMatch = superAdminToken.includes(searchQuery.toLowerCase());
            const B2AAdminTokenMatch = B2AAdminToken.includes(searchQuery.toLowerCase());

            const productIdMatch = productIds.some(id => id.includes(searchQuery.toLowerCase()));
            const quantityMatch = quantities.some(quantity => quantity.includes(searchQuery.toLowerCase()));
            const totalPriceMatch = totalPrices.some(price => price.includes(searchQuery.toLowerCase()));

            // Return true if any field matches
            return (
                customerName.includes(searchQuery.toLowerCase()) ||
                productNames.some(name => name.includes(searchQuery.toLowerCase())) ||
                employeeTokenMatch ||
                bussinessTokenMatch ||
                superAdminTokenMatch ||
                B2AAdminTokenMatch ||
                productIdMatch ||
                quantityMatch ||
                totalPriceMatch
            );
        });
    };




    // Re-filter the sales data when searchQuery or salesData changes
    useEffect(() => {
        const filteredData = filterSalesData(salesData);
        setFilteredSalesData(filteredData);
        setTotalPages(Math.ceil(filteredData.length / itemsPerPage)); // Calculate total pages
    }, [searchQuery, salesData, itemsPerPage]);

    // Render the sales data table
    const renderSalesData = (data) => {
        if (data.length === 0) {
            return (
                <tr>
                    <td colSpan={getColumnSpan(user.role)} style={{ textAlign: 'center' }}>
                        No sales data available.
                    </td>
                </tr>
            );
        }

        return data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((sale, index) => (
            <tr key={index}>
                <td className='text-center'>{new Date(sale.dataValues.createdAt).toLocaleDateString()}</td>
                <td className='text-center'>{new Date(sale.dataValues.createdAt).toLocaleTimeString()}</td>
                <td>{sale.dataValues.customer_name}</td>
                <td>
                    {sale.product_data.map((data, idx) => (
                        <span key={idx}>
                            {data.product_name}{idx < sale.product_data.length - 1 && ', '}
                        </span>
                    ))}
                </td>
                <td className='text-center'>{sale.dataValues.employeeToken.split('@')[0] || 'N/A'}</td>
                {renderAdminTokens(sale)}
            </tr>
        ));
    };

    const getColumnSpan = (role) => {
        if (role === 'bussinessAdmin') return 6;
        if (role === 'superAdmin') return 7;
        if (role === 'B2AAdmin') return 8;
        return 5; // Default
    };

    const renderAdminTokens = (sale) => {
        if (user?.role === 'bussinessAdmin' || user?.role === 'superAdmin' || user?.role === 'B2AAdmin') {
            return (
                <td className='text-center'>{sale.dataValues.bussinessToken.split('@')[0] || 'N/A'}</td>
            );
        }
        if (user?.role === 'superAdmin' || user?.role === 'B2AAdmin') {
            return (
                <td className='text-center'>{sale.dataValues.superAdminToken.split('@')[0] || 'N/A'}</td>
            );
        }
        if (user?.role === 'B2AAdmin') {
            return (
                <td className='text-center'>{sale.dataValues.B2AAdminToken.split('@')[0] || 'N/A'}</td>
            );
        }
        return null;
    };

    // Handle pagination
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className='my-3 bg-white p-3 shadow'>
            <h2>Sales Data</h2>

            {/* Time Filter Buttons */}
            <div className='d-flex justify-content-start mb-3'>
                <button className={`me-2 btn ${timeBtns.btn1 ? 'disabled bg-secondary text-dark' : 'btn-warning'} rounded-0`} onClick={() => setTimeLine({ btn1: true, btn2: false, btn3: false })}>Today</button>
                <button className={`me-2 btn ${timeBtns.btn2 ? 'disabled bg-secondary text-dark' : 'btn-success'} rounded-0`} onClick={() => setTimeLine({ btn1: false, btn2: true, btn3: false })}>This Month</button>
                <button className={`me-2 btn ${timeBtns.btn3 ? 'disabled bg-secondary text-dark' : 'btn-primary'} rounded-0`} onClick={() => setTimeLine({ btn1: false, btn2: false, btn3: true })}>This Year</button>
            </div>

            {/* Search input for sales data */}
            <div className='mb-3'>
                <input
                    type="text"
                    placeholder="Search Sales Data"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="form-control"
                />
            </div>

            {/* Items per page selection */}
            <div className="mb-3 d-flex justify-content-start align-items-center">
                <label className="me-2" style={{ minWidth: '150px' }}>Items per page:</label>
                <select
                    value={itemsPerPage}
                    onChange={(e) => setItemsPerPage(Number(e.target.value))}
                    className="form-select w-auto"
                >
                    <option value={itemsPerPage} disabled>{itemsPerPage}</option>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                </select>
                <b className='mx-1'>OR</b>
                <input className='form-control ms-2' placeholder={itemsPerPage} onChange={(e) => setItemsPerPage(e.target.value > 0 ? Number(e.target.value) : 10)} />
            </div>

            {/* Sales Data Table */}
            <div className='table-responsive mb-0 pb-0'>
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th className='text-center'>Date</th>
                            <th className='text-center'>Time</th>
                            <th className='text-center'>Customer</th>
                            <th className='text-center'>Items</th>
                            <th className='text-center'>Employee Token</th>
                            {user?.role === 'bussinessAdmin' && <th className='text-center'>Business Token</th>}
                            {(user?.role === 'superAdmin' || user?.role === 'B2AAdmin') && <th className='text-center'>Admin Token</th>}
                            {user?.role === 'B2AAdmin' && <th className='text-center'>B2A Admin</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {renderSalesData(filteredSalesData)}
                    </tbody>
                </table>

                {/* Pagination Controls */}
                <nav>
                    <ul className='pagination'>
                        {/* Previous Button */}
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button className='page-link' onClick={handlePreviousPage}>
                                Previous
                            </button>
                        </li>

                        {/* Page Numbers */}
                        {Array.from({ length: totalPages }, (_, i) => (
                            <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                <button className='page-link' onClick={() => handlePageChange(i + 1)}>
                                    {i + 1}
                                </button>
                            </li>
                        ))}

                        {/* Next Button */}
                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <button className='page-link' onClick={handleNextPage}>
                                Next
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default SalesDataPage;
