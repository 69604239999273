import axios from 'axios'
import './Category.css'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { exportToPDF, exportToExcel } from '../../Utils/ExportUtils.js';
import { UserContext } from '../../App'
import AddCategories from './AddCategories.jsx';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
axios.defaults.withCredentials = true

const Categories = () => {
  const { user, loggedIn, fontSizeChange } = useContext(UserContext)
  const [searchQuery, setSearchQuery] = useState(''); // State for search query
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [categoryPerPage, setcategoryPerPage] = useState(20); // State for number of category per page
  const [msg, setMsg] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [msg_type, setMsg_type] = useState(null);

  // Fetch categories from the server
  const [category, setcategory] = useState([]);
  useEffect(() => {
    axios.get('https://posnewversionbackend.basic2ai.info/api/categories')
    // axios.get('http://localhost:8010/api/categories')
      .then(res => {// Check if data is structured as expected
        setcategory(res.data.categoriesFound);
      })
      .catch(error => console.error('Error fetching categories:', error));
  }, [msg, isFormOpen]);

  // Filter categories based on search query
  const filteredcategory = searchQuery.trim() === '' ? category : category.filter(category =>
    category.cat_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    category.cat_desc.toLowerCase().includes(searchQuery.toLowerCase()) ||
    category.employeeToken.toString().includes(searchQuery) ||
    category.bussinessToken.toString().includes(searchQuery) ||
    category.superAdminToken.toString().includes(searchQuery)
  );
  // Pagination logic
  const indexOfLastCustomer = currentPage * categoryPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - categoryPerPage;
  const currentcategory = filteredcategory.slice(indexOfFirstCustomer, indexOfLastCustomer);

  const totalPages = Math.ceil(filteredcategory.length / categoryPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle next and previous page actions
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const msgRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (msgRef.current && !msgRef.current.contains(event.target)) {
        setMsg(null); // Close the message when clicking outside
      }
    };

    // Add event listener for outside clicks
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [msg]);
  const handleExportPDF = () => {
    if (filteredcategory.length < 1) {
      Swal.fire({
        title: 'Attention !',
        text: 'No data to print !',
        confirmButtonText: 'Okay',
        icon: 'warning'
      })
      return
    }
    const columnsToRemove = ['id', 'city', 'district', 'cust_id', 'employeeToken', 'bussinessToken', "superAdminToken", "B2AAdminToken", "createdAt", "updatedAt"];
    const columns = Object.keys(filteredcategory[0]).filter(column => !columnsToRemove.includes(column));
    columns[0] = 'Category ID';
    columns[1] = 'Name';
    columns[2] = 'Description';
    // console.log(columns)
    const rows = filteredcategory.map((customer, index) => {
      const filteredValues = Object.entries(customer)
        .filter(([key]) => !columnsToRemove.includes(key))
        .map(([key, value]) => value);
      return [index + 1, ...filteredValues];
    });
    const finalColumns = ['#', ...columns];
    exportToPDF('Customer List', finalColumns, rows, 'category.pdf');
  };

  const handlecategoryPerPageChange = (e) => {
    setcategoryPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to page 1 after changing items per page
  };

  const handleExportExcel = () => {
    if (filteredcategory.length < 1) {
      Swal.fire({
        title: 'Attention !',
        text: 'No data to print !',
        confirmButtonText: 'Okay',
        icon: 'warning'
      })
      return
    }
    const columns = Object.keys(filteredcategory[0]).slice(0, -1);
    const data = filteredcategory.map((customer, index) => [...Object.values(customer).slice(0, -1)]);
    exportToExcel('category', [columns, ...data], 'category.xlsx');
  };

  const handleCloseForm = () => {
    setIsFormOpen(false); // Hide form when onClose is triggered
  };
  const handleDelete = (cat_id, cat_name, employeeToken) => {
    // axios.post('http://localhost:8010/api/delete/category', { cat_id: cat_id, cat_name: cat_name, employeeToken: employeeToken })
    axios.post('https://posnewversionbackend.basic2ai.info/api/delete/category', { cat_id: cat_id, cat_name: cat_name, employeeToken: employeeToken })
      .then(res => {
        // console.log(res.data)
        if (res.status == 200) {
          setMsg(res.data.msg);
          setMsg_type(res.data.msg_type);

          // Clear the message after 2.5 seconds
          const timeout = setTimeout(() => {
            setMsg(null);
          }, 2500);

          return () => clearTimeout(timeout); // Clear the timeout properly
        } else {
          setMsg(res.data.msg);
          setMsg_type(res.data.msg_type);

          // Clear the message after 2.5 seconds
          const timeout = setTimeout(() => {
            setMsg(null);
          }, 2500);

          return () => clearTimeout(timeout); // Clear the timeout properly
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
  return (
    <div>
      {msg && (
        <div className='d-flex align-items-center justify-content-center' style={{ background: '', minHeight: '0px', position: 'absolute', width: '100vw', zIndex: '1050', left: '0px', top: "0px" }}>
          <div
            // ref={msgRef} // Reference the message box
            className={`msgAlert animate2  p-5 rounded-3 ${msg ? 'show ' : 'notShow'} ${msg_type === 'good' ? 'text-white bg-success' : 'text-white bg-danger'}`}
            style={{ top: '', fontWeight: '800', fontSize: '25px', height: '150px', width: '370px', background: '' }}
          >
            <div className=''>
              <div>
                <center>{msg_type == 'error' ? <i className='bi bi-x-circle animate' style={{ fontSize: '35px' }}></i> : <i className='bi bi-check-circle animate' style={{ fontSize: '35px' }}></i>}</center>
              </div>
              <div className='text-center'>
                {msg}
              </div>
            </div>
          </div>
        </div>
      )}
      {user ? (
        <div className='bg-light container'>
          <div className='' style={{ fontSize: `${fontSizeChange ? '35px' : '25px'}` }}>Product Categories</div>
          <div className='btn addCategoryBtn rounded-0 my-4' style={{ fontSize: `${fontSizeChange ? '25px' : '20px'}` }} onClick={() => setIsFormOpen(true)}>
            <i className='bi bi-plus-lg'></i> Create New Category
          </div>

          {isFormOpen && (
            <div className=''>
              <AddCategories fontSizeChange={fontSizeChange} onClose={handleCloseForm} isFormOpen={isFormOpen} />
            </div>
          )}

          <div className='card'>
            <div className='card-header bg-light py-1 px-3' style={{ color: '#4E73DF' }}>Categories</div>
            <div className='card-body p- bg-white'>
              <div className=''>
                <button style={{ fontSize: `${fontSizeChange ? '20px' : '16px'}` }} className='btn perivewButtons rounded-0 me-3 my-2' onClick={handleExportPDF}>
                  <i className='bi bi-file-pdf'></i> PDF
                </button>
                <button style={{ fontSize: `${fontSizeChange ? '20px' : '16px'}` }} className='btn perivewButtons rounded-0 me-3 my-2' onClick={handleExportExcel}>
                  <i className='bi bi-file-excel'></i> Excel
                </button>
                <div className='input-group d-flex align-items-center justify-content-between'>
                  <label className='me-1'>Search: </label>
                  <input
                    type='search'
                    className='form-control rounded-pill'
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </div>

              <div className='mb-3 input-group d-flex align-items-center my-3'>
                <label className='me-2'>Items per page: </label>
                <select className='border rounded-0 px-2' style={{ maxWidth: '100px' }} value={categoryPerPage} onChange={handlecategoryPerPageChange}>
                  <option className='bg-secondary .bg-gradient text-white' value={categoryPerPage} disabled>{categoryPerPage}</option>
                  <option style={{ textAlign: 'end' }} value={1}>1</option>
                  <option style={{ textAlign: 'end' }} value={5}>5</option>
                  <option style={{ textAlign: 'end' }} value={10}>10</option>
                  <option style={{ textAlign: 'end' }} value={20}>20</option>
                  <option style={{ textAlign: 'end' }} value={50}>50</option>
                  <option style={{ textAlign: 'end' }} value={100}>100</option>
                </select>
                <b className='mx-2'>or</b>
                <input className='form-control rounded-pill' style={{ maxWidth: '100px' }} onChange={e => setcategoryPerPage(e.target.value > 0 ? e.target.value : 20)} />
              </div>

              <div className='table-responsive'>
                <table className='table table-hover table-striped table-bordered'>
                  <thead >
                    <tr >
                      <th className='bg-primary text-white'>#</th>
                      <th className=' text-center bg-primary text-white ' style={{ minWidth: '150px' }}>Category ID</th>
                      <th className=' text-center bg-primary text-white' style={{ minWidth: '150px' }}>Name</th>
                      <th className=' text-center bg-primary text-white' style={{ minWidth: '250px' }}>Description</th>
                      {user.role === 'employee' && <th className='text-center bg-primary text-white' style={{ minWidth: '120px' }}>Action</th>}
                      {(user.role === 'superAdmin' || user.role === 'B2AAdmin' || user.role === 'bussinessAdmin' || user.role === 'employee') && <th className="text-center bg-primary text-white" style={{ minWidth: '150px' }}>By</th>}
                      {(user.role === 'B2AAdmin' || user.role === 'bussinessAdmin' || user.role === 'superAdmin') && <th className="text-center bg-primary text-white" style={{ minWidth: '150px' }}>Admin 1</th>}
                      {(user.role === 'superAdmin' || user.role === 'B2AAdmin') && <th className="text-center bg-primary text-white" style={{ minWidth: '150px' }}>Admin 2</th>}
                      {user.role === 'B2AAdmin' && <th className="text-center bg-primary text-white" style={{ minWidth: '150px' }}>Admin 3</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      currentcategory.length > 0 ? (
                        currentcategory.map((data, idx) => (
                          <tr className='' key={idx}>
                            <td>{parseInt(idx) + 1}</td>
                            <td>{data.cat_id}</td>
                            <td>{data.cat_name}</td>
                            <td>{data.cat_desc}</td>
                            {user.role === 'employee' && (
                              <td className='text-center'>
                                <NavLink to={`/category/edit/${data.cat_id}/${data.cat_name}/${data.cat_desc}/${data.employeeToken}`}>
                                  <button className='btn rounded-0 btn-warning text-white' style={{ fontSize: `${fontSizeChange ? '20px' : '16px'}` }}>
                                    <i className='bi bi-pencil-square'></i>
                                  </button>
                                </NavLink>
                                <button className='btn rounded-0 btn-danger  text-white' onClick={e => handleDelete(data.cat_id, data.cat_name, data.employeeToken)} style={{ fontSize: `${fontSizeChange ? '20px' : '16px'}` }}>
                                  <i className='bi bi-trash'></i>
                                </button>
                              </td>
                            )}
                            <td>{data.employeeToken.split('@')[0]}</td>
                            {(user.role === 'superAdmin' || user.role === 'B2AAdmin' || user.role === 'bussinessAdmin') && <td>{data.bussinessToken.split('@')[0]}</td>}
                            {(user.role === 'superAdmin' || user.role === 'B2AAdmin') && <td>{data.superAdminToken.split('@')[0]}</td>}
                            {user.role === 'B2AAdmin' && <td>{data.B2AAdminToken.split('@')[0]}</td>}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" className="text-center">No categories found.</td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>

              {/* Pagination Controls */}
              <div className='d-flex justify-content-between align-items-center p-1' style={{ maxWidth: '' }}>
                <div className='d-flex py-1' style={{ maxWidth: '50vw' }}>
                  <button
                    className={`btn border-0 rounded-0 mx-1 ${currentPage === 1 ? 'bg-purple-disabled  disabled' : 'bg-purple'}`}
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>

                  <div className='bg-white paginationNumbers d-flex py-1' style={{ maxWidth: '350px', overflowX: 'auto' }}>
                    {Array.from({ length: totalPages }, (_, index) => (
                      <button
                        key={index}
                        style={{ boxShadow: '2px 2px 2px 1px #c2bebebd', filter: 'drop-shadow(0px 0px 20px #c2bebebd)' }}
                        className={`btn rounded-0 mx-1 ${currentPage === index + 1 ? 'text-primary btn-light' : 'btn-light'}`}
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </button>
                    ))}

                  </div>
                  <button
                    className={`btn border-0 rounded-0 mx-1 ${currentPage === totalPages ? 'bg-purple-disabled disabled' : 'bg-purple'}`}
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
                <b>Current Page No. is : {currentPage} </b>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>You must be logged in to view categories.</div>
      )}
    </div>
  );
};

export default Categories;
