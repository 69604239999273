import React, { useContext, useEffect, useRef, useState } from 'react'
import { UserContext } from '../../App';
import axios from 'axios';
axios.defaults.withCredentials = true
const AddCategories = ({ fontSizeChange, onClose, isFormOpen }) => {
    const [cust_id, setCust_id] = useState('');
    const [cat_name, setCat_name] = useState('');
    const [cat_desc, setCat_desc] = useState('');
    const [cust_phone, setCust_phone] = useState('');
    const [village, setVillage] = useState('');
    const [city, setCity] = useState('');
    const [district, setDistrict] = useState('');
    const [estate, setEstate] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [msg, setMsg] = useState(null);
    const [msg_type, setMsg_type] = useState(null);

    const msgRef = useRef(null);
    const formRef = useRef(null); // useRef to track the form element

    // Function to handle phone number and zip code input
    const handleNumber = (e, len) => {
        if (e.target.value.length <= len) {
            if (len === 6) setZipCode(e.target.value);
            else if (len === 10) setCust_phone(e.target.value);
        } else {
            alert(`It should be of length ${len}`);
        }
    };

    const { user } = useContext(UserContext);

    // Fallback for user info
    const addedBy = user?.username ? `${user.username} ${user.email} ${user.phone}` : "Unknown User";

    // Effect to handle clicks outside the form
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (formRef.current && !formRef.current.contains(event.target)) {
                onClose(); // Close the form when clicked outside
            }
        };

        // Add event listener for clicks
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener on unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    // Effect to handle clicks outside the message
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (msgRef.current && !msgRef.current.contains(event.target)) {
                setMsg(null); // Close the message when clicking outside
            }
        };

        // Add event listener for outside clicks
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [msg]);

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('https://posnewversionbackend.basic2ai.info/api/register/categories', { cat_name: cat_name, cat_desc: cat_desc })
        // axios.post('http://localhost:8010/api/register/categories', { cat_name: cat_name, cat_desc: cat_desc })
            .then(res => {
                if (res.status === 201) {
                    setMsg(res.data.msg);
                    setMsg_type(res.data.msg_type);
                    setCat_name('');
                    setCat_desc('');
                } else {
                    setMsg(res.data.msg);
                    setMsg_type(res.data.msg_type);
                }
            })
            .catch(err => {
                console.error(err);
                setMsg('Error creating Category.');
                setMsg_type('error');
            });
    };

    return (
        <div>
            {msg && (
                <div className='d-flex align-items-center justify-content-center min-vh-100' style={{ background: '#5656569f', position: 'absolute', width: '100vw', zIndex: '1050', left: '0px', top: "-20px" }}>
                    <div
                        ref={msgRef} // Reference the message box
                        className={`msgAlert animate2 p-5 rounded-3 show border border-2 show ${msg ? 'show' : 'notShow'} ${msg_type === 'good' ? 'text-success' : 'text-danger'}`}
                        style={{ top: '35%', fontSize: '20px', height: '250px', width: '370px', background: 'white' }}
                    >
                        <div className=''>
                            <div>
                                <center>{msg_type == 'error' ? <i className='bi bi-x-circle animate' style={{ fontSize: '45px' }}></i> : <i className='bi bi-check-circle animate' style={{ fontSize: '45px' }}></i>}</center>
                            </div>
                            <div className='text-center'>
                                {msg}
                            </div>
                            <hr />
                            <center>
                                <div className={`btn ${msg_type === 'error' ? 'btn-danger' : 'btn-success'} rounded-0`} onClick={() => setMsg(null)}> {/* Close the message when clicking 'Okay' */}
                                    Okay
                                </div>
                            </center>
                        </div>
                    </div>
                </div>
            )}
            <div className="addCategories">
                <div className="d-flex justify-content-center" style={{ marginTop: '100px' }}>
                    {/* Apply ref to form and toggle form visibility using CSS classes */}
                    <div ref={formRef} className={`container border bg-white`} style={{ minHeight: '550px',overflow:'auto' }}>
                        <div className="btn rounded-0 my-3 py-2 ps-0 mainPageName" style={{ fontWeight: '600', fontSize: `${fontSizeChange ? '25px' : '18px'}` }}>
                            Add Category
                        </div>
                        <br />
                        {/* Back button to close the form */}
                        <div className="btn rounded-0 mb-3 text-white py-2 px-4 mainPageBack" style={{ fontSize: `${fontSizeChange ? '20px' : '16px'}` }} onClick={onClose}>
                            <i className="bi bi-arrow-left me-2"></i> Back
                        </div>
                        <form className="p-3" onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <div className="input-group">
                                        <label>Name</label>
                                        <input type="text" className="form-control w-100" value={cat_name} onChange={e => setCat_name(e.target.value)} required />
                                    </div>
                                </div>
                                <div className="col-12 mb-3">
                                    <div className="input-group">
                                        <label>Desc</label>
                                        <textarea type="text" rows={2} style={{ resize: 'none' }} className="form-control w-100" value={cat_desc} onChange={e => setCat_desc(e.target.value)} required />
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <label>Added by</label>
                                    <input type="text" className="form-control w-100" value={addedBy} readOnly disabled />
                                </div>
                            </div>
                            <button type='submit' className="btn rounded-0 text-white" style={{ background: '#1CC88A' }}>Create Category</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddCategories