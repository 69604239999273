import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { exportToPDF, exportToExcel } from '../../Utils/ExportUtils.js';
import { UserContext } from '../../App';
import AddProducts from './AddProducts.jsx';
import { NavLink } from 'react-router-dom';
import './Product.css'
axios.defaults.withCredentials = true;

const WarningProducts = () => {
    const { user, loggedIn, fontSizeChange } = useContext(UserContext);
    const [product, setProduct] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [ProductPerPage, setProductPerPage] = useState(20);
    const [msg, setMsg] = useState(null);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [msg_type, setMsg_type] = useState(null);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        axios.get('https://posnewversionbackend.basic2ai.info/api/categories')
        // axios.get('http://localhost:8010/api/categories')
            .then(res => {
                setCategories(res.data.categoriesFound);
            })
            .catch(error => console.error('Error fetching categories:', error));
    }, [msg, isFormOpen]);

    useEffect(() => {
        axios.get('https://posnewversionbackend.basic2ai.info/api/Products')
        // axios.get('http://localhost:8010/api/Products')
            .then(res => {
                // console.log(res.data)
                setProduct(res.data.productsFound.filter(items => (items.quantity / 2 < items.productWarning)));
                // console.log(res.data.productsFound.filter(items => (items.quantity / 2 > items.productWarning)))
            })
            .catch(error => console.error('Error fetching Products:', error));
    }, [msg, isFormOpen]);

    // Ensure filteredProduct is always an array
    const filteredProduct = product ? product.filter(prod =>
        searchQuery.trim() === '' ||
        prod.product_id.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
        prod.product_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        prod.product_desc.toLowerCase().includes(searchQuery.toLowerCase()) ||
        prod.employeeToken?.toString().includes(searchQuery) ||
        prod.bussinessToken?.toString().includes(searchQuery) ||
        prod.superAdminToken?.toString().includes(searchQuery)
    ) : [];

    // Pagination logic
    const indexOfLastProduct = currentPage * ProductPerPage;
    const indexOfFirstProduct = indexOfLastProduct - ProductPerPage;

    const currentProduct = filteredProduct.slice(indexOfFirstProduct, indexOfLastProduct);
    const totalPages = Math.ceil(filteredProduct.length / ProductPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const msgRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (msgRef.current && !msgRef.current.contains(event.target)) {
                setMsg(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [msg]);

    const handleExportPDF = () => {
        const columnsToInclude = ['product_name', 'category_name', 'cost_price', 'selling_price', 'quantity'];
        const columns = Object.keys(filteredProduct[0] || {}).filter(column => columnsToInclude.includes(column));
        columns[0] = 'Name'
        columns[1] = 'Cost'
        columns[2] = 'Sell'
        columns[3] = 'Quantity'
        columns[4] = 'Category'
        const rows = filteredProduct.map((customer, index) => {
            const filteredValues = Object.entries(customer)
                .filter(([key]) => columnsToInclude.includes(key))
                .map(([key, value]) => value);
            return [index + 1, ...filteredValues];
        });
        const finalColumns = ['#', ...columns];
        exportToPDF('Product Warning List', finalColumns, rows, 'Product.pdf');
    };

    const handleProductPerPageChange = (e) => {
        setProductPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handleExportExcel = () => {
        const columns = Object.keys(filteredProduct[0] || {}).slice(0, -1);
        const data = filteredProduct.map((customer) => [...Object.values(customer).slice(0, -1)]);
        exportToExcel('Product Warning List', [columns, ...data], 'Product.xlsx');
    };

    const handleCloseForm = () => {
        setIsFormOpen(false);
    };

    const handleDelete = (cat_id, product_id, employeeToken) => {
        axios.post('https://posnewversionbackend.basic2ai.info/api/delete/Product', { cat_id: cat_id, product_id: product_id, employeeToken: employeeToken })
        // axios.post('http://localhost:8010/api/delete/Product', { cat_id: cat_id, product_id: product_id, employeeToken: employeeToken })
            .then(res => {
                setMsg(res.data.msg);
                setMsg_type(res.data.msg_type);
                const timeout = setTimeout(() => {
                    setMsg(null);
                }, 2500);
                return () => clearTimeout(timeout);
            })
            .catch(err => console.log(err));
    };

    return (
        <div>
            {msg && (
                <div className='d-flex align-items-center justify-content-center' style={{ background: '', minHeight: '0px', position: 'absolute', width: '100vw', zIndex: '1050', left: '0px', top: "0px" }}>
                    <div
                        // ref={msgRef} // Reference the message box
                        className={`msgAlert animate2  p-5 rounded-3 ${msg ? 'show ' : 'notShow'} ${msg_type === 'good' ? 'text-white bg-success' : 'text-white bg-danger'}`}
                        style={{ top: '', fontWeight: '800', fontSize: '25px', height: '150px', width: '370px', background: '' }}
                    >
                        <div className=''>
                            <div>
                                <center>{msg_type == 'error' ? <i className='bi bi-x-circle animate' style={{ fontSize: '35px' }}></i> : <i className='bi bi-check-circle animate' style={{ fontSize: '35px' }}></i>}</center>
                            </div>
                            <div className='text-center'>
                                {msg}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {user ? (
                <div className='bg-light container'>
                    <div style={{ fontSize: `${fontSizeChange ? '35px' : '25px'}` }}>Products</div>
                    <NavLink to='/products'>
                        <div className="btn rounded-0 mt-4 mb-2 text-white me-2 px-4 mainPageBack" style={{ fontSize: `${fontSizeChange ? '20px' : '16px'}` }} >
                            <i className="bi bi-arrow-left me-2"></i> Back
                        </div>
                    </NavLink>
                    <NavLink to="/products/warning">
                        <div className='btn bg-warning addProductBtn2 rounded-0 mt-4 mb-2 me-2' style={{ pointerEvents: 'none', borderRight: "0px solid transparent", borderTop: "0px solid transparent", borderLeft: "0px solid transparent", borderBottom: '1px solid blue !important', fontSize: `${fontSizeChange ? '20px' : '16px'}` }}>
                            <i className='bi bi-exclamation-triangle-fill'></i> Product Warnings
                        </div>
                    </NavLink>
                    <NavLink to='/products/Expiry-Alert'>
                        <div className='btn addProductBtn3 rounded-0 mb-2 mt-4' style={{ fontSize: `${fontSizeChange ? '20px' : '16px'}` }}>
                            <i className='bi bi-cart3'></i> Expiry Alert
                        </div>
                    </NavLink>

                    {isFormOpen && (
                        <AddProducts fontSizeChange={fontSizeChange} onClose={handleCloseForm} isFormOpen={isFormOpen} />
                    )}

                    <div className='card'>
                        <div className='card-header bg-light py-1 px-3' style={{ color: '#4E73DF' }}>Products</div>
                        <div className='card-body bg-white'>
                            <div className=''>
                                <button style={{ fontSize: `${fontSizeChange ? '20px' : '16px'}` }} className='btn perivewButtons rounded-0 me-3 my-2' onClick={handleExportPDF}>
                                    <i className='bi bi-file-pdf'></i> PDF
                                </button>
                                <button style={{ fontSize: `${fontSizeChange ? '20px' : '16px'}` }} className='btn perivewButtons rounded-0 me-3 my-2' onClick={handleExportExcel}>
                                    <i className='bi bi-file-excel'></i> Excel
                                </button>
                                <div className='input-group d-flex align-items-center justify-content-between'>
                                    <label className='me-1'>Search: </label>
                                    <input
                                        type='search'
                                        className='form-control rounded-pill'
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className='mb-3 input-group d-flex align-items-center my-3'>
                                <label className='me-2'>Items per page: </label>
                                <select className='border rounded-0 px-2' style={{ maxWidth: '100px' }} value={ProductPerPage} onChange={handleProductPerPageChange}>
                                    <option className='bg-secondary .bg-gradient text-white' value={ProductPerPage} disabled>{ProductPerPage}</option>
                                    <option style={{ textAlign: 'end' }} value={1}>1</option>
                                    <option style={{ textAlign: 'end' }} value={5}>5</option>
                                    <option style={{ textAlign: 'end' }} value={10}>10</option>
                                    <option style={{ textAlign: 'end' }} value={20}>20</option>
                                    <option style={{ textAlign: 'end' }} value={50}>50</option>
                                    <option style={{ textAlign: 'end' }} value={100}>100</option>
                                </select>
                                <b className='mx-2'>or</b>
                                <input className='form-control rounded-pill' style={{ maxWidth: '100px' }} onChange={e => setProductPerPage(e.target.value > 0 ? e.target.value : 20)} />
                            </div>

                            <div className='table-responsive'>
                                <table className='table table-hover table-bordered table-striped'>
                                    <thead>
                                        <tr>
                                            <th style={{ minWidth: '150px' }} className='text-center'>Name</th>
                                            <th style={{ minWidth: '150px' }} className='text-center'>Description</th>
                                            <th style={{ minWidth: '150px' }} className='text-center'>Category</th>
                                            <th style={{ minWidth: '150px' }} className='text-center'>Cost Price</th>
                                            <th style={{ minWidth: '150px' }} className='text-center'>Selling Price</th>
                                            <th style={{ minWidth: '' }} className='text-center'>Qty.</th>
                                            {user.role == 'employee' && <th style={{ minWidth: '150px' }} className='text-center'>Action</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentProduct.length > 0 ? (
                                            currentProduct.map((product, index) => (
                                                <>
                                                    {parseInt(product.quantity) - parseInt(product.productWarning) < parseInt(parseInt(product.quantity) / 2) ?
                                                        <tr key={index}>
                                                            <td className='' style={{ verticalAlign: 'middle', textAlign: '' }}>{product.product_name}</td>
                                                            <td className='' style={{ verticalAlign: 'middle', textAlign: '' }}>{product.product_desc}</td>
                                                            <td className='' style={{ verticalAlign: 'middle', textAlign: 'center' }}>{product.category_name}</td>
                                                            <td className='' style={{ verticalAlign: 'middle', textAlign: 'end' }}>{product.cost_price} ₹</td>
                                                            <td className='' style={{ verticalAlign: 'middle', textAlign: 'end' }}>{product.selling_price} ₹</td>
                                                            <td style={{ background: `${parseInt(product.quantity) - parseInt(product.productWarning) > parseInt(product.productWarning) ? '#34c45b' : parseInt(product.quantity) / 2 - parseInt(product.productWarning) > parseInt(product.productWarning) ? '#FFC107' : parseInt(product.quantity) / 2 - parseInt(product.productWarning) <= parseInt(product.productWarning) ? '#DC3545' : '#36B9CC'}`, verticalAlign: 'middle', textAlign: 'center' }}>{product.quantity} </td>
                                                            {user.role == 'employee' && <td className='' style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                                <NavLink to={`/products/edit/${product.product_id}/${product.employeeToken}`} className='btn btn-warning rounded-0 text-white fs-4'>
                                                                    <i className='bi bi-pencil-square '></i>
                                                                </NavLink>
                                                                <button
                                                                    className='btn btn-danger rounded-0 fs-4'
                                                                    onClick={() => handleDelete(product.cat_id, product.product_id, product.employeeToken)}
                                                                >
                                                                    <i className='bi bi-trash'></i>
                                                                </button>
                                                            </td>}
                                                        </tr> : <tr className='text-center'>
                                                            <td colSpan={8}>
                                                                No Product Warning till now
                                                            </td>
                                                        </tr>
                                                    }

                                                </>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">No Products Found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            <div className='d-flex justify-content-between align-items-center p-1' style={{ maxWidth: '' }}>
                                <div className='d-flex py-1' style={{ maxWidth: '50vw' }}>
                                    <button
                                        className={`btn border-0 rounded-0 mx-1 ${currentPage === 1 ? 'bg-purple-disabled  disabled' : 'bg-purple'}`}
                                        onClick={handlePreviousPage}
                                        disabled={currentPage === 1}
                                    >
                                        Previous
                                    </button>

                                    <div className='bg-white paginationNumbers d-flex py-1' style={{ maxWidth: '350px', overflowX: 'auto' }}>
                                        {Array.from({ length: totalPages }, (_, index) => (
                                            <button
                                                key={index}
                                                style={{ boxShadow: '2px 2px 2px 1px #c2bebebd', filter: 'drop-shadow(0px 0px 20px #c2bebebd)' }}
                                                className={`btn rounded-0 mx-1 ${currentPage === index + 1 ? 'text-primary btn-light' : 'btn-light'}`}
                                                onClick={() => handlePageChange(index + 1)}
                                            >
                                                {index + 1}
                                            </button>
                                        ))}

                                    </div>
                                    <button
                                        className={`btn border-0 rounded-0 mx-1 ${currentPage === totalPages ? 'bg-purple-disabled disabled' : 'bg-purple'}`}
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                    >
                                        Next
                                    </button>
                                </div>
                                <b>Current Page No. is : {currentPage} </b>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='bg-light container'>
                    <div style={{ fontSize: '25px' }}>You must be logged in to view. Please Login</div>
                </div>
            )}
        </div>
    );
}


export default WarningProducts