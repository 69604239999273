// src/hooks/useFetchAnalyticsData.js
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../App';
import axios from 'axios';

axios.defaults.withCredentials = true;

const useFetchAnalyticsData = () => {
    const [data, setData] = useState(null);
    const { user, role } = useContext(UserContext);
    const [annualEarningsData, setannualEarningsData] = useState([]);
    const [monthlyEarningsData, setmonthlyEarningsData] = useState([]);
    const [profitLossDataAvailable, setprofitLossDataAvailable] = useState({});
    const [salesThisMonthData, setsalesThisMonthData] = useState([]);
    const [salesThisYearData, setsalesThisYearData] = useState([]);
    const [salesTodayData, setsalesTodayData] = useState([]);
    const [topProductsData, settopProductsData] = useState([]);
    const [availableCategoriesData, setavailableCategoriesData] = useState(0);
    const [availableProductsData, setavailableProductsData] = useState(0);
    const [yearlyOverviewData, setyearlyOverviewData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [allProducts, setAllProducts] = useState()
    const [error, setError] = useState(null);
    // console.log(role)
    useEffect(() => {
        // Check if user is defined before fetching data
        // console.log(user)
        if (!role) {
            console.warn('User context is undefined, skipping analytics fetch.');
            setIsLoading(false); // Consider loading is done if user is not defined
            return; // Exit early
        }
        // Define the fetch function
        const fetchAnalyticsData = async () => {
            try {
                const response = await axios.get(`https://posnewversionbackend.basic2ai.info/api/analytics/${role}`, {
                    // const response = await axios.get(`http://localhost:8010/api/analytics/${user.role}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                // Check if the response is valid
                if (!response.data) {
                    throw new Error('No data received');
                }
                // Extract data and set it into state variables
                const {
                    annual_earnings,
                    monthly_earnings,
                    profit_loss_data,
                    sales_this_month,
                    sales_this_year,
                    sales_today,
                    top_products,
                    total_categories_available,
                    total_products_available,
                    yearly_overview,
                    allProducts
                } = response.data;
                // Set states
                console.log(annual_earnings,
                    monthly_earnings,
                    profit_loss_data,
                    sales_this_month,
                    sales_this_year,
                    sales_today,
                    top_products,
                    total_categories_available,
                    total_products_available,
                    yearly_overview,
                    allProducts)
                setannualEarningsData(annual_earnings);
                setmonthlyEarningsData(monthly_earnings);
                setprofitLossDataAvailable(profit_loss_data);
                setsalesThisMonthData(sales_this_month);
                setsalesThisYearData(sales_this_year);
                setsalesTodayData(sales_today);
                settopProductsData(top_products);
                setavailableCategoriesData(total_categories_available);
                setavailableProductsData(total_products_available);
                setyearlyOverviewData(yearly_overview);
                setAllProducts(allProducts)
                // Optionally set the data state if you still want it
                setData(response.data);
            } catch (error) {
                // Set the error state
                console.error('Error fetching analytics data:', error);
                setError(error.message || 'An error occurred while fetching analytics data');
            } finally {
                // Update loading state
                setIsLoading(false);
            }
        };
        // Call the fetch function
        fetchAnalyticsData();
    }, [user]); // Use user as dependency; if user changes, it will re-fetch data

    return { data, isLoading, error, annualEarningsData, monthlyEarningsData, profitLossDataAvailable, salesThisMonthData, salesThisYearData, salesTodayData, topProductsData, availableCategoriesData, availableProductsData, yearlyOverviewData, allProducts };
};

export default useFetchAnalyticsData;
