import React, { useContext } from 'react'
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    ArcElement,
    LineElement,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { UserContext } from '../../App';
ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    LineElement,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
);
const LineGraph = ({ labels, earningsData }) => {
    const { fontSizeChange } = useContext(UserContext)
    return (
        <div className='card rounded-0 border-0'>
            <div className='card-header'><span className='fw-bolder fs-3' style={{ fontSize: `${fontSizeChange ? '30px' : '20px'}` }}>Earnings Overview</span></div>
            <div className='card-body d-flex align-items-center justify-content-center' >
                <Line
                    height={228}
                    data={{
                        labels,
                        datasets: [{
                            label: 'Earnings (₹)',
                            data: earningsData,
                            borderColor: 'rgba(75,192,192,1)',
                            backgroundColor: 'rgba(75,192,192,1)',
                            borderWidth: 2,
                            fill: true,
                            tension: 0.3, // Set tension for smooth curve
                        }],
                    }}
                    options={{
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top',
                            },
                        },
                        scales: {
                            y: {
                                beginAtZero: true,
                            },
                        },
                    }}
                />
            </div>
            <div className='card-footer mb-0'>Complete Year Earning</div>
        </div>
    )
}

export default LineGraph