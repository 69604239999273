import React, { useContext, useEffect, useState } from 'react'
import Profile from '../Profile/Profile'
import axios from 'axios'
import './DashBoard.css'
import { UserContext } from '../../App'
import Login from '../../Authentication/Login'
axios.defaults.withCredentials = true
const Dashboard = () => {
    const [msg, setMsg] = useState()
    const [msg_type, setMsg_type] = useState()
    const [numberOfSuperAdmins, setNumberOfSuperAdmins] = useState(0)
    const [numberOfBusinessAdmins, setNumberOfBusinessAdmins] = useState(0)
    const [numberOfEmployees, setNumberOfEmployees] = useState(0)
    const [totalProducts, setTotalProducts] = useState(20)
    const [totalCategories, setTotalCategories] = useState(5)
    const [totalCustomers, setTotalCustomers] = useState(60)
    const { user, loggedIn } = useContext(UserContext)
    const [totalB2AAdmins, setTotalB2AAdmins] = useState([])
    const [totalBusiness, setTotalBusiness] = useState([])
    const [totalEmployess, setTotalEmployess] = useState([])
    const [totalSuperAdmins, setTotalSuperAdmins] = useState([])
    const [salesTotal, setSalesTotal] = useState([])
    const [btns, setBtns] = useState({
        btn1: false,
        btn2: false,
        btn3: true,
        btn4: false,
        btn5: false,
        btn6: false,
    })
    const getAdminsData = () => {
        // axios.get('http://localhost:8010/api/getAdmins')
        axios.get('https://posnewversionbackend.basic2ai.info/api/getAdmins')
            .then(res => {
                // Log the full response for debugging

                // Safely check if the data structure exists before accessing it
                if (res.data) {
                    const {
                        totalB2AAdmins = [],   // Default to an empty array if undefined
                        totalBusinessAdmins = [],
                        totalEmployees = [],
                        totalSuperAdmins = []
                    } = res.data;

                    // Update state with fetched data
                    setTotalB2AAdmins(totalB2AAdmins);

                    setTotalBusiness(totalBusinessAdmins);
                    setNumberOfBusinessAdmins(totalBusinessAdmins.length);

                    setTotalEmployess(totalEmployees);
                    setNumberOfEmployees(totalEmployees.length);

                    setTotalSuperAdmins(totalSuperAdmins);
                    setNumberOfSuperAdmins(totalSuperAdmins.length);
                }
            })
            .catch(err => {
                console.log('Error fetching admins data:', err);
            });
    }
    const [customers, setCustomers] = useState([])
    useEffect(() => {
        axios.get('https://posnewversionbackend.basic2ai.info/api/customers')
        // axios.get('http://localhost:8010/api/customers')
            .then(res => {
                setTotalCustomers(res.data.customers.length);
                setCustomers(res.data.customers);
            })
            .catch(err => {
                console.log(err);
            });
        // axios.get('http://localhost:8010/api/Products')
        axios.get('https://posnewversionbackend.basic2ai.info/api/Products')
            .then(res => {
                setTotalProducts(res.data.productsFound.length);
            })
            .catch(err => {
                console.log(err);
            });
        axios.get('https://posnewversionbackend.basic2ai.info/api/categories')
        // axios.get('http://localhost:8010/api/categories')
            .then(res => {
                setTotalCategories(res.data.categoriesFound.length);
            })
            .catch(err => {
                console.log(err);
            });
        axios.get('https://posnewversionbackend.basic2ai.info/api/Sales')
        // axios.get('http://localhost:8010/api/Sales')
            .then(res => {
                setSalesTotal(res.data.salesFound);
            })
            .catch(err => {
                console.log(err);
            });
        getAdminsData()

    }, [msg]);

    return (
        <>
            {
                loggedIn ? <div>
                    <div className='d-flex align-items-center justify-content-center'>
                        <div className={`msgAlert mt-3 ${msg && 'show'}`}>
                            {msg}
                        </div>
                    </div>
                    <div className='text-uppercase border p-3 mb-3' style={{ width: 'max-content', color: "white", fontWeight: '600', letterSpacing: '2px', backgroundColor: "rgb(55, 157, 224)" }}>DashBoard</div>
                    <div className='shadow'>
                        <Profile />
                    </div>
                    <div className='my-5 shadow border'>
                        <div className='shadow p-3'>
                            <div className='text-uppercase btn rounded-0 border p-3 mb-3' style={{ cursor: 'pointer', width: 'max-content', color: "white", fontWeight: '600', letterSpacing: '2px', backgroundColor: "rgb(55, 157, 224)" }} onClick={e => getAdminsData()}>Quick View <i className='bi bi-arrow-repeat'></i></div>
                            <div className='row'>
                                {(user.role === 'B2AAdmin' || user.role === 'superAdmin') && <div className='col-sm-6 col-md-6 col-lg-4 col-xl-2 p-3 cardsProperties'>
                                    <div className='card onHover' onClick={e => setBtns({ ...btns, btn1: true, btn2: false, btn3: false, btn4: false, btn5: false, btn6: false })}>
                                        <div className=''>
                                            Super Admins : {user.role == 'B2AAdmin' ? numberOfSuperAdmins : 1}
                                        </div>
                                    </div>
                                </div>}
                                {(user.role === "B2AAdmin" || user.role === 'superAdmin' || user.role === 'bussinessAdmin') && <div className='col-sm-6 col-md-6 col-lg-4 col-xl-2 p-3 cardsProperties'>
                                    <div className='card onHover' onClick={e => setBtns({ ...btns, btn1: false, btn2: true, btn3: false, btn4: false, btn5: false, btn6: false })}>
                                        <div className=''>
                                            Business Admins : {numberOfBusinessAdmins}
                                        </div>
                                    </div>
                                </div>}
                                <div className='col-sm-6 col-md-6 col-lg-4 col-xl-2 p-3 cardsProperties'>
                                    <div className='card onHover' onClick={e => setBtns({ ...btns, btn1: false, btn2: false, btn3: true, btn4: false, btn5: false, btn6: false })}>
                                        <div className=''>
                                            Employees : {numberOfEmployees}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-6 col-lg-4 col-xl-2 p-3 cardsProperties'>
                                    <div className='card onHover'>
                                        <div className=''>
                                            Customers : {totalCustomers}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-6 col-lg-4 col-xl-2 p-3 cardsProperties'>
                                    <div className='card onHover'>
                                        <div className=''>
                                            Categories : {totalCategories}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-6 col-lg-4 col-xl-2 p-3 cardsProperties' >
                                    <div className='card onHover'>
                                        <div className=''>
                                            Products : {totalProducts}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-6 col-lg-4 col-xl-2 p-3 cardsProperties'>
                                    <div className='card onHover'>
                                        <div className=''>
                                            Sales : {salesTotal.length || 0}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='my-5 shadow border'>
                        <div className='shadow p-3'>
                            {(btns.btn1 && user.role != 'employee') &&
                                <div>
                                    <div className='text-uppercase border p-3 mb-3' style={{ width: 'max-content', color: "white", fontWeight: '600', letterSpacing: '2px', backgroundColor: "rgb(55, 157, 224)" }}>Super Admins</div>
                                    <div className='table-responsive'>
                                        <table className='table table-bordered table-hover table-striped'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th style={{ minWidth: '100px' }}>Profile Pic</th>
                                                    <th style={{ minWidth: '150px' }}>Name</th>
                                                    <th style={{ minWidth: '150px' }}>Username</th>
                                                    <th style={{ minWidth: '150px' }}>Role</th>
                                                    <th style={{ minWidth: '150px' }}>Email</th>
                                                    <th style={{ minWidth: '150px' }}>Phone</th>
                                                    <th style={{ minWidth: '150px' }}>State</th>
                                                    <th style={{ minWidth: '150px' }}>Shop Name</th>
                                                    <th style={{ minWidth: '150px' }}>GST Number</th>
                                                    <th style={{ minWidth: '150px' }}>Created By</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {totalSuperAdmins.length > 0 ? totalSuperAdmins.map((data, idx) => (
                                                    <tr className='' key={idx}>
                                                        <td>{parseInt(idx) + 1}</td>
                                                        <td><center><img className='rounded-circle border bg-black' style={{ width: '30px', height: '30px' }} src={`https://posnewversionbackend.basic2ai.info/profilePic/${data.profilePic}`} /></center></td>
                                                        {/* <td><center><img className='rounded-circle border bg-black' style={{ width: '30px', height: '30px' }} src={`http://localhost:8010/public/profilePic/${data.profilePic}`} /></center></td> */}
                                                        <td>{data.name}</td>
                                                        <td>{data.username}</td>
                                                        <td>{data.role}</td>
                                                        <td>{data.email}</td>
                                                        <td>{data.phone}</td>
                                                        <td>{data.estate}</td>
                                                        <td>{data.shopName}</td>
                                                        <td>{data.gstNumber}</td>
                                                        <td>{data.tokenB2AAdmin.split('@')[0]}</td>
                                                    </tr>
                                                )) : <tr className='text-center'>
                                                    <td colSpan={5}>No Data Avaialble</td>
                                                    <td colSpan={6}>No Data Avaialble</td>
                                                </tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                            {btns.btn2 &&
                                <div>
                                    <div className='text-uppercase border p-3 mb-3' style={{ width: 'max-content', color: "white", fontWeight: '600', letterSpacing: '2px', backgroundColor: "rgb(55, 157, 224)" }}>Business Admins</div>
                                    <div className='table-responsive'>
                                        <table className='table table-bordered table-hover table-striped'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th style={{ minWidth: '100px' }}>Profile Pic</th>
                                                    <th style={{ minWidth: '150px' }}>Name</th>
                                                    <th style={{ minWidth: '150px' }}>Username</th>
                                                    <th style={{ minWidth: '150px' }}>Role</th>
                                                    <th style={{ minWidth: '150px' }}>Email</th>
                                                    <th style={{ minWidth: '150px' }}>Phone</th>
                                                    <th style={{ minWidth: '150px' }}>State</th>
                                                    <th style={{ minWidth: '150px' }}>Shop Name</th>
                                                    <th style={{ minWidth: '150px' }}>GST Number</th>
                                                    <th style={{ minWidth: '150px' }}>By Super Admin</th>
                                                    <th style={{ minWidth: '150px' }}>By B2A Admin</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {totalBusiness.length > 0 ? totalBusiness.map((data, idx) => (
                                                    <tr className='' key={idx}>
                                                        <td>{parseInt(idx) + 1}</td>
                                                        <td><center><img className='rounded-circle border bg-black' style={{ width: '30px', height: '30px' }} src={`https://posnewversionbackend.basic2ai.info/profilePic/${data.profilePic}`} /></center></td>
                                                        {/* <td><center><img className='rounded-circle border bg-black' style={{ width: '30px', height: '30px' }} src={`http://localhost:8010/public/profilePic/${data.profilePic}`} /></center></td> */}
                                                        <td>{data.name}</td>
                                                        <td>{data.username}</td>
                                                        <td>{data.role}</td>
                                                        <td>{data.email}</td>
                                                        <td>{data.phone}</td>
                                                        <td>{data.estate}</td>
                                                        <td>{data.shopName}</td>
                                                        <td>{data.gstNumber}</td>
                                                        <td>{data.tokenSuperAdmin.split('@')[0]}</td>
                                                        <td>{data.tokenB2AAdmin.split('@')[0]}</td>
                                                    </tr>
                                                )) : <tr className='text-center'>
                                                    <td colSpan={6}>No Data Avaialble</td>
                                                    <td colSpan={6}>No Data Avaialble</td>
                                                </tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                            {btns.btn3 &&
                                <div>
                                    <div className='text-uppercase border p-3 mb-3' style={{ width: 'max-content', color: "white", fontWeight: '600', letterSpacing: '2px', backgroundColor: "rgb(55, 157, 224)" }}>Employees</div>
                                    <div className='table-responsive'>
                                        <table className='table table-bordered table-hover table-striped'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th style={{ minWidth: '100px' }}>Profile Pic</th>
                                                    <th style={{ minWidth: '150px' }}>Name</th>
                                                    <th style={{ minWidth: '150px' }}>Username</th>
                                                    <th style={{ minWidth: '150px' }}>Role</th>
                                                    <th style={{ minWidth: '150px' }}>Email</th>
                                                    <th style={{ minWidth: '150px' }}>Phone</th>
                                                    <th style={{ minWidth: '150px' }}>State</th>
                                                    <th style={{ minWidth: '150px' }}>Shop Name</th>
                                                    <th style={{ minWidth: '150px' }}>GST Number</th>
                                                    <th style={{ minWidth: '150px' }}>Employee Code</th>
                                                    {(user.role == 'B2AAdmin' && user.role == 'superAdmin' && user.role == 'bussinessAdmin') && <th style={{ minWidth: '150px' }}>By Business Admin</th>}
                                                    {(user.role == 'B2AAdmin' && user.role == 'superAdmin') && <th style={{ minWidth: '150px' }}>By Super Admin</th>}
                                                    {user.role == 'B2AAdmin' && <th style={{ minWidth: '150px' }}>By B2A Admin</th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {totalEmployess.length > 0 ? totalEmployess.map((data, idx) => (
                                                    <tr className='' key={idx}>
                                                        <td>{parseInt(idx) + 1}</td>
                                                        <td><center><img className='rounded-circle border bg-black' style={{ width: '30px', height: '30px' }} src={`https://posnewversionbackend.basic2ai.info/profilePic/${data.profilePic}`} /></center></td>
                                                        {/* <td><center><img className='rounded-circle border bg-black' style={{ width: '30px', height: '30px' }} src={`http://localhost:8010/public/profilePic/${data.profilePic}`} /></center></td> */}
                                                        <td>{data.name}</td>
                                                        <td>{data.username}</td>
                                                        <td>{data.role}</td>
                                                        <td>{data.email}</td>
                                                        <td>{data.phone}</td>
                                                        <td>{data.estate}</td>
                                                        <td>{data.shopName}</td>
                                                        <td>{data.gstNumber}</td>
                                                        <td>{data.tokenEmployee.split('@')[0]}</td>
                                                        {(user.role == 'B2AAdmin' && user.role == 'superAdmin' && user.role == 'bussinessAdmin') && <td>{data.tokenBusinessAdmin.split('@')[0]}</td>}
                                                        {(user.role == 'B2AAdmin' && user.role == 'superAdmin') &&<td>{data.tokenSuperAdmin.split('@')[0]}</td>}
                                                        {user.role == 'B2AAdmin' && <td>{data.tokenB2AAdmin.split('@')[0]}</td>}
                                                    </tr>
                                                )) : <tr className='text-center'>
                                                    <td colSpan={7}>No Data Avaialble</td>
                                                    <td colSpan={6}>No Data Avaialble</td>
                                                </tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                            {
                                btns.btn4 && <div>Total Customers: {totalCustomers}</div>
                            }
                            {
                                btns.btn5 && <div>Total Categories: {totalCategories}</div>
                            }
                            {
                                btns.btn6 && <div>Total Products: {totalProducts}</div>
                            }
                            {/* {btns.btn4 &&
                                <div>
                                    <div className='text-uppercase border p-3 mb-3' style={{ width: 'max-content', color: "white", fontWeight: '600', letterSpacing: '2px', backgroundColor: "rgb(55, 157, 224)" }}>Customers List</div>
                                    <div className='table-responsive'>
                                        <table className='table table-bordered table-hover table-striped'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th style={{ minWidth: '100px' }}>Name</th>
                                                    <th style={{ minWidth: '150px' }}>Email</th>
                                                    <th style={{ minWidth: '150px' }}>Phone</th>
                                                    <th style={{ minWidth: '150px' }}>Village</th>
                                                    <th style={{ minWidth: '150px' }}>City</th>
                                                    <th style={{ minWidth: '150px' }}>District</th>
                                                    <th style={{ minWidth: '150px' }}>State</th>
                                                    <th style={{ minWidth: '150px' }}>Zip Code</th>
                                                    {(user.role === 'superAdmin' || user.role === 'B2AAdmin' || user.role === 'bussinessAdmin' || user.role === 'employee') && <th className="text-center" style={{ minWidth: '150px' }}>By</th>}
                                                    {(user.role === 'B2AAdmin' || user.role === 'bussinessAdmin' || user.role === 'superAdmin') && <th className="text-center" style={{ minWidth: '150px' }}>Admin 1</th>}
                                                    {(user.role === 'superAdmin' || user.role === 'B2AAdmin') && <th className="text-center" style={{ minWidth: '150px' }}>Admin 2</th>}
                                                    {user.role === 'B2AAdmin' && <th className="text-center" style={{ minWidth: '150px' }}>Admin 3</th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {totalCustomers > 0 ? customers.map((data, idx) => (
                                                    <tr className='' key={idx}>
                                                        <td>{parseInt(idx) + 1}</td>
                                                        <td>{data.cust_name}</td>
                                                        <td>{data.cust_email}</td>
                                                        <td>{data.cust_phone}</td>
                                                        <td>{data.village}</td>
                                                        <td>{data.city}</td>
                                                        <td>{data.district}</td>
                                                        <td>{data.estate}</td>
                                                        <td>{data.zipCode}</td>
                                                        {(user.role === 'superAdmin' || user.role === 'B2AAdmin' || user.role === 'bussinessAdmin' || user.role === 'employee') && <td className="text-center" style={{ minWidth: '150px' }}>{data.employeeToken.split('@')[0]}</td>}
                                                        {(user.role === 'B2AAdmin' || user.role === 'bussinessAdmin' || user.role === 'superAdmin') && <td className="text-center" style={{ minWidth: '150px' }}>{data.bussinessToken}</td>}
                                                        {(user.role === 'superAdmin' || user.role === 'B2AAdmin') && <td className="text-center" style={{ minWidth: '150px' }}>{data.superAdminToken}</td>}
                                                        {user.role === 'B2AAdmin' && <td className="text-center" style={{ minWidth: '150px' }}>{data.B2AAdminToken}</td>}
                                                    </tr>
                                                )) : <tr className='text-center'>
                                                    <td colSpan={7}>No Data Avaialble</td>
                                                    <td colSpan={6}>No Data Avaialble</td>
                                                </tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            } */}
                        </div>
                    </div>
                </div> : <Login />
            }

        </>
    )
}

export default Dashboard