// SidebarNavbar.js
import React, { useContext, useState, useRef, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SidebarNavbar.css'; // Make sure you have this CSS file
import { UserContext } from '../App';
import Sales from '../Pages/Sales/Sales';
import Dashboard from '../Pages/DashBoard/Dashboard';
import Categories from '../Pages/Categories/Categories';
import Products from '../Pages/Products/Products';
import Customers from '../Pages/Customers/Customers';
import OurBills from '../Pages/OurBills/OurBills';
import Analytics from '../Pages/Analytics/Analytics';
import axios from 'axios';
import Login from '../Authentication/Login';
import Registration from '../Authentication/Registration';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
axios.defaults.withCredentials = true

const Navbar = () => {
    const [msg, setMsg] = useState(null)
    const [msg_type, setMsg_type] = useState(null)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const sidebarRef = useRef(null); // Ref for the sidebar
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const handleLinkClick = (link) => {
        setActiveLink(link);
    };

    const { handleToggleView, activeLink, setActiveLink, user, signUp, sidebarOpen, setSidebarOpen, setSignUp, loggedIn, setLoggedIn, fontSizeChange, setFontSizeChange } = useContext(UserContext);
    // console.log(user)
    const handleToggle = () => {
        setActiveLink('SignUp');
        setSignUp(!signUp);
    };
    const handleToggleViewHere = () => {
        setFontSizeChange(!fontSizeChange)
        handleToggleView()
    }
    // console.log(activeLink)
    // Close sidebar when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setSidebarOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [sidebarRef]);
    const handleLogOut = () => {
        Swal.fire({
            title: 'Attention',
            text: 'Do you want to Logout this session ? ',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'No',
            showCancelButton: true,
            icon: 'warning'
        }).then(res => {
            if (res.isDismissed) {
                return
            }
            else {
                axios.get('https://posnewversionbackend.basic2ai.info/api/logout')
                    // axios.get('http://localhost:8010/api/logout')
                    .then(res => {
                        // console.log(res)
                        setMsg("Logout Successful")
                        const timeout = setTimeout(() => {
                            setMsg(null);
                            setMsg_type(null);
                            window.location.href = '/'
                        }, 2500);

                        // Optional: clear the timeout when component unmounts or when not needed anymore
                        return () => clearTimeout(timeout);
                    })
            }
        }).catch(err => {
            Swal.fire({
                title: 'Error',
                text: 'Unable to Logout Right Now try agin after sometimes.',
                confirmButtonText: 'Okay',
                icon: 'error'
            })
        })

    }

    const [dropdownOpen1, setDropdownOpen1] = useState(false);
    const dropdownRef = useRef(null); // Ref to handle outside clicks

    // Toggle dropdown visibility
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const toggleDropdown1 = () => {
        setDropdownOpen1(!dropdownOpen);
    };

    // Handle outside click to close dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen1(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <>
            {
                loggedIn &&
                <div>
                    {/* Navbar */}
                    <div className='d-flex align-items-center justify-content-center'>
                        <div className={`msgAlert ${msg && 'show'}`}>
                            {msg}
                        </div>
                    </div>
                    <nav className="navbar fixed-top navbar-expand-lg shadow py-0 " style={{ background: '#092a4b', minWidth: '420px', width: '100vw' }}>
                        <div className="container-fluid">
                            <NavLink className="navbar-brand fw-bold text-white" to="/" style={{ fontSize: `${fontSizeChange ? '25px' : '18px'}` }}>
                                {user.shopName || "Default Shop"}
                            </NavLink>
                            <button className="navbar-toggler text-white" type="button" onClick={toggleSidebar}>
                                <span className="navbar-toggler-icon " style={{ filter: 'invert(1)' }}></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav ms-auto">
                                    <li className="nav-item d-flex align-items-center">
                                        <NavLink
                                            className={`nav-link d-flex align-items-center ${activeLink === 'sales' ? 'active' : ''}`}
                                            onClick={() => handleLinkClick('sales')}
                                            to="/sales"
                                            data-bs-toggle="tooltip"
                                            title="Sales"
                                        >
                                            <i className='bi me-1 bi-cart-check-fill'></i>
                                            <span className='hideBelow1000px'>Sales</span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item d-flex align-items-center">
                                        <NavLink
                                            className={`nav-link d-flex align-items-center ${activeLink === 'dashboard' ? 'active' : ''}`}
                                            onClick={() => handleLinkClick('dashboard')}
                                            to="/dashboard"
                                            data-bs-toggle="tooltip"
                                            title="Dashboard"
                                        >
                                            <i className='bi me-1 bi-speedometer2'></i>
                                            <span className='hideBelow1000px'>Dashboard</span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item d-flex align-items-center dropdown" onMouseEnter={() => setDropdownOpen(true)} onMouseLeave={() => setDropdownOpen(false)}>
                                        <NavLink
                                            className={`nav-link d-flex align-items-center dropdown-toggle ${activeLink === 'categories' || activeLink === 'products' ? 'active' : ''}`}
                                            id="navbarDropdown"
                                            to='/category'
                                            role="button"
                                            aria-expanded={dropdownOpen}
                                            data-bs-toggle="tooltip"
                                            title="Items"
                                        >
                                            <i className='bi me-1 bi-tag-fill'></i>
                                            <span className='hideBelow1000px'>Items</span>
                                        </NavLink>
                                        <ul className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} style={{ marginTop: '100px' }} aria-labelledby="navbarDropdown">
                                            <li>
                                                <NavLink
                                                    className={`dropdown-item ${activeLink === 'categories' ? 'active' : ''}`}
                                                    to="/categories"
                                                    onClick={() => handleLinkClick('categories')}
                                                    data-bs-toggle="tooltip"
                                                    title="Categories"
                                                >
                                                    Categories
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    className={`dropdown-item ${activeLink === 'products' ? 'active' : ''}`}
                                                    to="/products"
                                                    onClick={() => handleLinkClick('products')}
                                                    data-bs-toggle="tooltip"
                                                    title="Products"
                                                >
                                                    Products
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nav-item d-flex align-items-center">
                                        <NavLink
                                            className={`nav-link d-flex align-items-center ${activeLink === 'customer' ? 'active' : ''}`}
                                            onClick={() => handleLinkClick('customer')}
                                            to="/customer"
                                            data-bs-toggle="tooltip"
                                            title="Customers"
                                        >
                                            <i className='bi me-1 bi-person-circle'></i>
                                            <span className='hideBelow1000px'>Customers</span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item d-flex align-items-center">
                                        <NavLink
                                            className={`nav-link d-flex align-items-center ${activeLink === 'ourBills' ? 'active' : ''}`}
                                            onClick={() => handleLinkClick('ourBills')}
                                            to="/ourbills"
                                            data-bs-toggle="tooltip"
                                            title="Our Bills"
                                        >
                                            <i className='bi me-1 bi-card-checklist'></i>
                                            <span className='hideBelow1000px'>Our Bills</span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item d-flex align-items-center">
                                        <NavLink
                                            className={`nav-link d-flex align-items-center ${activeLink === 'analytics' ? 'active' : ''}`}
                                            onClick={() => handleLinkClick('analytics')}
                                            to="/analytics"
                                            data-bs-toggle="tooltip"
                                            title="Analytics"
                                        >
                                            <i className='bi me-1 bi-graph-up-arrow'></i>
                                            <span className='hideBelow1000px'>Analytics</span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item d-flex align-items-center">
                                        <NavLink
                                            className={`nav-link d-flex align-items-center`}
                                            to="https://posfrontend.basic2ai.info/"
                                            target='__blank'
                                            data-bs-toggle="tooltip"
                                            title="Lead Management"
                                        >
                                            <img src="https://pos.basic2ai.info/static/img/lead.svg" width="25" height="25" alt="" class="img-small me-1" />
                                            <span className='hideBelow1000px'>Lead Management</span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item d-flex align-items-center">
                                        <div
                                            className={`nav-link d-flex align-items-center ${signUp ? 'active' : ''}`}
                                            onClick={handleToggle}
                                            data-bs-toggle="tooltip"
                                            title="Add User"
                                        >
                                            {signUp ? <i className='bi me-1 bi-x-lg me-1'></i> : <i className='bi me-1 bi-person-add me-1'></i>}
                                            <span className='hideBelow1000px'>{signUp ? 'Tap Anywhere' : 'Add User'}</span>
                                        </div>
                                    </li>
                                    <li className="nav-item d-flex align-items-center">
                                        <div
                                            className={`nav-link d-flex align-items-center`}
                                            onClick={handleToggleViewHere}
                                            data-bs-toggle="tooltip"
                                            title="Change View"
                                        >
                                            {fontSizeChange ? <i className='bi me-1 bi-tablet'></i> : <i className='bi me-1 bi-laptop'></i>}
                                            <span className='hideBelow1000px'>Change View</span>
                                        </div>
                                    </li>
                                    <li className="nav-item d-flex align-items-center">
                                        <NavLink
                                            className={`nav-link d-flex align-items-center`}
                                            onClick={() => { handleLogOut(); setDropdownOpen1(false); }}
                                            data-bs-toggle="tooltip"
                                            title="Logout"
                                        >
                                            <i className="bi bi-box-arrow-in-right me-1"></i>
                                            <span className='hideBelow1000px'>Logout</span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item position-relative" ref={dropdownRef} style={{ display: 'flex', alignItems: 'center' }}>
                                        <NavLink
                                            className="ms-2 me-3"
                                            onClick={toggleDropdown1}
                                            data-bs-toggle="tooltip"
                                            title="Profile"
                                        >
                                            {user.profilePic ? (
                                                <img
                                                    className='rounded-circle border border-secondary'
                                                    src={`https://posnewversionbackend.basic2ai.info/profilePic/${user.profilePic}`}
                                                    // src={`http://localhost:8010/public/profilePic/${user.profilePic}`}
                                                    alt="Profile"
                                                    style={{ width: '40px', height: '40px', borderRadius: '50%', objectFit: 'cover' }}
                                                />
                                            ) : (
                                                <i className="bi bi-person-circle" style={{ fontSize: '40px' }}></i>
                                            )}
                                        </NavLink>

                                        {dropdownOpen1 && (
                                            <ul ref={dropdownRef} className="dropdown-menu show position-absolute shadow-lg" style={{ top: '90%', left: '50%', transform: 'translateX(-80%)', minWidth: '100px' }} >
                                                <li>
                                                    <NavLink className="dropdown-item" onClick={() => { handleLogOut(); setDropdownOpen1(false); }} >
                                                        <i className="bi bi-box-arrow-in-right me-1"></i> Logout
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        )}
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </nav>

                    {/* Sidebar */}
                    <div id="sidebar"
                        // ref={sidebarRef} 
                        className={sidebarOpen ? 'show' : ''}>
                        <div className='text-white mt-5 fw-bolder d-flex align-items-center justify-content-center' style={{ minHeight: '90px', verticalAlign: "middle", fontSize: `${fontSizeChange ? '35px' : '25px'}` }}>
                            <center>{user.shopName || "Default Shop"}</center>
                        </div>
                        <div className='p-2'>
                            <div className=' px-2 pt-2 rounded shadow bg-light border' >
                                <ul className="nav flex-column">
                                    <li className="nav-item">
                                        <NavLink className={`sidebar-link ${activeLink === 'sales' ? 'active' : ''}`} to="/sales" onClick={() => handleLinkClick('sales')}
                                        >
                                            <i className='bi me-1 bi-cart-check-fill'></i> Sales
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className={`sidebar-link ${activeLink === 'dashboard' ? 'active' : ''}`} to="/dashboard" onClick={() => handleLinkClick('dashboard')}>
                                            <i className='bi me-1 bi-speedometer2'></i> Dashboard
                                        </NavLink>
                                    </li>
                                    <li className="nav-item dropdown" onMouseEnter={() => setDropdownOpen(true)} onMouseLeave={() => setDropdownOpen(false)}>
                                        <div className={`sidebar-link dropdown-toggle ${activeLink === 'products' ? 'active' : ''} ${activeLink === 'categories' ? 'active' : ''}`} id="navbarDropdown" role="button" aria-expanded={dropdownOpen} >
                                            <i className='bi me-1 bi-tag-fill'></i> Items {activeLink === 'products' || activeLink === 'categories' ? <i className='bi me-1 bi-chevron-right'>{activeLink[0].toUpperCase()}{activeLink.slice(1)}..</i> : ''}
                                        </div>
                                        <ul className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} aria-labelledby="navbarDropdown">
                                            <li>
                                                <NavLink className={`dropdown-item ${activeLink === 'categories' ? 'active' : ''}`} to="/categories" onClick={() => handleLinkClick('categories')} >
                                                    <i className='bi me-1'></i> Categories
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink className={`dropdown-item ${activeLink === 'products' ? 'active' : ''}`} to="/products" onClick={() => handleLinkClick('products')} >
                                                    Products
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink className={`sidebar-link ${activeLink === 'customer' ? 'active' : ''}`} to="/customer" onClick={() => handleLinkClick('customer')} >
                                            <i className='bi me-1 bi-person-circle'></i> Customers
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className={`sidebar-link ${activeLink === 'ourBills' ? 'active' : ''}`} to="/ourbills" onClick={() => handleLinkClick('ourBills')} >
                                            <i className='bi me-1 bi-card-checklist'></i> Our Bills
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className={`sidebar-link ${activeLink === 'analytics' ? 'active' : ''}`} to="/analytics" onClick={() => handleLinkClick('analytics')} >
                                            <i className='bi me-1 bi-graph-up-arrow'></i> Analytics
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className={`sidebar-link`} to="https://posfrontend.basic2ai.info/" target='__blank' onClick={() => handleLinkClick('leadManagement')}>
                                            <img src="https://pos.basic2ai.info/static/img/lead.svg " width="25" height="25" alt="" title="" class="img-small me-1"></img>
                                            Lead Management
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='p-2'>
                            <div className=' px-2 rounded shadow bg-light border' >
                                <ul className='nav flex-column lowerPart' style={{ background: '' }}>
                                    <li className="nav-item position-relative mb-1 py-1" ref={dropdownRef} style={{ display: 'flex', alignItems: 'center' }}>
                                        <NavLink className="text-decoration-none" onClick={toggleDropdown1} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                            {user.profilePic ? (
                                                <div>
                                                    {user.profilePic ?
                                                        <img className='rounded-circle border border-black' src={`https://posnewversionbackend.basic2ai.info/profilePic/${user.profilePic}`} alt="Profile" style={{ width: '40px', height: '40px', borderRadius: '50%', objectFit: 'cover' }} /> : <i className='bi me-1 bi-person-circle' style={{ width: '40px', height: '40px' }}></i>} <b className='text-decoration-none ms-1'>Logout</b>
                                                    {/* <img className='rounded-circle border border-black' src={`http://localhost:8010/public/profilePic/${user.profilePic}`} alt="Profile" style={{ width: '40px', height: '40px', borderRadius: '50%', objectFit: 'cover' }} /> : <i className='bi me-1 bi-person-circle' style={{ width: '40px', height: '40px' }}></i>} <b className='text-decoration-none ms-1'>Logout</b> */}
                                                </div>
                                            ) : (
                                                <i className="bi bi-person-circle" style={{ fontSize: '40px' }}></i>
                                            )}
                                        </NavLink>

                                        {dropdownOpen1 && (
                                            <ul ref={dropdownRef} className="dropdown-menu show position-absolute shadow-lg" style={{ top: '90%', left: '50%', transform: 'translateX(-80%)', minWidth: '100px' }} >
                                                <li> <NavLink className="dropdown-item" onClick={() => { handleLogOut(); setDropdownOpen1(false); }} ><i className="bi bi-box-arrow-in-right me-1"></i> Logout</NavLink></li>
                                            </ul>
                                        )}
                                    </li>
                                    <li className="nav-item">
                                        <div className={`sidebar-link ${activeLink === 'SignUp' ? 'active' : 'text-black'}`} onClick={e => handleToggle()} >
                                            {signUp ? <i className='bi me-1 bi-x-lg me-1'></i> : <i className='bi me-1 bi-person-add me-1'></i>}
                                            {signUp ? 'Tap Anywhere' : 'Add User'}
                                        </div>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to='/dashboard' className={`sidebar-link ${activeLink === 'dashboard' ? 'active' : 'text-black'} ${activeLink === 'profile' ? 'active' : 'text-black'}`} onClick={() => handleLinkClick('profile')} >
                                            <i className='bi me-1 bi-person-check-fill me-1'></i> Profile
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <div className={`sidebar-link text-black `} style={{ background: '' }} onClick={() => handleToggleViewHere()} >
                                            {fontSizeChange ? <i className='bi me-1 bi-tablet'></i> : <i className='bi me-1 bi-laptop'></i>} Change View
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='d-flex  align-items-center justify-content-center'>
                            <div className='d-flex align-items-center rounded-circle justify-content-center text-white' onClick={e => setSidebarOpen(false)} style={{ background: '#829eed', width: '50px', height: '50px' }}>
                                <i className='bi me-1 bi-chevron-left' style={{ fontSize: '35px' }}></i>
                            </div>
                        </div>
                    </div>
                    {/* Main Content */}
                    <div className={`w-100 registerPageBase${signUp ? 'Open' : 'Close'}`}>
                        {signUp && <Registration signUp={signUp} setSignUp={setSignUp} />}
                    </div>
                </div>

            }
        </>
    );
};

export default Navbar;
