import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../App';

axios.defaults.withCredentials = true

const EditCategory = () => {
    const [msg, setMsg] = useState(null);
    const [msg_type, setMsg_type] = useState(null);
    const { cat_id, cat_name, cat_desc, employeeToken } = useParams();
    const [name, setName] = useState(cat_name); // Initialize with params if provided
    const [desc, setDesc] = useState(cat_desc);
    const [customerData, setCustomerData] = useState([]);
    const navigate = useNavigate();

    const { user, fontSizeChange, handleCloseForm, isFormOpen } = useContext(UserContext);

    const msgRef = useRef(null);
    const formRef = useRef(null); // useRef to track the form element

    // Use customerData to set initial form values if needed
    useEffect(() => {
        axios.post('https://posnewversionbackend.basic2ai.info/api/update-category', { cat_id, cat_name, cat_desc, employeeToken })
            .then(res => {
                if (res.status === 404) {
                    setMsg(res.data.msg);
                    setMsg_type(res.data.msg_type);
                } else {
                    setCustomerData(res.data.customer);
                }
            })
            .catch(err => {
                console.error(err);
            });
    }, [cat_id, cat_name, cat_desc, employeeToken]);

    // Closing the message modal when clicked outside
    useEffect(() => {
        const handleClickOutsideMsg = (event) => {
            if (msgRef.current && !msgRef.current.contains(event.target)) {
                setMsg(null); // Close message box when clicked outside
            }
        };

        document.addEventListener('mousedown', handleClickOutsideMsg);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideMsg);
        };
    }, [msg]);

    // Closing the form when clicked outside
    useEffect(() => {
        const handleClickOutsideForm = (event) => {
            if (formRef.current && !formRef.current.contains(event.target)) {
                // Close form by calling a function passed via context, or set some state to hide form
                handleCloseForm && handleCloseForm(); // Check if handleCloseForm is passed
            }
        };

        document.addEventListener('mousedown', handleClickOutsideForm);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideForm);
        };
    }, [handleCloseForm]);

    // Submit handler to update category
    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('https://posnewversionbackend.basic2ai.info/api/update/category', { cat_id, cat_name: name || cat_name, cat_desc: desc || cat_desc, employeeToken })
        // axios.post('http://localhost:8010/api/update/category', { cat_id, cat_name: name || cat_name, cat_desc: desc || cat_desc, employeeToken })
            .then(res => {
                if (res.status === 200) {
                    setMsg(res.data.msg);
                    setMsg_type(res.data.msg_type);
                    setTimeout(() => {
                        navigate('/categories');
                    }, 2500); // Delay before redirecting
                } else {
                    setMsg(res.data.msg);
                    setMsg_type(res.data.msg_type);
                }
            })
            .catch(err => {
                setMsg('Error updating category.');
                setMsg_type('error');
            });
    };

    return (
        <div>
            {msg && (
                <div className='d-flex align-items-center justify-content-center min-vh-100' style={{ background: '#5656569f', position: 'absolute', width: '100vw', zIndex: '1050', left: '0px', top: "0px" }}>
                    <div
                        ref={msgRef}
                        className={`msgAlert p-5 rounded-3 show border border-2 ${msg ? 'show' : 'notShow'} ${msg_type === 'good' ? 'text-success' : 'text-danger'}`}
                        style={{ top: '35%', fontSize: '20px', height: '250px', width: '370px', background: 'white' }}
                    >
                        <div className=''>
                            <center>{msg_type === 'error' ? <i className='bi bi-x-circle' style={{ fontSize: '45px' }}></i> : <i className='bi bi-check-circle' style={{ fontSize: '45px' }}></i>}</center>
                            <div className='text-center'>{msg}</div>
                            <hr />
                            <center>
                                <div className={`btn ${msg_type === 'error' ? 'btn-danger' : 'btn-success'} rounded-0`} onClick={() => setMsg(null)}>
                                    Okay
                                </div>
                            </center>
                        </div>
                    </div>
                </div>
            )}
            {<div className="">
                <div className="d-flex justify-content-center" style={{ marginTop: '' }}>
                    <div className={`container border bg-white`} style={{ minHeight: '700px' }} ref={formRef}>
                        <div className="btn rounded-0 my-3 py-2 ps-0 mainPageName" style={{ fontWeight: '600', fontSize: `${fontSizeChange ? '25px' : '18px'}` }}>
                            Update Category
                        </div>
                        <br />
                        <NavLink to='/categories'>
                            <div className="btn rounded-0 mb-3 text-white py-2 px-4 mainPageBack" style={{ fontSize: `${fontSizeChange ? '20px' : '16px'}` }} >
                                <i className="bi bi-arrow-left me-2"></i> Back
                            </div>
                        </NavLink>
                        <form className="p-3 bg-light" onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-6 mb-3">
                                    <div className="input-group">
                                        <label>Name</label>
                                        <input type="text" className="form-control w-100" value={name} onChange={e => setName(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-6 mb-3">
                                    <div className="input-group">
                                        <label>Category ID</label>
                                        <input type="text" className="form-control w-100" value={cat_id} disabled />
                                    </div>
                                </div>
                                <div className="col-12 mb-3">
                                    <div className="input-group">
                                        <label>Description</label>
                                        <textarea type="text" rows={15} className="form-control w-100" value={desc} onChange={e => setDesc(e.target.value)} style={{ resize: 'none' }} />
                                    </div>
                                </div>

                                <div className="input-group mb-3">
                                    <label>Added by</label>
                                    <input type="text" className="form-control w-100" value={user?.username || 'Unknown User'} disabled />
                                </div>
                            </div>
                            <button type='submit' className="btn rounded-0 text-white" style={{ background: '#1CC88A' }}>Update Category</button>
                        </form>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default EditCategory;
