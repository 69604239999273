import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useParams, useSearchParams } from 'react-router-dom'
import { UserContext } from '../../App'
import axios from 'axios'
import Swal from 'sweetalert2'

const CustomerDetail = () => {
    const { cust_name, cust_id, zipCode, role } = useParams()
    const { user, fontSizeChange } = useContext(UserContext)
    const [customer, setCustomer] = useState([])
    const [sales, setSales] = useState([])
    const [totalBills, setTotalBills] = useState(0)
    useEffect(() => {
        if (user) {
            axios.get(`https://posnewversionbackend.basic2ai.info/api/get/customer/detail/${cust_name}/${cust_id}/${zipCode}/${role}`)
            // axios.get(`http://localhost:8010/api/get/customer/detail/${cust_name}/${cust_id}/${zipCode}/${role}`)
                .then(res => {
                    if (res.status == 200) {
                        Swal.fire({
                            text: res.data.msg,
                            icon: res.data.msg_type,
                            title: res.data.msg_type.toUpperCase(), // Convert title to uppercase
                            confirmButtonText: 'Close',
                        });
                        // console.log(res.data)
                        setCustomer(res.data.customer)
                        setSales(res.data.sale)
                        setTotalBills(res.data.sale.length)
                    } else {
                        Swal.fire({
                            text: res.data.msg,
                            icon: res.data.msg_type,
                            title: res.data.msg_type.toUpperCase(), // Convert title to uppercase
                            confirmButtonText: 'Close',
                        });
                    }
                })
                .catch(err => {
                    console.log(err)
                    Swal.fire({
                        text: err.response.data.msg,
                        icon: err.response.data.msg_type,
                        title: err.response.data.msg_type.toUpperCase(), // Convert title to uppercase
                        confirmButtonText: 'Close',
                        confirmButtonColor: 'red'
                    });
                })
        } else {
            return <div>Non Authenticated Access ! <NavLink to='/login' className='text-decoration-none'>Login First...</NavLink></div>
        }
    }, [])
    return (
        <div className='container'>
            <h1>Customer Detail</h1>
            <NavLink to='/customer'>
                <div className='btn addProductBtn2 rounded-0 mt-2 mb-2 me-2' style={{ fontSize: `${fontSizeChange ? '20px' : '16px'}` }} ṅ>
                    <i className='bi bi-arrow-left'></i> &nbsp;Back
                </div>
            </NavLink>
            <div className='card my-3 rounded-0 bg-white shadow'>
                <div className='card-header'>
                    <span className='text-primary' >Customer Details</span>
                </div>
                <div className='card-body'>
                    <div className='row w-100'>
                        <div className='col-sm-12 col-md-6'>Name: <b>{customer?.cust_name || 'N/A'}</b></div>
                        <div className='col-sm-12 col-md-6'>Phone: <b>{customer?.cust_phone || 'N/A'}</b></div>
                        <div className='col-sm-12 col-md-6'>Email: <b>{customer?.cust_email || 'N/A'}</b></div>
                        <div className='col-sm-12 col-md-6'>Village: <b>{customer?.village || 'N/A'}</b></div>
                        <div className='col-sm-12 col-md-6'>City: <b>{customer?.city || 'N/A'}</b></div>
                        <div className='col-sm-12 col-md-6'>District: <b>{customer?.district || 'N/A'}</b></div>
                        <div className='col-sm-12 col-md-6'>State: <b>{customer?.estate || 'N/A'}</b></div>
                        <div className='col-sm-12 col-md-6'>Zip Code: <b>{customer?.zipCode || 'N/A'}</b></div>
                        <div className='col-sm-12 col-md-6'><br /><b>All Bills: {totalBills || 'N/A'}</b></div>
                    </div>
                </div>
                <div className='card-footer'>
                    <div className='row'>
                        <div className='col-sm-12 col-md-6'>
                            Added By : {customer?.employeeToken ? customer.employeeToken.split('@')[0] : 'N/A'}
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            Added Under : {customer?.bussinessToken ? customer.bussinessToken.split('@')[0] : 'N/A'}
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            Admin : {customer?.superAdminToken ? customer.superAdminToken.split('@')[0] : 'N/A'}
                        </div>
                    </div>
                </div>

            </div>
            <div className='my-3'>
                {
                    sales.map((data, idx) => (
                        <div className='shadow card rounded-0 my-3' key={idx}>
                            <div className='card-header' style={{ fontWeight: '800' }}>
                                Table : {idx + 1}
                            </div>
                            <div className='card-body px-0'>
                                <div className='table-responsive'>
                                    <table className='table table-hover table-bordered'>
                                        <thead>
                                            <tr>
                                                <th className='text-white text-center' style={{ verticalAlign: "middle", background: '#4E73DF', }}>#</th>
                                                <th className='text-white text-center' style={{ verticalAlign: "middle", background: '#4E73DF', minWidth: '100px' }}>Purchased On</th>
                                                <th className='text-white text-center' style={{ verticalAlign: "middle", background: '#4E73DF', minWidth: '100px' }}>Items</th>
                                                <th className='text-white text-center' style={{ verticalAlign: "middle", background: '#4E73DF', minWidth: '100px' }}>Amount (₹)</th>
                                                <th className='text-white text-center' style={{ verticalAlign: "middle", background: '#4E73DF', minWidth: '100px' }}>Tax (₹) | GST (%)</th>
                                                <th className='text-white text-center' style={{ verticalAlign: "middle", background: '#4E73DF', minWidth: '100px' }}>Total (₹)</th>
                                                <th className='text-white text-center' style={{ verticalAlign: "middle", background: '#4E73DF', minWidth: '100px' }}>Paid (₹)</th>
                                                <th className='text-white text-center' style={{ verticalAlign: "middle", background: '#4E73DF', minWidth: '100px' }}>Due (₹)</th>
                                                <th className='text-white text-center' style={{ verticalAlign: "middle", background: '#4E73DF', minWidth: '100px' }}>Given By (₹)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='' style={{ verticalAlign: 'middle', textAlign: 'center' }}>{idx + 1}</td>
                                                <td className='' style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data?.updatedAt?.split("T")[0] || 'N/A'}</td>
                                                <td className='' style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data?.numberOfItems || 'N/A'}</td>
                                                <td className='' style={{ verticalAlign: 'middle', textAlign: 'end' }}>{data?.totalAmountWithoutTax || 'N/A'} ₹</td>
                                                <td className='' style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data?.taxAmount} ₹ - {data?.taxPercentage} %</td>
                                                <td className='' style={{ verticalAlign: 'middle', textAlign: 'end' }}>{data?.totalAmountWithTax || 'N/A'} ₹</td>
                                                <td className='' style={{ verticalAlign: 'middle', textAlign: 'end' }}>{data?.amountPayed || 'N/A'} ₹</td>
                                                <td className='' style={{ verticalAlign: 'middle', textAlign: 'end' }}>{data?.amountLeft || 0} ₹</td>
                                                <td className='' style={{ verticalAlign: 'middle', textAlign: 'center' }}>{data.givenByCustomer ? 'Customer' : data.givenByShopkeeper ? 'Shop' : 'None'}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='card-footer px-0'>
                                <button className='border-0'>Products Purchased</button>
                                <div className='table-responsive'>
                                    <table className='table table-hover table-bordered'>
                                        <thead>
                                            <tr>
                                                <th className='text-black text-center' style={{ verticalAlign: "middle", background: '#FFC107', }}>#</th>
                                                <th className='text-black text-center' style={{ verticalAlign: "middle", background: '#FFC107', minWidth: '100px' }}>Product</th>
                                                <th className='text-black text-center' style={{ verticalAlign: "middle", background: '#FFC107', minWidth: '100px' }}>Price</th>
                                                <th className='text-black text-center' style={{ verticalAlign: "middle", background: '#FFC107', minWidth: '100px' }}>Quantity</th>
                                                <th className='text-black text-center' style={{ verticalAlign: "middle", background: '#FFC107', minWidth: '100px' }}>Total (₹)</th>
                                                <th className='text-black text-center' style={{ verticalAlign: "middle", background: '#FFC107', minWidth: '100px' }}>Category</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.product_data.map((innerData, id) => (
                                                    <tr key={id}>
                                                        <td style={{ textAlign: 'end' }}>{id + 1}</td>
                                                        <td style={{ textAlign: 'center' }}>{innerData.product_name}</td>
                                                        <td style={{ textAlign: 'end' }}>{innerData.total_price} ₹</td>
                                                        <td style={{ textAlign: 'center' }}>{innerData.quantity}</td>
                                                        <td style={{ textAlign: 'end' }}>{parseInt(innerData.total_price) * parseInt(innerData.quantity)} ₹</td>
                                                        <td style={{ textAlign: 'center' }}>{innerData.category_name}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default CustomerDetail