import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

/**
 * Universal method to export a table as a PDF
 * @param {String} title - Title of the PDF document
 * @param {Array} columns - Column headers for the table
 * @param {Array} rows - Table data in row format
 * @param {String} fileName - File name to save the PDF as
 */
export const exportToPDF = (title, columns, rows, fileName = 'document.pdf') => {
  // Check if rows are available
  if (!rows || rows.length === 0) {
    alert("No data available for export.");
    return;
  }

  const doc = new jsPDF();

  // Set a larger font size for the title
  doc.setFontSize(16);
  doc.text(title, 14, 20);

  // Apply a larger font size for the table content
  doc.setFontSize(12);

  // AutoTable options
  doc.autoTable({
    head: [columns],
    body: rows,
    startY: 30,
    startX: 30,
    theme: 'grid',
    headStyles: { fillColor: [100, 100, 255] }, // Customize table header colors
    styles: { fontSize: 12 } // Set font size for the table content
  });

  // Check if the window width is less than 600px
  if (window.innerWidth < 600) {
    // Directly download the PDF
    doc.save(fileName);
  } else {
    // Open the PDF in a new tab for preview
    const pdfOutput = doc.output('blob');
    const pdfUrl = URL.createObjectURL(pdfOutput);
    window.open(pdfUrl, '__blank');

    // Prompt for confirmation to download
    if (window.confirm(`Do you want to download this ${fileName}?`)) {
      doc.save(fileName);
    }
  }
}

/**
 * Universal method to export data as an Excel file
 * @param {String} sheetName - The name of the Excel sheet
 * @param {Array} data - Data in the format of an array of arrays
 * @param {String} fileName - File name to save the Excel as
 */
export const exportToExcel = (sheetName, data, fileName = 'document.xlsx') => {
  // Check if data is available
  if (!data || data.length === 0) {
    alert("No data available for export.");
    return;
  }

  const worksheet = XLSX.utils.aoa_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  XLSX.writeFile(workbook, fileName);
}
