import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../App';


axios.defaults.withCredentials = true

const Registration = () => {
    const [formData, setFormData] = useState({
        name: '',
        username: '',
        email: '',
        phone: '',
        zipCode: '',
        estate: '',
        shopName: '',
        gstNumber: '',
        role: '',
        password: '',
        profilePic: null,
    });
    const { signUp, setSignUp, user, loggedIn, fontSizeChange, setFontSizeChange } = useContext(UserContext);
    const [imagePreview, setImagePreview] = useState(null); // State for image preview
    const [imageInfo, setImageInfo] = useState({ size: '', type: '' }); // State for image info
    const formRef = useRef(); // Ref for the form container
    const [msg, setMsg] = useState(null)
    const [msg_type, setMsg_type] = useState(null)
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check file size (max 500 KB)
            if (file.size > 500 * 1024) {
                alert('File size must be less than 500 KB.');
                setFormData({ ...formData, profilePic: null });
                setImagePreview(null);
                setImageInfo({ size: '', type: '' });
                return;
            }

            setFormData({
                ...formData,
                profilePic: file,
            });

            // Create a preview URL
            const previewUrl = URL.createObjectURL(file);
            setImagePreview(previewUrl);

            // Set image information
            setImageInfo({
                size: (file.size / 1024).toFixed(2) + ' KB', // Convert to KB
                type: file.type.split('/')[1], // Get file extension
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission
        const formDataToSend = new FormData();

        // Append each property to the FormData object explicitly
        formDataToSend.append('name', formData.name);
        formDataToSend.append('username', formData.username);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('phone', formData.phone);
        formDataToSend.append('zipCode', formData.zipCode);
        formDataToSend.append('estate', formData.estate);
        formDataToSend.append('shopName', formData.shopName);
        formDataToSend.append('gstNumber', formData.gstNumber);
        formDataToSend.append('role', formData.role);
        formDataToSend.append('password', formData.password);
        formDataToSend.append('profilePic', formData.profilePic);
        // console.log(formDataToSend)
        // Send the form data to the backend
        axios.post('https://posnewversionbackend.basic2ai.info/api/register', formDataToSend)
        // axios.post('http://localhost:8010/api/register', formDataToSend)
            .then((res) => {
                setMsg_type(res.data.msg_type)
                setMsg(res.data.msg)
                // Optionally handle successful registration
            })
            .catch((err) => {
                setMsg(err.response.data.msg)
                setMsg_type(err.response.data.msg_type)
                // Optionally handle error
            });
    }


    // Close registration when clicking outside the form
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (formRef.current && !formRef.current.contains(event.target)) {
                setSignUp(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setSignUp]);

    return (
        <div className='mt-5 p-5' >
            <div className="container border bg-light shadow" ref={formRef} style={{ maxWidth: '500px', minWidth: '350px' }}>
                <button className='btn shadow border-0 ps-1 pt-1 bg-black' style={{ transform: 'scale(1.5)', marginLeft: '-5px', marginTop: '8px', borderEndEndRadius: '50px', borderTopLeftRadius: '0px', borderTopRightRadius: '0px', borderBottomLeftRadius: '0px' }} onClick={e => setSignUp(!signUp)}><i className='bi bi-x-lg text-danger' style={{ fontWeight: '900' }}></i></button>
                <h2 className="text-center mb-4">Register</h2>
                <center> {msg && <b className={`${msg_type === 'error' ? 'text-danger' : 'text-success'}`}>{msg}</b>}</center>
                { loggedIn ? <form onSubmit={handleSubmit} className='p-3'>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label htmlFor="name" className="form-label">
                                <i className="bi bi-person"></i> Name
                            </label>
                            <input type="text" className="form-control" name="name" value={formData.name} onChange={handleChange} required />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="username" className="form-label">
                                <i className="bi bi-person-fill"></i> Username
                            </label>
                            <input type="text" className="form-control" name="username" value={formData.username} onChange={handleChange} required />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label htmlFor="email" className="form-label">
                                <i className="bi bi-envelope-fill"></i> Email
                            </label>
                            <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} required />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="phone" className="form-label">
                                <i className="bi bi-telephone-fill"></i> Phone
                            </label>
                            <input type="tel" className="form-control" name="phone" value={formData.phone} onChange={handleChange} required />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label htmlFor="zipCode" className="form-label">Zip Code</label>
                            <input type="text" className="form-control" name="zipCode" value={formData.zipCode} onChange={handleChange} />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="estate" className="form-label">Estate</label>
                            <input type="text" className="form-control" name="estate" value={formData.estate} onChange={handleChange} />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label htmlFor="shopName" className="form-label">Shop Name</label>
                            <input type="text" className="form-control" name="shopName" value={formData.shopName} onChange={handleChange} />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="gstNumber" className="form-label">GST Number</label>
                            <input type="text" className="form-control" name="gstNumber" value={formData.gstNumber} onChange={handleChange} />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <label htmlFor="role" className="form-label">Role</label>
                            {user && <select className="form-select" name="role" value={formData.role} onChange={handleChange} required>
                                <option value="" disabled>Select Role</option>
                                <option value="employee" disabled={user.role !== 'bussinessAdmin'}>Employee</option>
                                <option value="bussinessAdmin" disabled={user.role !=='superAdmin'}>Business Admin</option>
                                <option value="superAdmin" disabled={user.role !== 'B2AAdmin'}>Super Admin</option>
                                <option value="B2AAdmin" disabled={user.role !== 'B2AAdmin'}>B2A Admin</option>
                            </select>}
                            
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="password" className="form-label">
                                <i className="bi bi-lock-fill"></i> Password
                            </label>
                            <input type="password" className="form-control" name="password" value={formData.password} onChange={handleChange} required />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="profilePic" className="form-label">
                            <i className="bi bi-image-fill"></i> Profile Picture
                        </label>
                        <input type="file" className="form-control" name="profilePic" onChange={handleFileChange} required />
                        {imagePreview && (
                            <div className="mt-2">
                                <hr />
                                Profile Pic Preview <br />
                                <img src={imagePreview} alt="Profile Preview" className="img-thumbnail shadow" style={{ maxWidth: '100px', maxHeight: '100px' }} /> &nbsp;
                                <img src={imagePreview} alt="Profile Preview" className="img-thumbnail shadow rounded-circle" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                <p>Type: {imageInfo.type}</p>
                                <p>Size: {imageInfo.size}</p>
                                <hr />
                            </div>
                        )}
                    </div>
                    <button type="submit" className="btn btn-success w-100 rounded-0">Register</button>
                </form> : <div>
                    <div className='d-flex fs-5 fw-semibold align-items-center justify-content-center' style={{ minHeight: '100px' }}>
                        You are Not authorized. <a href='/login'>Please Login...</a>
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default Registration;
