import React from 'react'

const PageNotFounf = () => {
    return (
        <div className='bg-light w-100 d-flex align-items-center justify-content-center ' style={{ minHeight: '30vh' }}>
            <div className='p-5 fw-bolder '>
                Page Not Available <a href='/sales' className='text-decoration-none'>Back <i className='bi bi-arrows-angle-contract'></i></a> or <a className='text-decoration-none' href='/'>Home</a>
            </div>
        </div>
    )
}

export default PageNotFounf