import axios from 'axios';
import { fontString } from 'chart.js/helpers';
import React, { useState, useEffect } from 'react';
axios.defaults.withCredentials = true
// This is the main Products Page component
const ProductsProfitLossPage = ({ allProducts }) => {
    // State to manage search queries for filtering
    const [productSearchQuery, setProductSearchQuery] = useState('');
    const [filteredProducts, setFilteredProducts] = useState(allProducts);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
    console.log(allProducts)
    // Filter products based on multiple search queries
    const filterProductsData = (products) => {
        return products.filter(product => {
            const lowerQuery = productSearchQuery.toLowerCase();

            const productNameMatch = product.product_name.toLowerCase().includes(lowerQuery);
            const descriptionMatch = product.product_desc.toLowerCase().includes(lowerQuery);
            const quantityMatch = product.quantity.toString().includes(productSearchQuery);
            const costPriceMatch = product.cost_price.toString().includes(productSearchQuery);
            const sellingPriceMatch = product.selling_price.toString().includes(productSearchQuery);
            const expiryDateMatch = product.expiry_date.toLowerCase().includes(lowerQuery);
            const b2aAdminTokenMatch = product.B2AAdminToken.toLowerCase().includes(lowerQuery);
            const businessTokenMatch = product.bussinessToken.toLowerCase().includes(lowerQuery);
            const employeeTokenMatch = product.employeeToken.toLowerCase().includes(lowerQuery);
            const superAdminTokenMatch = product.superAdminToken.toLowerCase().includes(lowerQuery);

            // Combine all conditions with OR logic to allow matches on any criteria
            return productNameMatch || descriptionMatch || quantityMatch ||
                costPriceMatch || sellingPriceMatch || expiryDateMatch ||
                b2aAdminTokenMatch || businessTokenMatch || employeeTokenMatch ||
                superAdminTokenMatch;
        });
    };

    // Update filtered products whenever search queries change
    useEffect(() => {
        const filteredData = filterProductsData(allProducts);
        setFilteredProducts(filteredData);
        setTotalPages(Math.ceil(filteredData.length / itemsPerPage)); // Calculate total pages based on items per page
    }, [productSearchQuery, allProducts, itemsPerPage]);

    // console.log(filteredProducts)
    // Render the product table
    const renderProducts = () => {
        if (filteredProducts.length === 0) {
            return (
                <tr>
                    <td colSpan={8} style={{ textAlign: 'center' }}>
                        No products available.
                    </td>
                </tr>
            );
        }

        return filteredProducts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((product, index) => (
            <tr key={index}>
                <td className='text-center'>{(currentPage - 1) * itemsPerPage + index + 1}</td> {/* Serial Number */}
                <td style={{minWidth:'max-content'}}>{product.product_name}</td>
                <td style={{minWidth:'max-content'}}>{product.product_desc}</td>
                <td style={{minWidth:'max-content'}} className='text-center'>{product.quantity_sold}</td>
                <td style={{minWidth:'max-content'}} className='text-end'>{(product.selling_price).toFixed(2)} ₹</td>
                <td style={{minWidth:'max-content'}} className='text-end'>{(product.selling_price * product.quantity_sold).toFixed(2)} ₹</td>
                <td style={{minWidth:'max-content'}} className='text-end'>{(product.cost_price).toFixed(2)} ₹</td>
                <td style={{minWidth:'max-content'}} className='text-end'>{(product.cost_price * product.quantity_sold).toFixed(2)} ₹</td>
                <td style={{minWidth:'max-content'}} className='text-end'>{((product.selling_price - product.cost_price) * product.quantity_sold).toFixed(2)} ₹<b style={{background:'#caf5ca'}} className={`fw-bold p-1 ms-2 ${((product.selling_price - product.cost_price) * product.quantity_sold) >= 0 ? ' text-success' : 'p-1 px-2 text-danger ms-1'}`}>{((product.selling_price - product.cost_price) * product.quantity_sold) > 0 ? <i class="bi bi-graph-up-arrow"></i> : <i class="bi bi-graph-down-arrow"></i>}</b></td>
                <td style={{minWidth:'max-content'}} className='text-end'>{((product.selling_price - product.cost_price) / product.cost_price * 100).toFixed(2)}%</td>
            </tr>
        ));
    };

    // Handle pagination
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Handle items per page change
    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(e.target.value > 0 ? Number(e.target.value) : 10); // Update items per page based on user selection
        setCurrentPage(1); // Reset to first page on items per page change
    };

    return (
        <div className='card rounded-0 p-0 border-0 bg-white my-3 bg-white shadow'>
            <div className='card-header'>
                <h2>Products with Profit and Loss</h2>
            </div>
            {/* Search inputs for filtering */}
            <div className='card-body'>
                <div className='mb-3 p-3'>
                    <label className='d-flex align-items-center justify-content-between form-control'>
                        <input
                            type="search"
                            placeholder="Search by Product Name"
                            value={productSearchQuery}
                            onChange={(e) => setProductSearchQuery(e.target.value)}
                            className="border-0 m-1 w-100 removerFocus"
                            style={{ outline: 'none', boxShadow: 'none' }} // Inline styles to remove focus
                        />
                        <i className='bi bi-search' style={{ transform: `${productSearchQuery.length % 2 === 0 ? 'scale(1.01)' : 'scale(0.95)'}`, transition: '0.2s ease-in-out' }}></i>
                    </label>
                </div>

                {/* Items per page selector */}
                <div className='mb-3 p-3 d-flex  align-items-center'>
                    <label style={{ minWidth: '125px' }} htmlFor="itemsPerPage" className='me-2'>Items per page:</label>
                    <select id="itemsPerPage" value={itemsPerPage} onChange={e => handleItemsPerPageChange(e)} className="form-select w-auto d-inline">
                        <option value={itemsPerPage} disabled>{itemsPerPage}</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                    </select>
                    <b className='mx-1'>OR</b>
                    <input type='number' className='form-control' onChange={e => handleItemsPerPageChange(e)} />
                </div>

                {/* Products Table */}
                <div className='table-responsive mb-0 p-3'>
                    <table className='table table-striped table-bordered'>
                        <thead className='table-head'>
                            <tr>
                                <th className='text-center'>#</th>
                                <th className='text-center'>Product Name</th>
                                <th className='text-center'>Dsc.</th>
                                <th className='text-center'>QS</th>
                                <th className='text-center'>SP</th>
                                <th className='text-center'>TSP</th>
                                <th className='text-center'>CP</th>
                                <th className='text-center'>TCP</th>
                                <th className='text-center'>PLA</th>
                                <th className='text-center'>%</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderProducts()}
                        </tbody>
                    </table>
                </div>
                <br />
                {/* Pagination Controls */}
                <nav className='p-3'>
                    <ul className='pagination'>
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button className='page-link' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                                Previous
                            </button>
                        </li>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                <button className='page-link' onClick={() => handlePageChange(i + 1)}>
                                    {i + 1}
                                </button>
                            </li>
                        ))}
                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <button className='page-link' onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                                Next
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className='card-footer bg-dark text-warning'>
                <div className='row'>
                    <div className='col-sm-3 col-md-3 col-lg-2 col-xl-2' style={{ minWidth: 'max-content', fontSize: '12px', margin: "auto 5px" }}><i className='text-danger'>*</i> Dsc. - Description, </div>
                    <div className='col-sm-3 col-md-3 col-lg-2 col-xl-2' style={{ minWidth: 'max-content', fontSize: '12px', margin: "auto 5px" }}><i className='text-danger'>*</i> QS - Quantity Sold, </div>
                    <div className='col-sm-3 col-md-3 col-lg-2 col-xl-2' style={{ minWidth: 'max-content', fontSize: '12px', margin: "auto 5px" }}><i className='text-danger'>*</i> SP - Selling Price, </div>
                    <div className='col-sm-3 col-md-3 col-lg-2 col-xl-2' style={{ minWidth: 'max-content', fontSize: '12px', margin: "auto 5px" }}><i className='text-danger'>*</i> TSP - Total Selling Price, </div>
                    <div className='col-sm-3 col-md-3 col-lg-2 col-xl-2' style={{ minWidth: 'max-content', fontSize: '12px', margin: "auto 5px" }}><i className='text-danger'>*</i> CP - Cost Price, </div>
                    <div className='col-sm-3 col-md-3 col-lg-2 col-xl-2' style={{ minWidth: 'max-content', fontSize: '12px', margin: "auto 5px" }}><i className='text-danger'>*</i> TCP - Total Cost Price, </div>
                    <div className='col-sm-3 col-m d-3 col-lg-2 col-xl-2' style={{ minWidth: 'max-content', fontSize: '12px', margin: "auto 5px" }}><i className='text-danger'>*</i> PLA - Profit Loss Amount</div>
                </div>
            </div>
        </div>
    );
};

export default ProductsProfitLossPage;
