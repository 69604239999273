import React from 'react'
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    ArcElement,
    LineElement,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    LineElement,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
);
const BarGgraph = ({ profitLabels, purchasePrices, sellingPrices }) => {
    return (
        <div className='bg-white shadow my-4'>
            <div className='card border-0 '>
                <div className='card-header fs-2 fw-bolder'>Profit and Loss Overview</div>
                <div className='card-body'>
                    <Bar
                    height={220}
                        data={{
                            labels: profitLabels,
                            datasets: [
                                {
                                    label: 'Purchase Price',
                                    data: purchasePrices,
                                    backgroundColor: '#F9484C',
                                    borderColor:'red'
                                },
                                {
                                    label: 'Selling Price',
                                    data: sellingPrices,
                                    backgroundColor: '#42B1FA',
                                    borderColor:'blue'
                                },
                            ],
                        }}
                        options={{
                            responsive: true,
                            scales: {
                                y: {
                                    beginAtZero: true,
                                },
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default BarGgraph