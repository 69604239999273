import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { exportToPDF, exportToExcel } from '../../Utils/ExportUtils.js';
import { UserContext } from '../../App';
import { NavLink } from 'react-router-dom';
import './Sales.css'
import AddSales from './AddSales.jsx';
import Swal from 'sweetalert2';
axios.defaults.withCredentials = true;

const Products = () => {
  const { user, loggedIn, fontSizeChange } = useContext(UserContext);
  const [product, setProduct] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [ProductPerPage, setProductPerPage] = useState(20);
  const [msg, setMsg] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [msg_type, setMsg_type] = useState(null);
  const [categories, setCategories] = useState([]);
  const [allSales, setAllSales] = useState([]);

  useEffect(() => {
    axios.get('https://posnewversionbackend.basic2ai.info/api/categories')
    // axios.get('http://localhost:8010/api/categories')
      .then(res => {
        setCategories(res.data.categoriesFound);
      })
      .catch(error => console.error('Error fetching categories:', error));
  }, [msg, isFormOpen]);

  useEffect(() => {
    axios.get('https://posnewversionbackend.basic2ai.info/api/Products')
    // axios.get('http://localhost:8010/api/Products')
      .then(res => {
        setProduct(res.data.productsFound);
      })
      .catch(error => console.error('Error fetching Products:', error));
  }, [msg, isFormOpen]);
  useEffect(() => {
    axios.get('https://posnewversionbackend.basic2ai.info/api/Sales')
    // axios.get('http://localhost:8010/api/Sales')
      .then(res => {
        // console.log(res.data)
        setAllSales(res.data.salesFound);
      })
      .catch(error => console.error('Error fetching Products:', error));
  }, [msg, isFormOpen]);

  // Ensure filteredProduct is always an array
  const filteredProduct = allSales ? allSales.filter(prod =>
    searchQuery.trim() === '' ||
    prod.id.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
    prod.sale_id.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
    prod.customer_name.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
    prod.numberOfItems.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
    prod.totalAmountWithoutTax.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
    prod.amountPayed.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
    prod.amountLeft.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
    prod.totalAmountWithTax.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
    prod.employeeToken?.toString().includes(searchQuery) ||
    prod.bussinessToken?.toString().includes(searchQuery) ||
    prod.superAdminToken?.toString().includes(searchQuery)
  ) : [];

  // Pagination logic
  const indexOfLastProduct = currentPage * ProductPerPage;
  const indexOfFirstProduct = indexOfLastProduct - ProductPerPage;

  const currentProduct = filteredProduct.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(filteredProduct.length / ProductPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const msgRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (msgRef.current && !msgRef.current.contains(event.target)) {
        setMsg(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [msg]);

  const handleExportPDF = () => {
    if (filteredProduct.length < 1) {
      Swal.fire({
        title: 'Attention !',
        text: 'No data to print !',
        confirmButtonText: 'Okay',
        icon: 'warning'
      })
      return
    }
    const columnsToInclude = ['customer_name', 'numberOfItems', 'amountPayed', 'totalAmountWithTax', 'taxPercentage', 'amountLeft'];
    const columns = Object.keys(filteredProduct[0] || {}).filter(column => columnsToInclude.includes(column));
    columns[0] = 'Name'
    columns[1] = 'Items'
    columns[4] = 'Amount Paid'
    columns[3] = 'Total Amount'
    columns[2] = 'Tax %'
    columns[5] = 'Amount Left'
    const rows = filteredProduct.map((customer, index) => {
      const filteredValues = Object.entries(customer)
        .filter(([key]) => columnsToInclude.includes(key))
        .map(([key, value]) => value);
      return [index + 1, ...filteredValues];
    });
    const finalColumns = ['#', ...columns];
    const userInput = prompt("Please enter the title. Click Cancel for Default Name. ");
    // Check if user provided input or canceled the prompt
    if (userInput !== null && userInput.trim() !== "") {
      // Display the input in an alert or use it for other purposes
      exportToPDF(`${userInput}`, finalColumns, rows, `${userInput}.pdf`);
    } else {
      exportToPDF('Customer Bill List', finalColumns, rows, 'CustomerBillList.pdf');
    }
  };

  const handleProductPerPageChange = (e) => {
    setProductPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleExportExcel = () => {
    if (filteredProduct.length < 1) {
      Swal.fire({
        title: 'Attention !',
        text: 'No data to print !',
        confirmButtonText: 'Okay',
        icon: 'warning'
      })
      return
    }
    const columns = Object.keys(filteredProduct[0] || {}).slice(0, -1);
    const data = filteredProduct.map((customer) => [...Object.values(customer).slice(0, -1)]);
    exportToExcel('Product', [columns, ...data], 'Product.xlsx');
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
  };

  const handleDelete = (cat_id, product_id, employeeToken) => {
    Swal.fire({
      title: 'Danger',
      text: 'DO you really want to delete this bill detail ? ',
      showCancelButton: true,
      cancelButtonText: 'No',
      icon: 'error'
    }).then(res => {
      if (res.isConfirmed) {
        axios.post('https://posnewversionbackend.basic2ai.info/api/delete/sale', { sale_id: cat_id, product_id: product_id, employeeToken: employeeToken })
        // axios.post('http://localhost:8010/api/delete/sale', { sale_id: cat_id, product_id: product_id, employeeToken: employeeToken })
          .then(res => {
            Swal.fire({
              title: res.data.msg,
              text: res.data.msg_type,
              icon: res.data.msg_type,
              confirmButtonText: res.data.msg_type == 'success' ? 'Okay' : 'Try Again.',
            }).then(res => {
              window.location.reload(true)
            })
          })
          .catch(err => console.log(err));
      } else {
        return
      }
    })
      .catch(err => {
        Swal.fire({
          text: 'Something went wrong. Please try again later !',
          title: 'Error',
          icon: 'error',
          confirmButtonText: 'Okay'
        })
      })

  };

  return (
    <div>
      {msg && (
        <div className='d-flex align-items-center justify-content-center' style={{ background: '', minHeight: '0px', position: 'absolute', width: '100vw', zIndex: '1050', left: '0px', top: "0px" }}>
          <div
            // ref={msgRef} // Reference the message box
            className={`msgAlert animate2  p-5 rounded-3 ${msg ? 'show ' : 'notShow'} ${msg_type === 'good' ? 'text-white bg-success' : 'text-white bg-danger'}`}
            style={{ top: '', fontWeight: '800', fontSize: '25px', height: '150px', width: '370px', background: '' }}
          >
            <div className=''>
              <div>
                <center>{msg_type == 'error' ? <i className='bi bi-x-circle animate' style={{ fontSize: '35px' }}></i> : <i className='bi bi-check-circle animate' style={{ fontSize: '35px' }}></i>}</center>
              </div>
              <div className='text-center'>
                {msg}
              </div>
            </div>
          </div>
        </div>
      )}
      {user ? (
        <div className='bg-light container'>
          <div style={{ fontSize: `${fontSizeChange ? '35px' : '25px'}` }}>Sales</div>
          {user.role === 'employee' &&
            <NavLink to={'/sales/add'}> <div className='btn addProductBtn1 rounded-0 mt-4 me-2 mb-2' style={{ fontSize: `${fontSizeChange ? '20px' : '16px'}` }} onClick={() => setIsFormOpen(true)}>
              <i className='bi bi-plus-lg'></i> Create New Sale
            </div></NavLink>
          }
          <NavLink to="/sales/rejected-altered-bills">
            <div className='btn addProductBtn2 rounded-0 mt-4 mb-2 me-2' style={{ fontSize: `${fontSizeChange ? '20px' : '16px'}` }}>
              <i className='bi bi-exclamation-triangle-fill'></i> Rejected / Altered Bills
            </div>
          </NavLink>
          <div className='card'>
            <div className='card-header bg-light py-1 px-3' style={{ color: '#4E73DF' }}>Sales</div>
            <div className='card-body bg-white'>
              <div className=''>
                <button style={{ fontSize: `${fontSizeChange ? '20px' : '16px'}` }} className='btn perivewButtons rounded-0 me-3 my-2' onClick={handleExportPDF}>
                  <i className='bi bi-file-pdf'></i> PDF
                </button>
                <button style={{ fontSize: `${fontSizeChange ? '20px' : '16px'}` }} className='btn perivewButtons rounded-0 me-3 my-2' onClick={handleExportExcel}>
                  <i className='bi bi-file-excel'></i> Excel
                </button>
                <div className='input-group d-flex align-items-center justify-content-between'>
                  <label className='me-1'>Search: </label>
                  <input
                    type='search'
                    className='form-control rounded-pill'
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </div>

              <div className='mb-3 input-group d-flex align-items-center my-3'>
                <label className='me-2'>Items per page: </label>
                <select className='border rounded-0 px-2' style={{ maxWidth: '100px' }} value={ProductPerPage} onChange={handleProductPerPageChange}>
                  <option className='bg-secondary .bg-gradient text-white' value={ProductPerPage} disabled>{ProductPerPage}</option>
                  <option style={{ textAlign: 'end' }} value={1}>1</option>
                  <option style={{ textAlign: 'end' }} value={5}>5</option>
                  <option style={{ textAlign: 'end' }} value={10}>10</option>
                  <option style={{ textAlign: 'end' }} value={20}>20</option>
                  <option style={{ textAlign: 'end' }} value={50}>50</option>
                  <option style={{ textAlign: 'end' }} value={100}>100</option>
                </select>
                <b className='mx-2'>or</b>
                <input className='form-control rounded-pill' style={{ maxWidth: '100px' }} onChange={e => setProductPerPage(e.target.value > 0 ? e.target.value : 20)} />
              </div>

              <div className='table-responsive'>
                <table className='table table-hover table-bordered'>
                  <thead>
                    <tr>
                      <th style={{ verticalAlign: "middle", minWidth: '20px' }} className='text-center'>#</th>
                      <th style={{ verticalAlign: "middle", minWidth: '120px' }} className='text-center'>Customer</th>
                      <th style={{ verticalAlign: "middle", minWidth: '' }} className='text-center'>Items</th>
                      <th style={{ verticalAlign: "middle", minWidth: '150px' }} className='text-center'>Total (Incl. GST)</th>
                      <th style={{ verticalAlign: "middle", minWidth: '120px' }} className='text-center'>Paid</th>
                      <th style={{ verticalAlign: "middle", minWidth: '100px' }} className='text-center'>GST | %</th>
                      <th style={{ verticalAlign: "middle", minWidth: '100px' }} className='text-center'>Tax Amount</th>
                      <th style={{ verticalAlign: "middle", minWidth: '70px' }} className='text-center'>Left </th>
                      <th style={{ verticalAlign: "middle", minWidth: '100px' }} className='text-center'>Given By</th>
                      {<th style={{ minWidth: '150px' }} className='text-center'>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {currentProduct.length > 0 ? (
                      currentProduct.map((product, index) => (
                        <tr key={index}>
                          <td className='' style={{ cursor: "pointer", verticalAlign: 'middle', textAlign: 'center' }}>{index + 1}</td>
                          <td className='' style={{ cursor: "pointer", verticalAlign: 'middle', textAlign: '' }}>{product.customer_name}</td>
                          <td className='' style={{ cursor: "pointer", verticalAlign: 'middle', textAlign: 'center' }}>{product.numberOfItems}</td>
                          <td className='' style={{ cursor: "pointer", verticalAlign: 'middle', textAlign: 'end' }}>{product.totalAmountWithTax} ₹</td>
                          <td className='' style={{ cursor: "pointer", verticalAlign: 'middle', textAlign: 'end' }}>{product.amountPayed} ₹</td>
                          <td className='' style={{ cursor: "pointer", verticalAlign: 'middle', textAlign: 'end' }}>{product.taxPercentage} %</td>
                          <td className='' style={{ cursor: "pointer", verticalAlign: 'middle', textAlign: 'end' }}>{product.taxAmount} ₹</td>
                          <td className='' style={{ cursor: "pointer", verticalAlign: 'middle', textAlign: 'end' }}>{product.amountLeft} ₹</td>
                          <td className='' style={{ cursor: "pointer", verticalAlign: 'middle', textAlign: 'center' }}>{product.givenByCustomer ? 'Customer' : product.givenByShopkeeper ? 'Shop' : 'None'} </td>
                          <td className='' style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                            <NavLink to={`/sales/detailed/${product.sale_id}/${product.customer_id}/${product.employeeToken}`} className='btn btn-info rounded-0 text-white fs-4'>
                              <i className='bi bi-info-lg '></i>
                            </NavLink>
                            {user.role === 'employee' && <NavLink to={`/sales/edit/${product.sale_id}/${product.customer_id}/${product.employeeToken}`} className='btn btn-warning rounded-0 text-white fs-4'>
                              <i className='bi bi-pencil-square '></i>
                            </NavLink>}
                            {user.role === 'employee' && <button
                              className='btn btn-danger rounded-0 fs-4'
                              onClick={() => handleDelete(product.sale_id, product.customer_id, product.employeeToken)}
                            >
                              <i className='bi bi-trash'></i>
                            </button>}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">No Products Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className='d-flex justify-content-between align-items-center p-1' style={{ maxWidth: '' }}>
                <div className='d-flex py-1' style={{ maxWidth: '50vw' }}>
                  <button
                    className={`btn border-0 rounded-0 mx-1 ${currentPage === 1 ? 'bg-purple-disabled  disabled' : 'bg-purple'}`}
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>

                  <div className='bg-white paginationNumbers d-flex py-1' style={{ maxWidth: '350px', overflowX: 'auto' }}>
                    {Array.from({ length: totalPages }, (_, index) => (
                      <button
                        key={index}
                        style={{ boxShadow: '2px 2px 2px 1px #c2bebebd', filter: 'drop-shadow(0px 0px 20px #c2bebebd)' }}
                        className={`btn rounded-0 mx-1 ${currentPage === index + 1 ? 'text-primary btn-light' : 'btn-light'}`}
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </button>
                    ))}

                  </div>
                  <button
                    className={`btn border-0 rounded-0 mx-1 ${currentPage === totalPages ? 'bg-purple-disabled disabled' : 'bg-purple'}`}
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
                <b>Current Page No. is : {currentPage} </b>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='bg-light container'>
          <div style={{ fontSize: '25px' }}>You must be logged in to view. Please Login <a href='/'>Back</a></div>
        </div>
      )}
    </div>
  );
}

export default Products;
