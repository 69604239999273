import React, { useContext } from 'react'
import { UserContext } from '../App'

const Footer = () => {
    const { user } = useContext(UserContext)
    return (
        <>
            {
                user && <div className='footer bg-black text-center text-white py-2'>
                    <div>
                        <a className='text-decoration-none' target='__blank' href='https://basic2ai.info'>Basic to Artificial Intelligence <sup><i className='bi bi-c-circle'></i></sup>2024</a>
                        <sm className="mx-4">Created by Anirudh Kala <a target='__blank' href="https://github.com/anirudhkala110"><i className='bi bi-github'></i></a></sm>
                    </div>
                </div>
            }
        </>
    )
}

export default Footer